export const form = `{% if (ctx.prefix || ctx.suffix) { %}
<div class="input-group">
  {% } %}
  {% if (ctx.prefix) { %}
    <div class="input-group-prepend" ref="prefix">
      <span class="input-group-text">
        {% if(ctx.prefix instanceof HTMLElement){ %}
          {{ ctx.t(ctx.prefix.outerHTML, { _userInput: true }) }}
        {% } else{ %}
          {{ ctx.t(ctx.prefix, { _userInput: true }) }}
        {% } %}
      </span>
    </div>
  {% } %}
  {% if (!ctx.component.editor && !ctx.component.wysiwyg) { %}
    {% if (ctx.disabled && ctx.component.type !== "hidden" && ctx.disabledValue || (ctx.component.type === "phoneNumber" && ctx.disabled) || (ctx.component.type === "email" && ctx.disabled) || ctx.component?.attributes?.readonly) { %}
        <div ref="disabledValue" class="disabledValueContainer {{ctx.disabledValue.length > 70 ? 'break-word' : ''}}">{{ctx.disabledValue}}</div>
    {% } else { %}
      <{{ctx.input.type}}
        ref="{{ctx.input.ref ? ctx.input.ref : 'input'}}"
        {% for (var attr in ctx.input.attr) { %}
          {{attr}}="{{ctx.input.attr[attr]}}"
        {% } %}
        id="{{ctx.instance.id}}-{{ctx.component.key}}"
        aria-labelledby="l-{{ctx.instance.id}}-{{ctx.component.key}} {% if (ctx.component.description) { %}d-{{ctx.instance.id}}-{{ctx.component.key}}{% } %}"
        aria-required="{{ctx.input.ref === 'input' || !ctx.input.ref ? ctx.component.validate.required :
          ctx.component.fields && ctx.component.fields[ctx.input.ref] && ctx.component.fields[ctx.input.ref].required || false}}"
      >{{ctx.input.content}}</{{ctx.input.type}}>
      {% if (ctx.component.type !== 'textarea') { %}
        {% if (ctx.hasValueMaskInput) { %}
          <input ref="valueMaskInput" />
        {% } %}
      {% } %}
    {% } %}
  {% } %}
  {% if (ctx.component.editor || ctx.component.wysiwyg) { %}
    <div ref="input"></div>
  {% } %}
  {% if (ctx.component.type === 'datetime') { %}
    <span aria-live="assertive" id="{{ctx.instance.id}}-liveRegion" class="sr-only" ref="liveRegion"></span>
  {% } %}
  {% if (ctx.suffix) { %}
    {% if (ctx.disabled || ctx.component?.attributes?.readonly) { %}
      {% if (ctx.disabledValue) { %}
        <div class="input-group-append" ref="suffix">
          <div class="input-suffix">
            {% if(ctx.suffix instanceof HTMLElement){ %}
              {{ ctx.t(ctx.suffix.outerHTML, { _userInput: true }) }}
            {% } else{ %}
              {{ ctx.t(ctx.suffix, { _userInput: true }) }}
            {% } %}
          </div>
        </div>
      {% } %}
    {% } else { %}
      <div class="input-group-append" ref="suffix">
        <div class="input-suffix">
          {% if(ctx.suffix instanceof HTMLElement){ %}
            {{ ctx.t(ctx.suffix.outerHTML, { _userInput: true }) }}
          {% } else{ %}
            {{ ctx.t(ctx.suffix, { _userInput: true }) }}
          {% } %}
        </div>
      </div>
    {% } %}
  {% } %}
{% if (ctx.prefix || ctx.suffix) { %}
</div>
{% } %}
{% if (ctx.component.showCharCount || ctx.component.showWordCount) { %}
<div class="form-text {{ctx.component.description ? 'pull-right' : 'text-right'}}">
  {% if (ctx.component.showCharCount) { %}
  <span class="text-muted" ref="charcount" aria-live="polite"></span>
  {% } %}
  {% if (ctx.component.showWordCount) { %}
  <span class="text-muted" ref="wordcount" aria-live="polite"></span>
  {% } %}
</div>
{% } %}
`;
