/* eslint-disable max-lines */
import { PlusOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { SearchPanel } from 'src/components/SearchPanel/SearchPanel';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { Router } from 'src/core/router/Router';
import { IndividualsStore } from 'src/stores/IndividualsStore/IndividualsStore';
import { UserStore } from 'src/stores/UserStore/UserStore';
import styled from 'styled-components';

const store = IndividualsStore;

export const IndividualsHeaderPanel = observer(() => {
    const { t } = useTranslation();

    return (
        <StyledHeaderWrapper>
            <SearchPanel
                store={store}
                placeholder={t(
                    'Page.Individuals.AsideHeader.Search.Placeholder',
                )}
                maxSearchFieldLength={20}
            />
            {UserStore.can.manage.individuals && (
                <Tooltip
                    title={t(
                        'Page.Individuals.AsideHeader.CreateNewItem.Tooltip',
                    )}
                >
                    <StyledCreateButton
                        onClick={() => {
                            const createLink =
                                EntityLinks.individuals.create?.();
                            createLink && Router.navigate(createLink);
                        }}
                    >
                        <PlusOutlined />
                    </StyledCreateButton>
                </Tooltip>
            )}
        </StyledHeaderWrapper>
    );
});

const StyledHeaderWrapper = styled.header`
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto;
`;

const StyledCreateButton = styled.button`
    width: 3.6rem;
    min-width: 3rem;
    font-weight: 700;
    height: 2.4rem;
    margin-left: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: 2rem;
    border: none;
    background: var(--btn-bg);
    border-radius: 0.35rem;
    color: #ffffff;
    span: {
        font-size: 1rem;
    }
`;
