import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { FormioForm } from 'src/components/FormioForm/FormioForm';
import { Spinner } from 'src/components/Spinner/Spinner';
import { FormioCustomEvent, FormioEvent } from 'src/core/Formio.types';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { Router } from 'src/core/router/Router';
import { TaskRepresentation } from 'src/generated-api-client';
import { ProcessDefinitionsStore } from 'src/stores/ProcessDefinitionsStore/ProcessDefinitionsStore';
import { ProcessStoreProvider } from 'src/stores/ProcessStore/ProcessStore';
import { CombinedLoadingStatus } from 'src/utils/mobx/CombinedLoadingStatus';
import styled from 'styled-components';

// import form from './form.json';

export type ProcessStartFormProps = {
    processKey: string;
    context?: any;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSubmit?: (data: any) => void | Promise<void>;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onCustomEvent?: (event: FormioCustomEvent) => void | Promise<void>;
};

export const ProcessStartForm = observer(
    ({
        processKey,
        context,
        onSubmit,
        onCustomEvent,
    }: ProcessStartFormProps) => {
        const processDefinition = useMemo(() => {
            return ProcessDefinitionsStore.getProcessDefinition(processKey);
        }, [processKey, ProcessDefinitionsStore.list]);
        const [submissionSet, setSubmissionSet] = useState(false);

        const processStore = ProcessStoreProvider.getInstance(processKey);

        useEffect(() => {
            if (processStore && !processDefinition) {
                processStore.setContext(context);
            }
        }, [processStore, context]);

        useEffect(() => {
            if (processKey) {
                processStore.loadItem(processKey);
                processStore.loadForm(processKey);
            }

            return () => {
                processStore.itemLoader.reset();
                processStore.formLoader.reset();
            };
        }, [processKey]);

        const loadingStatus = useMemo(() => {
            return new CombinedLoadingStatus([
                ProcessDefinitionsStore.listLoader,
                processStore.itemLoader,
                processStore.formLoader,
            ]);
        }, [processStore]);

        if (loadingStatus.isLoading && !submissionSet) {
            return <StyledSpinner />;
        }

        return (
            <>
                {processDefinition?.name && <h1>{processDefinition.name}</h1>}
                <FormioForm
                    // form={form as any}
                    form={processStore.currentItemForm as any}
                    submission={processStore.getInitialVariables()}
                    onSubmit={getSubmitHandler(processKey, onSubmit)}
                    setSubmissionSet={setSubmissionSet}
                    onCustomEvent={async (event) => {
                        await onCustomEvent?.(event);
                    }}
                    normalizeSubmission
                />
            </>
        );
    },
);

const submitForm = async (processKey: string, data: any) => {
    const process = ProcessStoreProvider.getInstance(processKey);
    await process.update(processKey, data);

    return process.nextTasks as any as TaskRepresentation[];
};

const getSubmitHandler = (
    processKey: string,
    onSubmit?: (_: any) => void | Promise<void>,
) => {
    let isSubmitting = false;

    return async (values: FormioEvent) => {
        if (isSubmitting) {
            return;
        }
        const { data } = values;

        isSubmitting = true;

        const tasks = await submitForm(processKey, data);
        await onSubmit?.(data);
        if (tasks?.length) {
            const firstTask = tasks[0];
            Router.navigate(EntityLinks.tasks.itemDetails(firstTask.id));
        }
    };
};

const StyledSpinner = styled(Spinner)`
    margin: 0 auto;
    display: block;
`;
