import { UserRole } from 'src/stores/UserStore/UserRole';
import { EntityConfig } from 'src/stores/UserStore/UserStore.types';

export const ProductCampaignsViewers: UserRole[] = [
    UserRole.Admin,
    UserRole.HeadOfSales,
    UserRole.SalesManager,
    UserRole.SalesTeamLeader,
    UserRole.RelationshipManager,
    UserRole.HeadOfCredit,
    UserRole.CreditTeamLeader,
    UserRole.CreditUnderwriter,
    UserRole.HeadOfRisk,
    UserRole.RiskOfficer,
    UserRole.CustomerCare,
    UserRole.HeadOfOperation,
    UserRole.OperationOfficer,
    UserRole.FinancialOperations,
    UserRole.CustomerSuccess,
    UserRole.CustomerSuccessManager,
    UserRole.Compliance,
    UserRole.HeadOfCollection,
    UserRole.CollectionTeamLeader,
    UserRole.CollectionOfficer,
    UserRole.EnforcementOfficer,
    UserRole.CollectionAdmin,
    UserRole.Auditing,
    UserRole.Finance,
    UserRole.Support,
    UserRole.SalesSupport,
    UserRole.CAD,
    UserRole.SeniorCAD,
];

export const ProductCampaignsManagers: UserRole[] = [UserRole.Admin];

export const ProductCampaignsConfig: EntityConfig = {
    view: ProductCampaignsViewers,
    manage: ProductCampaignsManagers,
};
