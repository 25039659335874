/* eslint-disable max-lines */
import { computed } from 'mobx';
import { Trans } from 'react-i18next';
import { AppEnv } from 'src/core/Environment';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { CampaignsPage } from 'src/pages/CampaignsPage/CampaignsPage';
import { CollectionsPage } from 'src/pages/CollectionsPage/CollectionsPage';
import { FormioSandbox } from 'src/pages/FormioSandbox/FormioSandbox';
import { IndividualsPage } from 'src/pages/IndividualsPage/IndividualsPage';
import { LegalEntitiesPage } from 'src/pages/LegalEntitiesPage/LegalEntitiesPage';
import { LoanApplicationsPage } from 'src/pages/LoanApplicationsPage/LoanApplicationsPage';
import { LoansPage } from 'src/pages/LoansPage/LoansPage';
import { OperationsPage } from 'src/pages/OperationsPage/OperationsPage';
import { ProductsPage } from 'src/pages/ProductsPage/ProductsPage';
import { TasksPage } from 'src/pages/TasksPage/TasksPage';
import { TransactionsPage } from 'src/pages/TransactionsPage/TransactionsPage';
import { RouteDefinition } from 'src/stores/RouterStore/RouterStore.types';
import { UserStore } from 'src/stores/UserStore/UserStore';

export class RouterStoreClass {
    @computed get routes(): RouteDefinition[] {
        return [
            {
                key: RoutePaths.tasks,
                path: RoutePaths.tasks,
                Component: TasksPage,
                menuTitle: () => <Trans i18nKey="App.Header.Menu.Tasks" />,
                menuLink: EntityLinks.tasks.list(),
                unavailable: UserStore.canNot.view.tasks,
            },
            {
                key: RoutePaths.loanApplications,
                path: RoutePaths.loanApplications,
                Component: LoanApplicationsPage,
                menuTitle: () => (
                    <Trans i18nKey="App.Header.Menu.Applications" />
                ),
                menuLink: EntityLinks.applications.list(),
                exact: false,
                unavailable: UserStore.canNot.view.applications,
            },

            {
                key: RoutePaths.operations,
                path: RoutePaths.operations,
                Component: OperationsPage,
                menuLink: EntityLinks.operations.list(),
                menuHide: true,
                exact: false,
                unavailable: UserStore.canNot.view.operations,
            },

            {
                key: RoutePaths.loans,
                path: RoutePaths.loans,
                Component: LoansPage,
                menuTitle: () => <Trans i18nKey="App.Header.Menu.Loans" />,
                menuLink: EntityLinks.loans.list(),
                exact: false,
                unavailable: UserStore.canNot.view.loans,
            },

            // {
            //     key: RoutePaths.borrowers,
            //     path: RoutePaths.borrowers,
            //     Component: BorrowersPage,
            //     menuTitle: () => <Trans i18nKey="App.Header.Menu.Borrowers" />,
            //     menuLink: EntityLinks.borrowers.list(),
            //     exact: false,
            //     unavailable: ifFeatureAvailable([
            //         UserStore.canNot.view.borrowers,
            //         featureList.borrowers.unavailable,
            //     ]),
            // },

            // TODO: LegalEntitiesModule use the RoutePaths.borrowers as alias for RoutePaths.legalEntities
            {
                key: RoutePaths.borrowers,
                path: RoutePaths.borrowers,
                Component: LegalEntitiesPage,
                menuTitle: () => (
                    <Trans i18nKey="App.Header.Menu.LegalEntities" />
                ),
                menuLink: EntityLinks.legalEntities.list(),
                exact: false,
                unavailable: UserStore.canNot.view.legalEntities,
            },
            {
                key: RoutePaths.individuals,
                path: RoutePaths.individuals,
                Component: IndividualsPage,
                menuTitle: () => (
                    <Trans i18nKey="App.Header.Menu.Individuals" />
                ),
                menuLink: EntityLinks.individuals.list(),
                exact: false,
                unavailable: UserStore.canNot.view.individuals,
            },
            {
                key: RoutePaths.products,
                path: RoutePaths.products,
                Component: ProductsPage,
                menuLink: EntityLinks.products.list(),
                exact: false,
                unavailable: UserStore.canNot.view.products,
                menuHide: true,
            },
            {
                key: RoutePaths.campaigns,
                path: RoutePaths.campaigns,
                Component: CampaignsPage,
                menuTitle: () => <Trans i18nKey="App.Header.Menu.Products" />,
                menuLink: EntityLinks.campaigns.list(),
                exact: false,
                unavailable: UserStore.canNot.view.products,
            },
            {
                key: RoutePaths.transactions,
                path: RoutePaths.transactions,
                Component: TransactionsPage,
                menuTitle: () => (
                    <Trans i18nKey="App.Header.Menu.Transactions" />
                ),
                menuLink: EntityLinks.transactions.list(),
                exact: false,
                unavailable: UserStore.canNot.view.transactions,
            },
            {
                key: RoutePaths.collections,
                path: RoutePaths.collections,
                Component: CollectionsPage,
                menuTitle: () => (
                    <Trans i18nKey="App.Header.Menu.Collections" />
                ),
                menuLink: EntityLinks.collections.list(),
                exact: false,
                unavailable: UserStore.canNot.view.collections,
            },
            {
                key: RoutePaths.formioSandBox,
                path: RoutePaths.formioSandBox,
                Component: FormioSandbox,
                exact: false,
                unavailable: !AppEnv.isDev,
            },
        ];
    }

    @computed get menuItems(): RouteDefinition[] {
        return this.routes
            .filter((route) => route.menuTitle)
            .filter((route) => !route.unavailable)
            .filter((route) => !route.menuHide);
    }
}

export const RouterStore = new RouterStoreClass();
