import { computed, observable } from 'mobx';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { TaskRepresentation } from 'src/generated-api-client';
import { TaskStore } from 'src/stores/TaskStore/TaskStore';
import { CombinedLoadingStatus } from 'src/utils/BasicStore/CombinedLoadingStatus';

export class IndexPageViewModelClass {
    @observable loadingStatus = new CombinedLoadingStatus([
        TaskStore.assignedTaskListLoader,
    ]);

    @computed get homePage() {
        // TODO: fix it after implementation of leads page
        const hasTasks = Boolean(TaskStore.assignedTaskList?.content?.length);

        if (hasTasks) {
            const tasksList = TaskStore.assignedTaskList?.content;
            const firstTask = tasksList![0] as TaskRepresentation;
            const urgentTask = TaskStore.getUrgentTaskId;
            const task = TaskStore.isHasUrgentTask ? urgentTask : firstTask?.id;

            return EntityLinks.tasks.itemDetails(task);
        }

        return EntityLinks.tasks.list();
    }
}

export const IndexPageViewModel = new IndexPageViewModelClass();
