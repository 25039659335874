/* eslint-disable max-classes-per-file */
import {
    LoanApplicationApiFindAllApplicationsRequest,
    LoanApplicationBrief,
} from 'src/generated-api-client';
import {
    bpmsResourcesManagerApi,
    loanApplicationsApi,
} from 'src/services/apiServices';
import { InitialFilters } from 'src/stores/LoanApplicationsStore/InitialFilters';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

export type LoanApplicationFilters = Pick<
    LoanApplicationApiFindAllApplicationsRequest,
    | 'filterSearch'
    | 'filterRequestedAtFrom'
    | 'filterRequestedAtTo'
    | 'filterStatuses'
>;

export class LoanApplicationsStoreClass extends BasicStore<
    LoanApplicationBrief,
    LoanApplicationFilters
> {
    itemPathKey = 'loanApplicationId';
    itemDetailsFormKey =
        'embedded:deployment:forms/bo/bo-02-loan-application-info.json';

    api: BasicStoreApi<LoanApplicationBrief> = {
        loadList: async (...args: any[]) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            const response = await RequestHelper.unwrapFromAxiosPromise(
                loanApplicationsApi.findAllApplications(
                    {
                        ...this.pager,
                        ...this.filterCriteria.filter,
                        sort: InitialFilters.defaultFilters,
                    },
                    options,
                ),
            );

            this.pager.setTotal(response.totalElements || 0);

            return response.content || [];
        },
        loadItem: (id, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                loanApplicationsApi.getApplication({ id }, options),
            );
        },
        loadItemForm: (...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                bpmsResourcesManagerApi.getForm(
                    {
                        formKey: this.itemDetailsFormKey,
                    },
                    options,
                ),
            );
        },
    };

    filterCriteria = new FilterCriteria<LoanApplicationFilters>();
    pager = new Pager();
}

export const LoanApplicationsStore = new LoanApplicationsStoreClass();
