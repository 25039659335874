import {
    LegalEntity,
    LegalEntityApiFindAllLegalEntitiesRequest,
} from 'src/generated-api-client';
import {
    bpmsResourcesManagerApi,
    legalEntitiesApi,
} from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

export type LegalEntitiesFilter = Pick<
    LegalEntityApiFindAllLegalEntitiesRequest,
    'filterBlacklisted' | 'filterFullName' | 'filterSearch'
>;
export class LegalEntitiesStoreClass extends BasicStore<
    LegalEntity,
    LegalEntitiesFilter
> {
    itemDetailsFormKey = 'embedded:deployment:forms/bo/bo-12-company.json';

    itemEditFormKey = 'embedded:deployment:forms/bo/bo-31-edit-company.json';
    itemCreateFormKey?: string | undefined =
        'embedded:deployment:forms/bo/bo-42-create-company.json';

    pager = new Pager();
    api: BasicStoreApi<LegalEntity> = {
        loadList: async (...args: any[]) => {
            const options = RequestHelper.getOptionsFromArgs(args);
            const resp = await RequestHelper.unwrapFromAxiosPromise(
                legalEntitiesApi.findAllLegalEntities(
                    {
                        ...this.pager,
                        ...this.filterCriteria.filter,
                    },
                    options,
                ),
            );

            this.pager.setTotal(resp.totalElements || 0);

            return resp.content || [];
        },
        updateItem: (id, data, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                legalEntitiesApi.updateLegalEntity(
                    {
                        id: Number(id),
                        legalEntity: data,
                    },
                    options,
                ),
            );
        },
        loadItemForm: (formKey: string, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                bpmsResourcesManagerApi.getForm({ formKey }, options),
            );
        },
        loadItem: (id: number, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                legalEntitiesApi.getLegalEntity({ id }, options),
            );
        },
        createItem: (data, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                legalEntitiesApi.createLegalEntity(
                    {
                        legalEntity: data,
                    },
                    options,
                ),
            );
        },
    };

    filterCriteria: FilterCriteria<LegalEntitiesFilter> = new FilterCriteria(
        {},
    );
}

export const LegalEntitiesStore = new LegalEntitiesStoreClass();
