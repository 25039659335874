import { notification } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TaskWithNotificationActionViewModel } from 'src/components/FormForTaskWithAction/TaskWithActionViewModel';
import { AssignedTasksStore } from 'src/stores/AssignedTasksStore/AssignedTasksStore';
import { TaskWithActionStore } from 'src/stores/TaskWithActionStore/TaskWithActionStore';

export const NotificationTaskComponent = observer(() => {
    const { t } = useTranslation();
    const taskNotificationId =
        TaskWithActionStore.taskWithNotificationAction?.id || '';

    const handleClose = async (taskId: string, data: any) => {
        await TaskWithActionStore.submitForm(taskId, data);
        TaskWithActionStore.reset();
        AssignedTasksStore.loadList();
    };

    useEffect(() => {
        if (!taskNotificationId) return;

        TaskWithNotificationActionViewModel.loadItem(taskNotificationId);
    }, [taskNotificationId]);

    useEffect(() => {
        if (!TaskWithNotificationActionViewModel.taskVariables) return;

        if (TaskWithNotificationActionViewModel.taskVariables) {
            const taskVariablesData =
                TaskWithNotificationActionViewModel.taskVariables?.data;
            const message = taskVariablesData?.message;
            const hasL10nTitleKey = Boolean(
                TaskWithNotificationActionViewModel?.currentTaskDefinition
                    ?.extensions?.l10nTitleKey,
            );
            const title = hasL10nTitleKey
                ? t(
                      `${TaskWithNotificationActionViewModel?.currentTaskDefinition?.extensions?.l10nTitleKey}`,
                      {
                          ...TaskWithNotificationActionViewModel
                              ?.currentTaskDefinition?.taskInfo,
                      },
                  )
                : TaskWithNotificationActionViewModel?.currentTaskDefinition
                      ?.name;

            notification.open({
                message: title,
                description: message,
                key: taskNotificationId,
                duration: 0,
                onClose: () =>
                    handleClose(taskNotificationId, taskVariablesData),
            });
        }
    }, [TaskWithNotificationActionViewModel.taskVariables]);

    return <div />;
});
