/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable complexity */
/* eslint-disable no-param-reassign */
import Components from 'formiojs/components/Components';

const ContainerComponent = (Components as any).components.container;

class Container extends (ContainerComponent as any) {
    setValue(value: any, flags: any) {
        if (this.options?.readOnly) {
            this.redraw();

            return super.setValue(value, flags);
        }

        const res = super.setValue(value, flags);
        this.redraw();

        return res;
    }
}

export default Container;
