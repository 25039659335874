/* eslint-disable max-lines */
import { PlusOutlined } from '@ant-design/icons';
import { Form, Select, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { SearchPanel } from 'src/components/SearchPanel/SearchPanel';
import {
    SearchPanelFilterForm,
    SearchPanelFilterFormProps,
} from 'src/components/SearchPanel/SearchPanelFilterForm';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { Router } from 'src/core/router/Router';
import {
    CalculationType,
    CampaignsFilter,
    CampaignsStore,
} from 'src/stores/CampaignsStore/CampaignsStore';
import { UserStore } from 'src/stores/UserStore/UserStore';
import styled from 'styled-components';

const store = CampaignsStore;

export const CampaignsPageHeaderPanel = observer(() => {
    const { t } = useTranslation();

    return (
        <StyledHeaderWrapper>
            <SearchPanel
                store={store}
                renderPanel={(props) => <FilterPanel {...props} />}
                placeholder={t('Page.Products.AsideHeader.Search.Placeholder', {
                    defaultValue: 'Search',
                })}
            />
            {UserStore.can.manage.products && (
                <Tooltip
                    title={t(
                        'Page.Campaign.AsideHeader.CreateNewItem.Tooltip',
                        { defaultValue: 'Create new campaign' },
                    )}
                >
                    <StyledCreateButton
                        onClick={() => {
                            const createLink = EntityLinks.campaigns.create?.();
                            createLink && Router.navigate(createLink);
                        }}
                    >
                        <PlusOutlined />
                    </StyledCreateButton>
                </Tooltip>
            )}
        </StyledHeaderWrapper>
    );
});

const StyledHeaderWrapper = styled.header`
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto;
`;

const StyledCreateButton = styled.button`
    width: 3.6rem;
    min-width: 3rem;
    font-weight: 700;
    height: 2.4rem;
    margin-left: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: 2rem;
    border: none;
    background: var(--btn-bg);
    border-radius: 0.35rem;
    color: #ffffff;
    span: {
        font-size: 1rem;
    }
`;

const FilterPanel = observer((props: SearchPanelFilterFormProps) => {
    const { t } = useTranslation();

    return (
        <SearchPanelFilterForm
            {...props}
            onFinish={({ ...values }: CampaignsFilter) => {
                store.filterCriteria?.applyFilter({
                    ...values,
                });
                store.loadList();
            }}
        >
            <StyledSelect
                label={t('Page.Products.Filter.Status', {
                    defaultValue: 'Status',
                })}
                name="filterActive"
            >
                <Select allowClear>
                    <Select.Option value="true">
                        {t(`data:status.product.ACTIVE`)}
                    </Select.Option>
                    <Select.Option value="false">
                        {t(`data:status.product.INACTIVE`)}
                    </Select.Option>
                </Select>
            </StyledSelect>

            <StyledSelect
                label={t(
                    'bo:bo-components.bo-ct-21-campaign-parameters.calculationType',
                    {
                        defaultValue: 'Calculation Type',
                    },
                )}
                name="filterCalculationTypes"
            >
                <Select mode="multiple" allowClear>
                    <Select.Option value={CalculationType.MCA}>
                        {t(`data:type.calculation.MCA`)}
                    </Select.Option>
                    <Select.Option value={CalculationType.NON_MCA}>
                        {t(`data:type.calculation.NON_MCA`)}
                    </Select.Option>
                    <Select.Option value={CalculationType.CUSTOM}>
                        {t(`data:type.calculation.CUSTOM`)}
                    </Select.Option>
                </Select>
            </StyledSelect>
        </SearchPanelFilterForm>
    );
});

const StyledSelect = styled(Form.Item)`
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        display: flex;
        align-items: center;
        border-radius: 0.5rem;
    }
`;
