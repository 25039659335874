import {
    BPMSResourcesManagementApiFactory,
    CampaignApiFactory,
    CollectionApiFactory,
    Configuration,
    IndividualApiFactory,
    LegalEntityApiFactory,
    LoanApiFactory,
    LoanApplicationApiFactory,
    LoanProductApiFactory,
    ProcessDefinitionManagerApiFactory,
    TaskManagerApiFactory,
    TransactionApiFactory,
    UISettingsApiFactory,
} from 'src/generated-api-client';

const apiConfig: Configuration | undefined = undefined;
const basePath = '';

const filesApiPath = '/api/file?';
export const getFileUrl = (filename: string) => {
    const searchParams = new URLSearchParams();
    searchParams.append('form', filename);

    return `${filesApiPath}${searchParams.toString()}`;
};

export const processDefinitionManagerApi = ProcessDefinitionManagerApiFactory(
    apiConfig,
    basePath,
);

export const taskManagerApi = TaskManagerApiFactory(apiConfig, basePath);

export const bpmsResourcesManagerApi = BPMSResourcesManagementApiFactory(
    apiConfig,
    basePath,
);

export const uiSettingsApi = UISettingsApiFactory(apiConfig, basePath);

export const loanApplicationsApi = LoanApplicationApiFactory(
    apiConfig,
    basePath,
);

export const loansApi = LoanApiFactory(apiConfig, basePath);

export const productsApi = LoanProductApiFactory(apiConfig, basePath);
export const campaignsApi = CampaignApiFactory(apiConfig, basePath);
export const transactionsApi = TransactionApiFactory(apiConfig, basePath);
export const collectionsApi = CollectionApiFactory(apiConfig, basePath);

export const legalEntitiesApi = LegalEntityApiFactory(apiConfig, basePath);

export const individualsApi = IndividualApiFactory(apiConfig, basePath);
