import { Layout } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { FormWrapperForTaskWithAction } from 'src/components/FormForTaskWithAction/FormWrapperForTaskWithAction';
import { NotificationTaskComponent } from 'src/components/FormForTaskWithAction/NotificationTaskComponent';
import { Header } from 'src/components/Header';
import { SplashScreen } from 'src/components/SplashScreen/SplashScreen';
import { RoutesLayout } from 'src/containers/RoutesLayout/RoutesLayout';
import { UrgentTaskView } from 'src/pages/UrgentTaskView/UrgentTaskView';
import { AppStore } from 'src/stores/AppStore/AppStore';
import { AuthStore } from 'src/stores/AuthStore/AuthStore';
import { TaskStore } from 'src/stores/TaskStore/TaskStore';
import styled from 'styled-components';

export const AppLayout = observer(() => {
    useEffect(() => {
        if (AuthStore.authenticated) {
            TaskStore.loadAssignedTaskList();
        }
    }, [AuthStore.authenticated]);

    if (
        !AuthStore.authenticated ||
        !AppStore.started ||
        !TaskStore.assignedTaskLoaded
    ) {
        return <SplashScreen />;
    }

    return (
        <Layout>
            <NotificationTaskComponent />
            <FormWrapperForTaskWithAction />
            {TaskStore.isHasUrgentTask ? (
                <UrgentTaskView />
            ) : (
                <>
                    <Header />
                    <StyledContent>
                        <RoutesLayout />
                    </StyledContent>
                </>
            )}
        </Layout>
    );
});

const StyledContent = styled(Layout.Content)`
    background-color: var(--background);
`;
