import { History } from 'history';
import { action, observable } from 'mobx';
import { Router } from 'src/core/router/Router';
import { configureMobX } from 'src/mobXSettings';
import { AuthStore } from 'src/stores/AuthStore/AuthStore';
import { SettingsStore } from 'src/stores/SettingsStore/SettingsStore';
import { UserStore } from 'src/stores/UserStore/UserStore';

export class AppStoreClass {
    @observable started = false;

    @action
    async init() {
        configureMobX();
        await SettingsStore.load();

        if (SettingsStore.hasSettings) {
            await SettingsStore.setup();
            await AuthStore.authenticate();
        }
        if (AuthStore.authenticated) {
            await UserStore.loadUserInfo();
        }
    }

    setHistory(history: History) {
        Router.setHistory(history);
        this.started = true;
    }
}

export const AppStore = new AppStoreClass();
