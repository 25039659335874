import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useRouteMatch } from 'react-router-dom';
import { ProcessStartForm } from 'src/components/ProcessStartForm';
import { Router } from 'src/core/router/Router';
import { ProcessStoreProvider } from 'src/stores/ProcessStore/ProcessStore';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';

export type EntityProcessProps = {
    store: BasicStore<any>;
    entityListUrl: string;
};

type EntityProcessRouteMath = { entityId: string; processKey: string };

export const EntityTypeProcess = observer(
    ({ store, entityListUrl }: EntityProcessProps) => {
        const { params } = useRouteMatch<EntityProcessRouteMath>();
        const { processKey } = params;
        const process = ProcessStoreProvider.getInstance(processKey);

        const processContext = _.merge(process.getContext); // TODO: refactor context

        return (
            <ProcessStartForm
                processKey={processKey}
                key="process"
                context={processContext}
                onSubmit={() => {
                    store.loadList();
                    Router.navigate(entityListUrl);
                }}
                onCustomEvent={(event) => {
                    if (event.type === 'navigateToEntityCard') {
                        event.event.preventDefault();
                        Router.navigate(entityListUrl);
                    }
                    if (event.type === 'cancel') {
                        event.event.preventDefault();
                        Router.navigate(entityListUrl);
                    }
                }}
            />
        );
    },
);
