import { List } from 'antd';
import { observer } from 'mobx-react-lite';
import { EntityPresentation } from 'src/components/EntityPresentation/EntityPresentation';
import { MasterDetailsAside } from 'src/components/MasterDetailsPage/MasterDetailsAside';
import { MasterDetailsPage } from 'src/components/MasterDetailsPage/MasterDetailsPage';
import { Individual } from 'src/generated-api-client';
import { IndividualsHeaderPanel } from 'src/pages/IndividualsPage/IndividualHeaderPanel';
import { IndividualsListItem } from 'src/pages/IndividualsPage/IndividualsListItem';
import { IndividualsStore } from 'src/stores/IndividualsStore/IndividualsStore';

const store = IndividualsStore;

export const IndividualsPage = observer(() => {
    return (
        <MasterDetailsPage
            pageName="individuals"
            store={store}
            asidePanel={<IndividualsAsidePanel />}
        >
            <EntityPresentation store={store} entityName="individuals" />
        </MasterDetailsPage>
    );
});

const IndividualsAsidePanel = observer(() => {
    return (
        <MasterDetailsAside
            store={store}
            renderItem={(item: Individual) => {
                return (
                    <List.Item key={item.id}>
                        <IndividualsListItem item={item} />
                    </List.Item>
                );
            }}
            header={<IndividualsHeaderPanel />}
        />
    );
});
