import {
    CampaignApiFindAllCampaignsRequest,
    CampaignBrief,
} from 'src/generated-api-client';
import {
    bpmsResourcesManagerApi,
    campaignsApi,
} from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

export enum CalculationType {
    MCA = 'MCA',
    NON_MCA = 'NON_MCA',
    CUSTOM = 'CUSTOM',
}

export type CampaignsFilter = Pick<
    CampaignApiFindAllCampaignsRequest,
    'filterActive' | 'filterCalculationTypes' | 'filterSearch'
>;

export class CampaignsStoreClass extends BasicStore<CampaignBrief> {
    itemDetailsFormKey = 'embedded:deployment:forms/bo/bo-19-campaign.json';

    itemEditFormKey = 'embedded:deployment:forms/bo/bo-21-edit-campaign.json';

    itemCreateFormKey =
        'embedded:deployment:forms/bo/bo-20-create-campaign.json';

    pager = new Pager();

    api: BasicStoreApi<CampaignBrief> = {
        loadList: async (...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            const resp = await RequestHelper.unwrapFromAxiosPromise(
                campaignsApi.findAllCampaigns(
                    {
                        ...this.pager,
                        ...this.filterCriteria.filter,
                    },
                    options,
                ),
            );

            this.pager.setTotal(resp.totalElements || 0);

            return resp.content || [];
        },

        loadItemForm: (formKey: string, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                bpmsResourcesManagerApi.getForm({ formKey }, options),
            );
        },
        loadItem: (id: number, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                campaignsApi.getCampaign({ id }, options),
            );
        },
        updateItem: (id, data, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                campaignsApi.updateCampaign(
                    { id: Number(id), campaign: data },
                    options,
                ),
            );
        },
        createItem: (data, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                campaignsApi.createCampaign({ campaign: data }, options),
            );
        },
    };

    filterCriteria: FilterCriteria<CampaignsFilter> = new FilterCriteria();
}

export const CampaignsStore = new CampaignsStoreClass();
