/* eslint-disable sonarjs/cognitive-complexity */
import { Button, Col, List } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { Router } from 'src/core/router/Router';
import { TaskRepresentation } from 'src/generated-api-client';
import { TaskViewViewModel } from 'src/pages/TasksPage/TaskViewViewModel';
import { AssignedTasksStore } from 'src/stores/AssignedTasksStore/AssignedTasksStore';
import { AvailableTasksStore } from 'src/stores/AvailabaleTasksStore/AvailableTasksStore';
import { DateHelper } from 'src/utils/DateHelper';
import styled from 'styled-components';

export type TaskListItemProps = {
    item: TaskRepresentation;
};

export const TaskListItem = observer(({ item }: TaskListItemProps) => {
    const { t } = useTranslation();

    const hasL10nTitleKey = Boolean(item.extensions?.l10nTitleKey);
    const title = hasL10nTitleKey
        ? t(`${item.extensions?.l10nTitleKey}`, {
              ...item?.taskInfo,
          })
        : item.name;

    const isCancellableTask =
        (item?.extensions as unknown as { type: string })?.type ===
        'non-cancellable';

    const CancelButton = () => {
        return isCancellableTask ? null : (
            <StyledButtonCancel
                className="show-on-hover"
                onClick={async (e) => {
                    e.preventDefault();
                    AvailableTasksStore.listLoader.turnOffSilentMode();
                    AssignedTasksStore.listLoader.turnOffSilentMode();
                    await AssignedTasksStore.unclaim(item.id);
                    await AvailableTasksStore.loadList();
                    if (item.id === TaskViewViewModel.taskDefinition?.id) {
                        TaskViewViewModel.loadItem(item.id);
                    }
                }}
            >
                {t('Page.Tasks.AsidePanel.Item.Cancel', {
                    defaultValue: 'Cancel',
                })}
            </StyledButtonCancel>
        );
    };

    return (
        <StyledListItem>
            <TaskStyledLink to={EntityLinks.tasks.itemDetails(item.id)}>
                <LinkInnerContainer>
                    <StyledInfoCol flex={1}>{title}</StyledInfoCol>
                    <StyledDateCol
                        className={isCancellableTask ? '' : 'hide-on-hover'}
                    >
                        {DateHelper.format(item.createTime)}
                    </StyledDateCol>
                    {item.assignee ? (
                        <CancelButton />
                    ) : (
                        <StyledButton
                            className="show-on-hover"
                            onClick={async (e) => {
                                e.preventDefault();
                                AssignedTasksStore.listLoader.turnOffSilentMode();
                                AvailableTasksStore.listLoader.turnOffSilentMode();
                                await AvailableTasksStore.claim(item.id);
                                await AssignedTasksStore.loadList();
                                Router.navigate(`/tasks/${item.id}`);
                                if (
                                    item.id ===
                                    TaskViewViewModel.taskDefinition?.id
                                ) {
                                    TaskViewViewModel.loadItem(item.id);
                                }
                            }}
                        >
                            {t('Page.Tasks.AsidePanel.Item.Claim', {
                                defaultValue: 'Claim',
                            })}
                        </StyledButton>
                    )}
                </LinkInnerContainer>
            </TaskStyledLink>
        </StyledListItem>
    );
});

const StyledListItem = styled(List.Item)`
    position: relative;
    background: var(--background);
    color: var(--text-color);
    .show-on-hover {
        display: none;
    }
    &:hover {
        .hide-on-hover {
            display: none;
        }

        .show-on-hover {
            display: inline-block;
        }
    }
`;

const StyledButton = styled(Button)`
    color: white;
    background: var(--btn-bg);
    margin-top: 0.18rem;

    font-size: 1rem;
    line-height: 125%;
    position: absolute;
    right: 0.82rem;
    top: 0.5rem;
    height: auto;
    padding: 0.3rem 0.7rem;

    &:active,
    &:focus,
    &:hover {
        background: var(--btn-bg);
        color: white;
        border-color: var(--btn-bg);
    }
`;

const StyledButtonCancel = styled(Button)`
    color: white;
    background: var(--btn-bg);

    font-size: 0.82rem;
    line-height: 150%;
    height: 1.82rem;
    position: absolute;
    right: 0.82rem;
    top: 0.6rem;
    padding: 0 0.6rem;
    border-color: var(--btn-bg);

    &:active,
    &:focus,
    &:hover {
        background: var(--btn-bg-hover);
        border-color: var(--btn-bg-hover);
        color: white;
    }
`;

const StyledInfoCol = styled(Col)`
    width: 15rem;
    flex: initial !important;
    font-weight: 400;
    font-size: 1rem;
    line-height: 125%;
`;

const StyledDateCol = styled(Col)`
    font-size: 0.8rem;
    line-height: 150%;

    text-align: right;

    color: #828b94;
`;

const TaskStyledLink = styled(NavLink)`
    display: block;
    padding: 0.7rem 0.6rem;
    background: var(--aside-list-item-bg);
    font-size: 1.7rem;
    color: var(--text-color);
    border-radius: 0.75rem;

    &:hover {
        background: var(--background);
        border-color: var(--background);
        background: var(--aside-list-active-bg);
        color: var(--text-color);
        text-decoration: none;
    }

    &.active {
        background: var(--aside-list-active-bg);
        border-color: var(--aside-list-active-bg);
        color: var(--text-color);
    }
`;

const LinkInnerContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
