import {
    convertMapToList,
    DEFAULT_STATUS_KEY,
    StatusInfo,
} from 'src/components/EntityStatusInfo/EntityStatusInfo';
import { CssColors } from 'src/styles/CssVariables';

export const CollectionsStatusInfoMap: Record<string, StatusInfo> = {
    COLLECTION: {
        color: CssColors.mainGreen,
        borderColor: CssColors.mainGreen,
        title: 'Collection',
        backgroundColor: CssColors.mainGreen,
    },
    ENFORCEMENT: {
        color: CssColors.mainGray,
        borderColor: CssColors.mainGray,
        title: 'Enforcement',
        backgroundColor: CssColors.mainGray,
    },
    CLOSED: {
        color: CssColors.mainRed,
        borderColor: CssColors.mainRed,
        title: 'Closed',
        backgroundColor: CssColors.mainRed,
    },
    [DEFAULT_STATUS_KEY]: {
        color: CssColors.mainGray,
        borderColor: CssColors.mainGray,
        title: '',
        backgroundColor: CssColors.mainGray,
    },
};

export const getCollectionStatuses = () => {
    return convertMapToList(CollectionsStatusInfoMap);
};
