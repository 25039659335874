import {
    LoanProduct,
    LoanProductApiFindAllProductsRequest,
} from 'src/generated-api-client';
import { bpmsResourcesManagerApi, productsApi } from 'src/services/apiServices';
import { initialProductsFilters } from 'src/stores/ProductsStore/InitialFilters';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

export type ProductsFilter = Pick<
    LoanProductApiFindAllProductsRequest,
    | 'filterActive'
    | 'filterAmount'
    | 'filterCampaignId'
    | 'filterInterestRate'
    | 'filterPaymentFrequency'
    | 'filterSearch'
    | 'filterTerm'
>;

export class ProductsStoreClass extends BasicStore<LoanProduct> {
    itemDetailsFormKey = 'embedded:deployment:forms/bo/bo-14-product.json';

    itemEditFormKey = 'embedded:deployment:forms/bo/bo-16-edit-product.json';

    itemCreateFormKey =
        'embedded:deployment:forms/bo/bo-15-create-product.json';

    pager = new Pager();

    api: BasicStoreApi<LoanProduct> = {
        loadList: async (...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);
            const resp = await RequestHelper.unwrapFromAxiosPromise(
                productsApi.findAllProducts(
                    {
                        ...this.pager,
                        ...initialProductsFilters,
                        ...this.filterCriteria.filter,
                    },
                    options,
                ),
            );

            this.pager.setTotal(resp.totalElements || 0);

            return resp.content || [];
        },

        loadItemForm: (formKey: string, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                bpmsResourcesManagerApi.getForm({ formKey }, options),
            );
        },
        loadItem: (id: number, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                productsApi.getProduct({ id }, options),
            );
        },
        updateItem: (id, data, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                productsApi.updateProduct(
                    {
                        id: Number(id),
                        loanProduct: data,
                    },
                    options,
                ),
            );
        },
        createItem: (data, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                productsApi.createProduct({ loanProduct: data }, options),
            );
        },
    };

    filterCriteria: FilterCriteria<ProductsFilter> = new FilterCriteria();

    // @computed get hasFilter() {
    //     const { active, maxAmount, maxTerm, search, campaignId } =
    //         this.filterCriteria.filter;

    //     return (
    //         !_.isNil(active) ||
    //         !_.isNil(maxAmount) ||
    //         !_.isNil(maxTerm) ||
    //         !_.isNil(search) ||
    //         !_.isNil(campaignId)
    //     );
    // }
}

export const ProductsStore = new ProductsStoreClass();
