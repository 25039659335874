import { Layout, Typography } from 'antd';
import { BasicProps } from 'antd/lib/layout/layout';
import { observer } from 'mobx-react-lite';
import { AppFeature } from 'src/app-configuration/AppConfiguration.types';
import { Spinner } from 'src/components/Spinner/Spinner';
import styled from 'styled-components';

export enum PageType {
    BASE,
    WIDE_FORM,
    SIMPLE_FORM,
    FULL,
}
export type BasePageProps = Omit<React.HTMLAttributes<any>, 'title'> & {
    title?: React.ReactNode;
    pageName: AppFeature;
    isLoading?: boolean;
    isUpdating?: boolean;
    type?: PageType;
};
export const BasePage: React.FC<BasePageProps> = observer(
    ({ children, isLoading, title, type = PageType.BASE, ...props }) => {
        return (
            <StyledContent type={type} {...props}>
                {title && <StyledPageTitle>{title}</StyledPageTitle>}
                {isLoading ? <StyledSpinner /> : children}
            </StyledContent>
        );
    },
);

export const StyledContent = styled<React.FC<BasicProps & { type?: PageType }>>(
    Layout.Content,
)`
    max-width: ${(props) => getMaxWithByType(props.type)};
    margin: 0 auto;
    position: relative;
    padding: 2rem 0 0;
`;

const getMaxWithByType = (type?: PageType) => {
    if (type === PageType.WIDE_FORM) {
        return '800px';
    }
    if (type === PageType.SIMPLE_FORM) {
        return '580px';
    }
    if (type === PageType.FULL) {
        return '100%';
    }

    return '67rem';
};
const StyledSpinner = styled(Spinner)`
    margin: 0 auto;
    display: block;
`;

const StyledPageTitle = styled(Typography.Title)`
    font-size: 40px;
    line-height: 105%;
    color: var(--text-color);

    &.ant-typography {
        margin-bottom: 35px;
    }
`;
