/* eslint-disable sonarjs/cognitive-complexity */
import _ from 'lodash';
import { AppFeature } from 'src/app-configuration/AppConfiguration.types';
import { ApplicationsConfig } from 'src/stores/UserStore/AppFeatureConfigs/ApplicationsConfig';
import { CollectionsConfig } from 'src/stores/UserStore/AppFeatureConfigs/CollectionsConfig';
import { IndividualsConfig } from 'src/stores/UserStore/AppFeatureConfigs/IndividualsConfig';
import { LegalEntitiesConfig } from 'src/stores/UserStore/AppFeatureConfigs/LegalEntitiesConfig';
import { LoansConfig } from 'src/stores/UserStore/AppFeatureConfigs/LoansConfig';
import { ProductCampaignsConfig } from 'src/stores/UserStore/AppFeatureConfigs/ProductCampaignsConfig';
import { TasksConfig } from 'src/stores/UserStore/AppFeatureConfigs/TasksConfig';
import { TransactionsConfig } from 'src/stores/UserStore/AppFeatureConfigs/TransactionsConfig';
import { UserRole } from 'src/stores/UserStore/UserRole';
import {
    Action,
    EntityConfig,
    ReadablePermissions,
} from 'src/stores/UserStore/UserStore.types';

export const userPermissionsConfig: Record<AppFeature, EntityConfig> = {
    tasks: TasksConfig,
    applications: ApplicationsConfig,

    loans: LoansConfig,
    borrowers: LegalEntitiesConfig,
    products: ProductCampaignsConfig,
    campaigns: ProductCampaignsConfig,

    transactions: TransactionsConfig,

    operations: {
        view: true,
        manage: true,
    },
    collections: CollectionsConfig,
    profile: {
        view: false,
        manage: false,
    },
    individuals: IndividualsConfig,
    legalEntities: LegalEntitiesConfig,
};

export const generateReadablePermissions = (
    userRoles: UserRole[],
    positive: boolean,
) => {
    const allActions: Array<Action> = ['manage', 'view'];

    return Object.fromEntries(
        allActions.map((action) => {
            const calculatedActionPermissions = Object.fromEntries(
                Object.entries(userPermissionsConfig).map(
                    ([entity, entityConfig = {}]: [string, EntityConfig]) => {
                        if (typeof entityConfig[action] === 'boolean') {
                            const canDoActionsWithEntity = positive
                                ? entityConfig[action]
                                : !entityConfig[action];

                            return [entity, canDoActionsWithEntity];
                        }
                        const actionRoles = entityConfig[action] || [];

                        if (Array.isArray(actionRoles)) {
                            const canDoActionsWithEntity =
                                _.intersection(actionRoles, userRoles).length >
                                0;

                            return [
                                entity,
                                positive
                                    ? canDoActionsWithEntity
                                    : !canDoActionsWithEntity,
                            ];
                        }

                        return [entity, !positive];
                    },
                ),
            );

            return [action, calculatedActionPermissions];
        }),
    ) as ReadablePermissions;
};
