import { action } from 'mobx';
import { TaskRepresentation } from 'src/generated-api-client';
import { taskManagerApi } from 'src/services/apiServices';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

const DEFAULT_TASKS_LIST_SIZE = 20;
const DEFAULT_TASKS_PAGE_NUMBER = 0;

export class AvailableTasksStoreClass extends BasicStore<TaskRepresentation> {
    api: BasicStoreApi<TaskRepresentation> = {
        loadList: async (...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);
            const resp = await RequestHelper.unwrapFromAxiosPromise(
                taskManagerApi.listAvailable(
                    {
                        page: DEFAULT_TASKS_PAGE_NUMBER,
                        size: DEFAULT_TASKS_LIST_SIZE,
                    },
                    options,
                ),
            );

            return resp.content || [];
        },
    };

    itemDetailsFormKey = '';

    claimTaskAction = new AsyncOperationWithStatus((id: string) =>
        RequestHelper.unwrapFromAxiosPromise(taskManagerApi.claim({ id })),
    );

    @action async claim(id: string) {
        await this.claimTaskAction.call(id);
        await this.loadList();
    }
}

export const AvailableTasksStore = new AvailableTasksStoreClass();
