import {
    Loan,
    LoanApiFindAllLoansRequest,
    LoanBrief,
} from 'src/generated-api-client';
import { bpmsResourcesManagerApi, loansApi } from 'src/services/apiServices';
import { initialLoansFilter } from 'src/stores/LoansStore/InitialFilters';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

export type LoansFilter = Pick<
    LoanApiFindAllLoansRequest,
    | 'filterSearch'
    | 'filterActivatedAtFrom'
    | 'filterActivatedAtTo'
    | 'filterStatuses'
>;

export class LoansStoreClass extends BasicStore<LoanBrief, Loan> {
    itemPathKey = 'loanId';
    itemDetailsFormKey = 'embedded:deployment:forms/bo/bo-03-loan.json';
    pager = new Pager();
    api: BasicStoreApi<LoanBrief> = {
        loadList: async (...args: any[]) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            const response = await RequestHelper.unwrapFromAxiosPromise(
                loansApi.findAllLoans(
                    {
                        ...this.pager,
                        ...initialLoansFilter,
                        ...this.filterCriteria.filter,
                        sort: initialLoansFilter.defaultSort,
                    },
                    options,
                ),
            );

            this.pager.setTotal(response.totalElements || 0);

            return response.content || [];
        },
        loadItem: (id, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                loansApi.getLoan({ id }, options),
            );
        },
        loadItemForm: (...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                bpmsResourcesManagerApi.getForm(
                    {
                        formKey: this.itemDetailsFormKey,
                    },
                    options,
                ),
            );
        },
    };

    filterCriteria = new FilterCriteria<LoansFilter>();
}

export const LoansStore = new LoansStoreClass();
