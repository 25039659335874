import { Row, Tooltip } from 'antd';
import { truncate } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { EntityStatusInfo } from 'src/components/EntityStatusInfo/EntityStatusInfo';
import {
    MainContainer,
    MainEntityListItemText,
    MasterDetailsAsideItem,
    SecondaryContainer,
    SecondaryEntityListItemText,
} from 'src/components/MasterDetailsPage/MasterDetailsAsideItem';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { Campaign } from 'src/generated-api-client';
import { ProductsStatusInfoMap } from 'src/pages/ProductsPage/ProductsStatusInfoMap';

type CampaignsListItemProps = { item: Campaign };

export const CampaignsListItem = observer(
    ({ item }: CampaignsListItemProps) => {
        const { t } = useTranslation();

        return (
            <MasterDetailsAsideItem
                to={EntityLinks.campaigns.itemDetails(item.id)}
            >
                <MainContainer>
                    <Row>
                        <div>
                            <MainEntityListItemText className="strong-text">{`${item.id} `}</MainEntityListItemText>
                            <Tooltip title={item.name}>
                                <MainEntityListItemText>
                                    {truncate(item.name, {
                                        length: 51,
                                        separator: ' ',
                                        omission: '...',
                                    })}
                                </MainEntityListItemText>
                            </Tooltip>
                        </div>
                    </Row>

                    <EntityStatusInfo
                        statusInfoMap={ProductsStatusInfoMap}
                        status={getStatus(item.active)}
                        entityName="product"
                    />
                </MainContainer>
                <SecondaryContainer justifyContent="flex-start">
                    <SecondaryEntityListItemText>
                        <span className="strong-text">
                            {t(
                                `data:type.calculation.${item?.calculationType}`,
                            )}
                        </span>
                    </SecondaryEntityListItemText>
                </SecondaryContainer>
            </MasterDetailsAsideItem>
        );
    },
);

const getStatus = (status?: boolean | null) => {
    if (status) {
        return 'ACTIVE';
    }

    return 'INACTIVE';
};
