/* eslint-disable max-lines */
import { Form, Input, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { SearchPanel } from 'src/components/SearchPanel/SearchPanel';
import {
    SearchPanelFilterForm,
    SearchPanelFilterFormProps,
} from 'src/components/SearchPanel/SearchPanelFilterForm';
import {
    ProductsFilter,
    ProductsStore,
} from 'src/stores/ProductsStore/ProductsStore';
import styled from 'styled-components';

const store = ProductsStore;

export const ProductsHeaderPanel = observer(() => {
    const { t } = useTranslation();

    return (
        <StyledHeaderWrapper>
            <SearchPanel
                store={store}
                renderPanel={(props) => <FilterPanel {...props} />}
                placeholder={t('Page.Products.AsideHeader.Search.Placeholder', {
                    defaultValue: 'Search',
                })}
            />
        </StyledHeaderWrapper>
    );
});

const StyledHeaderWrapper = styled.header`
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto;
`;

const FilterPanel = observer((props: SearchPanelFilterFormProps) => {
    const { t } = useTranslation();

    return (
        <SearchPanelFilterForm
            {...props}
            initialValues={{ filterActive: 'true', issuedAt: [] }}
            onFinish={({ filterActive, ...values }: ProductsFilter) => {
                store.filterCriteria?.applyFilter({
                    ...values,
                    filterActive,
                });
                store.loadList();
            }}
        >
            <StyledSelect
                label={t('Page.Products.Filter.Status', {
                    defaultValue: 'Status',
                })}
                name="filterActive"
            >
                <Select allowClear>
                    <Select.Option value="true">
                        {t(`data:status.product.ACTIVE`)}
                    </Select.Option>
                    <Select.Option value="false">
                        {t(`data:status.product.INACTIVE`)}
                    </Select.Option>
                </Select>
            </StyledSelect>
            <Form.Item
                label={t('Page.Products.Filter.MaxAmount', {
                    defaultValue: 'Max amount',
                })}
                name="filterAmount"
            >
                <StyledInput type="number" />
            </Form.Item>
            <Form.Item
                label={t('Page.Products.Filter.MaxTerm', {
                    defaultValue: 'Max term',
                })}
                name="filterTerm"
            >
                <StyledInput type="number" />
            </Form.Item>
        </SearchPanelFilterForm>
    );
});

const StyledSelect = styled(Form.Item)`
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        display: flex;
        align-items: center;
        height: 2.7rem;
        border-radius: 0.5rem;
    }
`;

const StyledInput = styled(Input)`
    height: 2.7rem;
    border-radius: 0.5rem;
`;
