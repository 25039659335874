import {
    TransactionApiFindAllTransactionsRequest,
    TransactionBrief,
} from 'src/generated-api-client';
import {
    bpmsResourcesManagerApi,
    transactionsApi,
} from 'src/services/apiServices';
import { InitialFilters } from 'src/stores/TransactionsStore/InitialFilters';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

export type TransactionsFilter = Pick<
    TransactionApiFindAllTransactionsRequest,
    | 'filterCreatedAtFrom'
    | 'filterCreatedAtTo'
    | 'filterProcessedAtFrom'
    | 'filterProcessedAtTo'
    | 'filterSearch'
    | 'filterStatuses'
    | 'filterTypes'
>;

export class TransactionsStoreClass extends BasicStore<TransactionBrief> {
    itemDetailsFormKey =
        'embedded:deployment:forms/bo/bo-01-transaction-info.json';

    api: BasicStoreApi<TransactionBrief> = {
        loadList: async (...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            const resp = await RequestHelper.unwrapFromAxiosPromise(
                transactionsApi.findAllTransactions(
                    {
                        ...this.pager,
                        ...this.filterCriteria.filter,
                        sort: InitialFilters.defaultSort,
                    },
                    options,
                ),
            );

            this.pager.setTotal(resp.totalElements || 0);

            return resp.content || [];
        },
        loadItemForm: (formKey: string, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                bpmsResourcesManagerApi.getForm({ formKey }, options),
            );
        },
        loadItem: (id: number, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                transactionsApi.getTransaction({ id }, options),
            );
        },
    };

    filterCriteria: FilterCriteria<TransactionsFilter> = new FilterCriteria({});

    pager = new Pager();
}

export const TransactionsStore = new TransactionsStoreClass();
