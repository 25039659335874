import { Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { EllipsisContainer } from 'src/components/EllipsisContainer/EllipsisContainer';
import {
    MainContainer,
    MainEntityListItemText,
    MasterDetailsAsideItem,
    SecondaryContainer,
    SecondaryEntityListItemText,
} from 'src/components/MasterDetailsPage/MasterDetailsAsideItem';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { getMaskedPhoneValue } from 'src/formio/utils/getDisabledValue';
import { Individual } from 'src/generated-api-client';

type IndividualListItemProps = { item: Individual };

export const IndividualsListItem = observer(
    ({ item }: IndividualListItemProps) => {
        return (
            <MasterDetailsAsideItem
                to={EntityLinks.individuals.itemDetails(item.id)}
            >
                <MainContainer>
                    <Tooltip title={item.number}>
                        <EllipsisContainer>
                            <MainEntityListItemText className="strong-text">{`${item.number} `}</MainEntityListItemText>
                        </EllipsisContainer>
                    </Tooltip>
                    <Tooltip title={item.fullName}>
                        <EllipsisContainer>
                            <MainEntityListItemText>
                                {item.fullName}
                            </MainEntityListItemText>
                        </EllipsisContainer>
                    </Tooltip>
                </MainContainer>
                <SecondaryContainer>
                    <Tooltip title={getMaskedPhoneValue(item.phone)}>
                        <EllipsisContainer>
                            <SecondaryEntityListItemText>
                                <span className="strong-text">
                                    {getMaskedPhoneValue(item.phone)}
                                </span>
                            </SecondaryEntityListItemText>
                        </EllipsisContainer>
                    </Tooltip>
                    <Tooltip title={item.email}>
                        <EllipsisContainer>
                            <SecondaryEntityListItemText>
                                {item.email}
                            </SecondaryEntityListItemText>
                        </EllipsisContainer>
                    </Tooltip>
                </SecondaryContainer>
            </MasterDetailsAsideItem>
        );
    },
);
