import {
    CollectionApiFindAllCollectionsRequest,
    CollectionBrief,
} from 'src/generated-api-client';
import {
    bpmsResourcesManagerApi,
    collectionsApi,
} from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

export type CollectionsFilter = Pick<
    CollectionApiFindAllCollectionsRequest,
    | 'filterSearch'
    | 'filterStartedAtFrom'
    | 'filterStartedAtTo'
    | 'filterStatuses'
>;

export class CollectionsStoreClass extends BasicStore<CollectionBrief> {
    itemDetailsFormKey = 'embedded:deployment:forms/bo/bo-24-collection.json';

    pager = new Pager();
    api: BasicStoreApi<CollectionBrief> = {
        loadList: async (...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            const response = await RequestHelper.unwrapFromAxiosPromise(
                collectionsApi.findAllCollections(
                    {
                        ...this.pager,
                        ...this.filterCriteria.filter,
                    },
                    options,
                ),
            );

            this.pager.setTotal(response.totalElements || 0);

            return response.content || [];
        },
        loadItem: (id, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                collectionsApi.getCollection({ id }, options),
            );
        },
        loadItemForm: (...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                bpmsResourcesManagerApi.getForm(
                    {
                        formKey: this.itemDetailsFormKey,
                    },
                    options,
                ),
            );
        },
    };

    filterCriteria = new FilterCriteria<CollectionsFilter>();
}

export const CollectionsStore = new CollectionsStoreClass();
