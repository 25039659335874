/* eslint-disable max-lines */
import { Button, ButtonProps, Form, Input, Popover, Tooltip } from 'antd';
import { Rule } from 'antd/lib/form';
import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchPanelFilterFormProps } from 'src/components/SearchPanel/SearchPanelFilterForm';
import { ReactComponent as IconFilterClear } from 'src/icons/filter-clear.svg';
import { ReactComponent as FilterIconActive } from 'src/icons/icon-filter-active.svg';
import { ReactComponent as FilterIcon } from 'src/icons/icon-filter.svg';
import { ReactComponent as SearchIcon } from 'src/icons/icon-search.svg';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import styled from 'styled-components';

export type SearchPanelProps = {
    placeholder?: string;
    store: BasicStore<any>;
    searchFieldRules?: Rule[];
    maxSearchFieldLength?: number;
    renderPanel?: (
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        props?: SearchPanelFilterFormProps,
    ) => React.ReactElement<SearchPanelFilterFormProps>;
};

type PopperElement = { close: () => void };

export const SearchPanel = observer(
    ({
        store,
        placeholder = '',
        renderPanel,
        searchFieldRules = [],
        maxSearchFieldLength,
    }: SearchPanelProps) => {
        const popover = useRef<PopperElement>();
        const { t } = useTranslation();
        const [form] = Form.useForm();

        return (
            <Form
                form={form}
                onFinish={(values) => {
                    store.filterCriteria?.applyFilter(values);
                    store.pager?.reset?.();
                    store.loadList();
                }}
                layout="vertical"
            >
                {store.filterCriteria && (
                    <StyledFormItem
                        name="filterSearch"
                        rules={searchFieldRules}
                    >
                        <StyledInputSearch
                            maxLength={maxSearchFieldLength}
                            addonBefore={
                                renderPanel && (
                                    <StyledPopper
                                        ref={(el: PopperElement) => {
                                            popover.current = el;
                                        }}
                                        content={renderPanel({
                                            form,
                                            closePopper: () =>
                                                popover.current?.close(),
                                        })}
                                        trigger="click"
                                        placement="bottomLeft"
                                    >
                                        <StyledFilterButton
                                            hasFilter={store.hasFilterValues}
                                        >
                                            {store.hasFilterValues ? (
                                                <FilterIconActive />
                                            ) : (
                                                <FilterIcon />
                                            )}
                                        </StyledFilterButton>
                                    </StyledPopper>
                                )
                            }
                            addonAfter={
                                store.hasSearchOrFilterValues && (
                                    <Tooltip
                                        title={t(
                                            'Components.Filter.Button.Clear',
                                        )}
                                    >
                                        <StyledClearFilterButton
                                            type="link"
                                            icon={<IconFilterClear />}
                                            onClick={() => {
                                                form.resetFields();
                                                store.filterCriteria?.resetFilter();
                                                store.loadList();
                                            }}
                                        />
                                    </Tooltip>
                                )
                            }
                            placeholder={placeholder}
                            prefix={<StyledSearchIcon />}
                            onPressEnter={(e) => {
                                e.preventDefault();
                                store.pager?.reset?.();
                                form.submit();
                            }}
                        />
                    </StyledFormItem>
                )}
            </Form>
        );
    },
);

const StyledFormItem = styled(Form.Item)`
    margin-bottom: 0;
`;

const StyledFilterButton = styled.button<ButtonProps & { hasFilter?: boolean }>`
    border: none;
    width: 3rem;
    height: 2rem;
    font-size: 0.7rem;
    background: ${(props) =>
        props.hasFilter
            ? 'var(--filter-button-active-bg)'
            : 'var(--filter-button-bg)'};
    color: black;
    border-radius: 0.3rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding: 0 0.7rem;

    svg {
        width: 1.1rem;
        height: 0.9rem;
    }

    &:active,
    &:hover,
    &:focus,
    &.active {
        background: var(--filter-button-active-bg);
        svg {
            path {
                stroke: #ffffff;
            }
        }
    }
`;

const StyledInputSearch = styled(Input.Search)`
    background: transparent;

    .ant-input-group {
        display: flex;
    }

    .ant-input-search-button {
        display: none;
    }

    .ant-btn-icon-only {
        width: auto;
        height: auto;
    }

    .ant-input-group-addon {
        display: flex;
        width: fit-content;
        padding: 0;
        border: none;
        background: transparent;
    }

    .ant-input-affix-wrapper {
        background: #f5f5f7;
        border: none;
        margin-top: 0.05rem;
        height: 2rem;

        border-left: 0.15rem solid white;

        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        border-top-right-radius: 0.37rem !important;
        border-bottom-right-radius: 0.37rem !important;
    }

    .ant-input-affix-wrapper-focused {
        box-shadow: none;
    }

    .ant-input {
        background: transparent;
    }

    .anticon.ant-input-clear-icon-hidden {
        visibility: visible;
    }

    .ant-input::placeholder {
        color: transparent;
    }

    .ant-input:focus::placeholder {
        color: #d9d9d9;
    }
`;

export const StyledPopper = styled(Popover)`
    .ant-popover-inner {
        border-radius: 0.35rem;
    }
    .ant-popover-arrow {
        display: none;
    }
    .ant-popover-inner {
        border-radius: 0.47rem;
    }
`;

const StyledClearFilterButton = styled(Button)`
    display: flex;
    align-items: center;
    padding: 0;
    margin-left: 0.4rem;
    height: auto;
    svg {
        width: 1rem;
    }
    &:hover {
        svg {
            circle {
                fill: #787879 !important;
            }
        }
    }
`;

const StyledSearchIcon = styled(SearchIcon)`
    width: 0.9rem;
    height: 0.9rem;
`;
