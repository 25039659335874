/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import _, { has } from 'lodash';

export type FormInstanceComponent = {
    attached: boolean;
    disabled: boolean;
    isDisabledOnInvalid: boolean;
    path: string;
    visible: boolean;
    rendered: boolean;
    type: string;
    parent?: FormInstanceComponent;
    key: string;
    events?: any;
    _data?: any;
    component?: FormInstanceComponent;
};

export type FormInstance = {
    formio: {
        everyComponent: (fn: EachComponentFn) => void;
    };
};

export type EachComponentFn = (component: FormInstanceComponent) => void;

const DEFAULT_KEYS_TO_PICK = ['context', 'securityContext'];

const normalize = (form: FormInstance, data: any) => {
    const keysToPick: string[] = [...DEFAULT_KEYS_TO_PICK];
    form.formio.everyComponent((component: FormInstanceComponent) => {
        const componentApiKey = calcKey(component);
        if (
            (!component.disabled || component.isDisabledOnInvalid === false) &&
            component.visible &&
            (component.component?.type === 'address' ||
                component.type !== 'container')
        ) {
            keysToPick.push(componentApiKey);
        }
        if (component?.type === 'file') {
            const transformedFileData = transformFileData(
                _.get(data, componentApiKey),
            );
            _.set(data, transformedFileData, componentApiKey);
        }
    });

    return _.pick(data, keysToPick);
};

function getAllKeys(obj: any): any {
    const keys = _.keys(obj);
    const nestedKeys = _.flatMapDeep(_.values(obj), (value) =>
        _.isObject(value) ? getAllKeys(value) : [],
    );

    return _.union(keys, nestedKeys);
}

const normalizeCrud = (form: FormInstance, data: any) => {
    const keysToPick: string[] = [...getAllKeys(data)];
    form?.formio?.everyComponent((component: FormInstanceComponent) => {
        const componentApiKey = calcKey(component);
        keysToPick.push(componentApiKey);
        if (component?.type === 'file') {
            const transformedFileData = transformFileData(
                _.get(data, componentApiKey),
            );
            _.set(data, componentApiKey, transformedFileData);
        }
        if (component?.type === 'phoneNumber') {
            const transformedPhoneData = transformPhoneData(
                _.get(data, componentApiKey),
            );

            _.set(data, componentApiKey, transformedPhoneData);
        }
    });

    return _.pick(data, keysToPick);
};

export const SubmissionNormalizer = {
    normalize,
    normalizeCrud,
};

const calcKey = (component: FormInstanceComponent, key?: string): string => {
    if (component.parent) {
        const result =
            component.type === 'container' && key
                ? `${component.key}.${key}`
                : key || component.key;

        return calcKey(component.parent, result);
    }

    return key || '';
};

const transformFileData = (data: any[]) => {
    if (Array.isArray(data)) {
        return data?.map((item: any) => {
            item.url = item?.url?.replace(
                item.name,
                item?.data?.persistedFileName || item?.name,
            );
            item.name = item?.data?.persistedFileName || item?.name;
            if (item?.data?.baseUrl) {
                item.url = item?.url?.replace(item.data.baseUrl, '');
                item.data.baseUrl = '';
            }

            if (has(item, 'actions')) {
                delete item.actions;
            }

            return item;
        });
    }

    return data;
};

const transformPhoneData = (data: string) => {
    if (!data) return;

    return data?.replace(/[+\-()\s]/g, '');
};
