// eslint-disable-next-line simple-import-sort/sort
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { FormioCustomEvent, FormioEvent } from 'src/core/Formio.types';
import { Router } from 'src/core/router/Router';
import { FormioForm } from 'src/components/FormioForm/FormioForm';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { TaskViewViewModel } from 'src/pages/TasksPage/TaskViewViewModel';
import { BasePage, PageType } from 'src/components/BasePage';
import { Button, Col, Row, Typography } from 'antd';
import { CurrentTaskStore } from 'src/stores/CurrentTaskStore/CurrentTaskStore';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { useTranslation } from 'react-i18next';

type TaskViewRouterParams = {
    taskId: string;
};

export const TaskView = observer(() => {
    const { taskId } = useParams<TaskViewRouterParams>();
    const isReadOnly = !TaskViewViewModel.taskDefinition?.assignee;

    useEffect(() => {
        TaskViewViewModel.loadItem(taskId);

        return () => {
            TaskViewViewModel.reset();
        };
    }, [taskId]);

    const submitHandler = useMemo(() => {
        return getSubmitHandler(taskId);
    }, [taskId]);

    const { isLoading } = TaskViewViewModel.loadingStatus;

    return (
        <StyledPage pageName="task" isLoading={isLoading} type={PageType.FULL}>
            {taskId ? (
                <React.Fragment key={isReadOnly?.toString()}>
                    <TaskViewHeader />
                    <FormioForm
                        form={TaskViewViewModel.taskForm as any}
                        submission={TaskViewViewModel.taskVariables}
                        onSubmit={submitHandler as any}
                        onCustomEvent={getCustomEventHandler(taskId) as any}
                        readOnly={isReadOnly}
                        normalizeSubmission
                    />
                </React.Fragment>
            ) : null}
        </StyledPage>
    );
});

const TaskViewHeader = observer(() => {
    const assigned = Boolean(TaskViewViewModel.taskDefinition?.assignee);
    const { t } = useTranslation();

    if (!TaskViewViewModel.taskDefinition) {
        return null;
    }
    const { taskDefinition } = TaskViewViewModel;

    const hasL10nTitleKey = Boolean(taskDefinition.extensions?.l10nTitleKey);
    const title = hasL10nTitleKey
        ? t(`${taskDefinition.extensions?.l10nTitleKey}`, {
              ...taskDefinition?.taskInfo,
          })
        : taskDefinition.name;

    const CancelButton = () => {
        return (taskDefinition?.extensions as unknown as { type: string })
            ?.type === 'non-cancellable' ? null : (
            <StyledButtonCancel
                onClick={() => {
                    TaskViewViewModel.unclaim(
                        TaskViewViewModel.taskDefinition?.id,
                    );
                }}
            >
                {t('Page.Tasks.TaskView.Cancel', {
                    defaultValue: 'Cancel',
                })}
            </StyledButtonCancel>
        );
    };

    return (
        <StyledHeaderRow gutter={10} wrap={false} align="middle">
            <Col flex={1}>
                <StyledTitlte>{title}</StyledTitlte>
            </Col>
            <Col>
                {assigned ? (
                    <CancelButton />
                ) : (
                    <StyledButton
                        type="primary"
                        onClick={() => {
                            TaskViewViewModel.claim(
                                TaskViewViewModel.taskDefinition?.id,
                            );
                        }}
                    >
                        {t('Page.Tasks.TaskView.Claim', {
                            defaultValue: 'Claim',
                        })}
                    </StyledButton>
                )}
            </Col>
        </StyledHeaderRow>
    );
});

const StyledButton = styled(Button)`
    background: var(--btn-bg);
    border-radius: 0.5rem;
    width: 8.6rem;
    height: 2.7rem;
    padding: inherit;
`;

const StyledButtonCancel = styled(Button)`
    color: white;
    background: var(--btn-bg);
    border-radius: 0.5rem;
    width: 8.6rem;
    height: 2.7rem;
    border-color: var(--btn-bg);
    padding: inherit;

    &:active,
    &:focus,
    &:hover {
        background: var(--btn-bg-hover);
        border-color: var(--btn-bg-hover);
        color: white;
        border-color: transparent;
    }
`;

const StyledHeaderRow = styled(Row)`
    margin-bottom: 1.8rem;
`;

const getSubmitHandler = (taskId: string) => {
    let isSubmitting = false;

    return async (values: FormioEvent) => {
        if (isSubmitting) {
            return;
        }
        isSubmitting = true;

        const { data } = values;
        await submitForm(taskId, data);
    };
};

const submitForm = async (taskId: string, data: any) => {
    const tasks = await TaskViewViewModel.submitForm(taskId, data);
    CurrentTaskStore.reset();
    if (tasks?.length) {
        const firstTask = tasks[0];
        Router.navigate(EntityLinks.tasks.itemDetails(firstTask.id));
    } else {
        Router.navigate(EntityLinks.tasks.list());
    }
};

const getCustomEventHandler =
    (processKey: string) =>
    async (event: FormioCustomEvent<'submitWithoutData'>) => {
        if (event.type === 'submitWithoutData') {
            const data = _.pick(event.data, [event.component.key]);
            await submitForm(processKey, data);
        }
    };

const StyledPage = styled(BasePage)`
    padding: 2rem 1.8rem;
    .ant-list {
        margin-left: 1.2rem;
    }
    .formio-component-submit1 {
        button {
            margin-left: 1.6rem;
        }
    }
`;

const StyledTitlte = styled(Typography.Title)`
    padding-left: 1.6rem;
    margin-bottom: 0 !important;
`;
