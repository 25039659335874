import { Tooltip } from 'antd';
import { truncate } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { EntityStatusInfo } from 'src/components/EntityStatusInfo/EntityStatusInfo';
import {
    MainContainer,
    MainEntityListItemText,
    MasterDetailsAsideItem,
    SecondaryContainer,
    SecondaryEntityListItemText,
} from 'src/components/MasterDetailsPage/MasterDetailsAsideItem';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { LoanProduct } from 'src/generated-api-client';
import { ProductsStatusInfoMap } from 'src/pages/ProductsPage/ProductsStatusInfoMap';
import { SettingsStore } from 'src/stores/SettingsStore/SettingsStore';
import { NumberHelper } from 'src/utils/NumberHelper';

type ProductListItemProps = { item: LoanProduct };

export const ProductListItem = observer(({ item }: ProductListItemProps) => {
    const currencyMnemonic = SettingsStore.currencyMnemonic as string;

    const { i18n } = useTranslation();

    return (
        <MasterDetailsAsideItem to={EntityLinks.products.itemDetails(item.id)}>
            <MainContainer>
                <Tooltip title={item.code}>
                    <MainEntityListItemText className="strong-text">
                        {truncate(item.code, {
                            length: 21,
                            separator: ' ',
                            omission: '...',
                        })}
                    </MainEntityListItemText>
                </Tooltip>
                <EntityStatusInfo
                    statusInfoMap={ProductsStatusInfoMap}
                    status={getStatus(item.active)}
                    entityName="product"
                />
            </MainContainer>
            <SecondaryContainer>
                <SecondaryEntityListItemText>
                    <span className="strong-text">
                        {NumberHelper.currencyFormatter(
                            item.minAmount || 0,
                            2,
                            currencyMnemonic,
                            i18n.language,
                        )}
                        {' - '}
                        {NumberHelper.currencyFormatter(
                            item.maxAmount || 0,
                            2,
                            currencyMnemonic,
                            i18n.language,
                        )}
                    </span>
                </SecondaryEntityListItemText>
            </SecondaryContainer>
        </MasterDetailsAsideItem>
    );
});

const getStatus = (status?: boolean | null) => {
    if (status) {
        return 'ACTIVE';
    }

    return 'INACTIVE';
};
