import Components from 'formiojs/components/Components';

const EmailfieldComponent = (Components as any).components.email;

class EmailField extends (EmailfieldComponent as any) {
    setValue(value: any, flags: any = {}) {
        const res = super.setValue(value, flags);

        this.redraw();

        return res;
    }

    attach(element: any) {
        return super.attach(element);
    }
}

export default EmailField;
