import { COUNTRY_INFO } from 'src/formio/components/PhoneField/utils';

export const getMaskByIsoPhone = (code: string, phone: string): string => {
    const countryData = COUNTRY_INFO.find((item) => item.iso === code);

    if (!countryData || Array?.isArray(countryData?.mask)) {
        console.error(
            'You need to check phone mask for Country:',
            countryData?.name,
        );

        return phone?.replace(/\d/g, '9');
    }

    return (countryData?.mask as string)?.replace(/#/g, '9');
};
