import { Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';
import { EntityStatusInfo } from 'src/components/EntityStatusInfo/EntityStatusInfo';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { LegalEntity, LoanApplication } from 'src/generated-api-client';
import { ApplicationStatusInfoMap } from 'src/pages/LoanApplicationsPage/ApplicationStatusInfoMap';
import { DateHelper } from 'src/utils/DateHelper';
import { FullNameHelper } from 'src/utils/FullNameHelper';
import styled from 'styled-components';

type LoanApplicationListProps = { item: LoanApplication };

export const LoanApplicationListItem = observer(
    ({ item }: LoanApplicationListProps) => {
        const fullName = FullNameHelper(item.borrower as LegalEntity);

        return (
            <StyledNavLink
                to={EntityLinks.applications.itemDetails(item.id)}
                onClick={(e) => {
                    if (
                        (e.currentTarget as HTMLElement).classList.contains(
                            'active',
                        )
                    ) {
                        e.preventDefault();
                    }
                }}
            >
                <MainText>
                    <Tooltip title={item.number}>
                        <EllipsisContainer>
                            <strong>{item.number}</strong>
                        </EllipsisContainer>
                    </Tooltip>
                </MainText>
                <EntityStatusInfo
                    statusInfoMap={ApplicationStatusInfoMap}
                    status={item.status}
                    entityName="application"
                />
                <MainText>
                    <Tooltip title={fullName}>
                        <EllipsisContainer>{fullName}</EllipsisContainer>
                    </Tooltip>
                </MainText>
                <Footer>
                    <SecondaryText>
                        <EllipsisContainer>
                            {item?.campaign?.name}
                        </EllipsisContainer>
                    </SecondaryText>
                    <SecondaryText>
                        {DateHelper.format(item.requestedAt || '')}
                    </SecondaryText>
                </Footer>
            </StyledNavLink>
        );
    },
);

const Footer = styled.div`
    margin-top: 0.5rem;
    display: grid;
    gap: 0.5rem;
    grid-template-columns: 1fr auto;
`;

const StyledNavLink = styled(NavLink)`
    background: var(--aside-list-item-bg);
    border: 0.08rem solid #d2d2d7;
    border-radius: 0.7rem;
    margin: 0.7rem 0;
    padding: 1rem 1.5rem;
    display: block;

    &:hover {
        border-color: var(--aside-list-border-color-hover);
        color: var(--form-text-color);
        text-decoration: none;
    }

    &.active {
        border-color: var(--aside-list-active-border-color);
        background: var(--aside-list-active-bg);
        border-width: 0.14rem;
    }
`;

const MainText = styled.div`
    color: var(--form-text-color);
    line-height: 125%;
    position: relative;
    overflow: hidden;
`;

const SecondaryText = styled.div`
    font-size: 0.8rem;
    color: var(--aside-list-item-color-secondary);
    line-height: 125%;
    position: relative;
    overflow: hidden;
`;

export const EllipsisContainer = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 1em;
`;
