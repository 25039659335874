/* eslint-disable no-underscore-dangle */
import moment from 'moment-with-locales-es6';

export const DEFAULT_FORMAT = '{{systemFormat}}';

export const getDateFormatForLocales = (
    isTimeEnabled = false,
): {
    formioFormat: string;
    appFormat: string;
} => {
    const browserLocale = window.navigator.language;
    const date = moment(new Date()).locale(browserLocale);
    const addFormat = date?._locale?._longDateFormat?.L;
    const format = date?._locale?._longDateFormat?.L?.replace(
        /D{1,2}/g,
        'dd',
    ).replace('YYYY', 'yyyy');
    const timeFormat = 'HH:mm:ss';

    const formioFormat = isTimeEnabled ? `${format} ${timeFormat}` : format;

    const isUSALocale = browserLocale === 'en-US';

    if (isUSALocale) {
        return {
            formioFormat,
            appFormat: 'MM-DD-YYYY',
        };
    }

    return {
        formioFormat: formioFormat || 'dd.MM.yyyy',
        appFormat: addFormat || 'dd.MM.YYYY',
    };
};

export const getDateFormattedValue = (
    value: string,
    isTimeEnabled: boolean,
) => {
    const browserLocale = window.navigator.language;
    const isUSALocale = browserLocale === 'en-US';
    const formattedDate = moment(value).locale(browserLocale).format('L');
    const formattedTime = moment(value)
        .locale(browserLocale)
        .format('HH:mm:ss');
    const formattedDateDivider = isUSALocale
        ? formattedDate?.replace(/\//g, '-')
        : formattedDate;
    const formattedValue = isTimeEnabled
        ? `${formattedDateDivider} ${formattedTime}`
        : formattedDateDivider;

    return (value ? formattedValue : value) || '';
};
