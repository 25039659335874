/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActivityAuditTrail
 */
export interface ActivityAuditTrail {
    /**
     * 
     * @type {string}
     * @memberof ActivityAuditTrail
     */
    'activityName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityAuditTrail
     */
    'assignee'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityAuditTrail
     */
    'completedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActivityAuditTrail
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActivityAuditTrail
     */
    'processBusinessKey'?: string;
}
/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'buildingNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'createdBy'?: string;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'poBox'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'postalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'shortAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'streetAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'updatedBy'?: string;
}
/**
 * 
 * @export
 * @interface AddressBrief
 */
export interface AddressBrief {
    /**
     * 
     * @type {string}
     * @memberof AddressBrief
     */
    'buildingNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBrief
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBrief
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBrief
     */
    'createdBy'?: string;
    /**
     * 
     * @type {number}
     * @memberof AddressBrief
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressBrief
     */
    'poBox'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBrief
     */
    'postalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBrief
     */
    'shortAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBrief
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBrief
     */
    'streetAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBrief
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressBrief
     */
    'updatedBy'?: string;
}
/**
 * 
 * @export
 * @interface AmortisationSchedulePeriod
 */
export interface AmortisationSchedulePeriod {
    /**
     * 
     * @type {string}
     * @memberof AmortisationSchedulePeriod
     */
    'endDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriod
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriod
     */
    'interestAllocation'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriod
     */
    'interestPaid'?: number;
    /**
     * 
     * @type {string}
     * @memberof AmortisationSchedulePeriod
     */
    'legacyKey'?: string;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriod
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriod
     */
    'outstandingBalance'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriod
     */
    'paymentAmount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AmortisationSchedulePeriod
     */
    'paymentPeriod'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriod
     */
    'principalAllocation'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriod
     */
    'principalDebtClosingBalance'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriod
     */
    'principalDebtOpeningBalance'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriod
     */
    'principalPaid'?: number;
    /**
     * 
     * @type {string}
     * @memberof AmortisationSchedulePeriod
     */
    'status'?: string;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriod
     */
    'totalPaid'?: number;
}
/**
 * 
 * @export
 * @interface AmortisationSchedulePeriodBrief
 */
export interface AmortisationSchedulePeriodBrief {
    /**
     * 
     * @type {string}
     * @memberof AmortisationSchedulePeriodBrief
     */
    'endDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodBrief
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodBrief
     */
    'interestAllocation'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodBrief
     */
    'interestPaid'?: number;
    /**
     * 
     * @type {string}
     * @memberof AmortisationSchedulePeriodBrief
     */
    'legacyKey'?: string;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodBrief
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodBrief
     */
    'outstandingBalance'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodBrief
     */
    'paymentAmount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AmortisationSchedulePeriodBrief
     */
    'paymentPeriod'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodBrief
     */
    'principalAllocation'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodBrief
     */
    'principalDebtClosingBalance'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodBrief
     */
    'principalDebtOpeningBalance'?: number;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodBrief
     */
    'principalPaid'?: number;
    /**
     * 
     * @type {string}
     * @memberof AmortisationSchedulePeriodBrief
     */
    'status'?: string;
    /**
     * 
     * @type {number}
     * @memberof AmortisationSchedulePeriodBrief
     */
    'totalPaid'?: number;
}
/**
 * 
 * @export
 * @interface Assignee
 */
export interface Assignee {
    /**
     * 
     * @type {string}
     * @memberof Assignee
     */
    'relationshipManager'?: string;
    /**
     * 
     * @type {string}
     * @memberof Assignee
     */
    'salesManager'?: string;
    /**
     * 
     * @type {string}
     * @memberof Assignee
     */
    'salesTeamLeader'?: string;
}
/**
 * 
 * @export
 * @interface AssigneeBrief
 */
export interface AssigneeBrief {
    /**
     * 
     * @type {string}
     * @memberof AssigneeBrief
     */
    'customerSuccess'?: string;
}
/**
 * 
 * @export
 * @interface AuthorisedPerson
 */
export interface AuthorisedPerson {
    /**
     * 
     * @type {number}
     * @memberof AuthorisedPerson
     */
    'id'?: number;
    /**
     * 
     * @type {Individual}
     * @memberof AuthorisedPerson
     */
    'individual'?: Individual;
    /**
     * 
     * @type {string}
     * @memberof AuthorisedPerson
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface AuthorisedPersonBrief
 */
export interface AuthorisedPersonBrief {
    /**
     * 
     * @type {number}
     * @memberof AuthorisedPersonBrief
     */
    'id'?: number;
    /**
     * 
     * @type {IndividualBrief}
     * @memberof AuthorisedPersonBrief
     */
    'individual'?: IndividualBrief;
    /**
     * 
     * @type {string}
     * @memberof AuthorisedPersonBrief
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface BankAccount
 */
export interface BankAccount {
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    'accountHolderName'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    'accountNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    'bankTitleArabic'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    'bankTitleEnglish'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    'disbursementBankName'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    'iban'?: string;
    /**
     * 
     * @type {number}
     * @memberof BankAccount
     */
    'id'?: number;
    /**
     * 
     * @type {Array<FileBrief>}
     * @memberof BankAccount
     */
    'stampedIban'?: Array<FileBrief>;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccount
     */
    'updatedBy'?: string;
}
/**
 * 
 * @export
 * @interface BankAccountBrief
 */
export interface BankAccountBrief {
    /**
     * 
     * @type {string}
     * @memberof BankAccountBrief
     */
    'accountHolderName'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountBrief
     */
    'accountNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountBrief
     */
    'bankTitleArabic'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountBrief
     */
    'bankTitleEnglish'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountBrief
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountBrief
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountBrief
     */
    'disbursementBankName'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountBrief
     */
    'iban'?: string;
    /**
     * 
     * @type {number}
     * @memberof BankAccountBrief
     */
    'id'?: number;
    /**
     * 
     * @type {Array<FileBrief>}
     * @memberof BankAccountBrief
     */
    'stampedIban'?: Array<FileBrief>;
    /**
     * 
     * @type {string}
     * @memberof BankAccountBrief
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof BankAccountBrief
     */
    'updatedBy'?: string;
}
/**
 * 
 * @export
 * @interface BlacklistEntry
 */
export interface BlacklistEntry {
    /**
     * 
     * @type {LegalEntity}
     * @memberof BlacklistEntry
     */
    'borrower'?: LegalEntity;
    /**
     * 
     * @type {string}
     * @memberof BlacklistEntry
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof BlacklistEntry
     */
    'createdBy': string;
    /**
     * 
     * @type {string}
     * @memberof BlacklistEntry
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof BlacklistEntry
     */
    'fullName'?: string;
    /**
     * 
     * @type {number}
     * @memberof BlacklistEntry
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof BlacklistEntry
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof BlacklistEntry
     */
    'reason': string;
    /**
     * 
     * @type {string}
     * @memberof BlacklistEntry
     */
    'vatNumber'?: string;
}
/**
 * 
 * @export
 * @interface BulkRetryIncidentsResponse
 */
export interface BulkRetryIncidentsResponse {
    /**
     * 
     * @type {number}
     * @memberof BulkRetryIncidentsResponse
     */
    'attemptToRetryQty'?: number;
}
/**
 * 
 * @export
 * @interface CallResult
 */
export interface CallResult {
    /**
     * 
     * @type {number}
     * @memberof CallResult
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof CallResult
     */
    'author': string;
    /**
     * 
     * @type {string}
     * @memberof CallResult
     */
    'calledAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof CallResult
     */
    'collectionsAgreement'?: string;
    /**
     * 
     * @type {string}
     * @memberof CallResult
     */
    'collectionsAgreementDetails'?: string;
    /**
     * 
     * @type {string}
     * @memberof CallResult
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof CallResult
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof CallResult
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CallResult
     */
    'nextReminderDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CallResult
     */
    'promiseToPayDate'?: string;
}
/**
 * 
 * @export
 * @interface CallResultBrief
 */
export interface CallResultBrief {
    /**
     * 
     * @type {number}
     * @memberof CallResultBrief
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof CallResultBrief
     */
    'author': string;
    /**
     * 
     * @type {string}
     * @memberof CallResultBrief
     */
    'calledAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof CallResultBrief
     */
    'collectionsAgreement'?: string;
    /**
     * 
     * @type {string}
     * @memberof CallResultBrief
     */
    'collectionsAgreementDetails'?: string;
    /**
     * 
     * @type {string}
     * @memberof CallResultBrief
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof CallResultBrief
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof CallResultBrief
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CallResultBrief
     */
    'nextReminderDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CallResultBrief
     */
    'promiseToPayDate'?: string;
}
/**
 * 
 * @export
 * @interface Campaign
 */
export interface Campaign {
    /**
     * 
     * @type {boolean}
     * @memberof Campaign
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    'calculationType'?: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    'disbursementOption': string;
    /**
     * 
     * @type {number}
     * @memberof Campaign
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    'offerTemplateName': string;
    /**
     * 
     * @type {boolean}
     * @memberof Campaign
     */
    'secured'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    'signingOption'?: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Campaign
     */
    'updatedBy'?: string;
}
/**
 * 
 * @export
 * @interface CampaignBrief
 */
export interface CampaignBrief {
    /**
     * 
     * @type {boolean}
     * @memberof CampaignBrief
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CampaignBrief
     */
    'calculationType'?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBrief
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBrief
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBrief
     */
    'disbursementOption': string;
    /**
     * 
     * @type {number}
     * @memberof CampaignBrief
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignBrief
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBrief
     */
    'offerTemplateName': string;
    /**
     * 
     * @type {boolean}
     * @memberof CampaignBrief
     */
    'secured'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CampaignBrief
     */
    'signingOption'?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBrief
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignBrief
     */
    'updatedBy'?: string;
}
/**
 * 
 * @export
 * @interface Collateral
 */
export interface Collateral {
    /**
     * 
     * @type {string}
     * @memberof Collateral
     */
    'city'?: string;
    /**
     * 
     * @type {Array<FileBrief>}
     * @memberof Collateral
     */
    'collateralDocument'?: Array<FileBrief>;
    /**
     * 
     * @type {string}
     * @memberof Collateral
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof Collateral
     */
    'finalAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof Collateral
     */
    'firstValuationAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof Collateral
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Collateral
     */
    'notaryPublicName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Collateral
     */
    'ownerName'?: string;
    /**
     * 
     * @type {number}
     * @memberof Collateral
     */
    'secondValuationAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof Collateral
     */
    'type'?: string;
    /**
     * 
     * @type {Array<FileBrief>}
     * @memberof Collateral
     */
    'valuationDocument'?: Array<FileBrief>;
}
/**
 * 
 * @export
 * @interface CollateralBrief
 */
export interface CollateralBrief {
    /**
     * 
     * @type {string}
     * @memberof CollateralBrief
     */
    'city'?: string;
    /**
     * 
     * @type {Array<FileBrief>}
     * @memberof CollateralBrief
     */
    'collateralDocument'?: Array<FileBrief>;
    /**
     * 
     * @type {string}
     * @memberof CollateralBrief
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof CollateralBrief
     */
    'finalAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollateralBrief
     */
    'firstValuationAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof CollateralBrief
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CollateralBrief
     */
    'notaryPublicName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollateralBrief
     */
    'ownerName'?: string;
    /**
     * 
     * @type {number}
     * @memberof CollateralBrief
     */
    'secondValuationAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof CollateralBrief
     */
    'type'?: string;
    /**
     * 
     * @type {Array<FileBrief>}
     * @memberof CollateralBrief
     */
    'valuationDocument'?: Array<FileBrief>;
}
/**
 * 
 * @export
 * @interface Collection
 */
export interface Collection {
    /**
     * 
     * @type {Assignee}
     * @memberof Collection
     */
    'assignee'?: Assignee;
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    'businessStatus'?: string;
    /**
     * 
     * @type {Array<CallResult>}
     * @memberof Collection
     */
    'callResults'?: Array<CallResult>;
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    'crStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    'enforcementNote'?: string;
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    'entityEnforcement'?: string;
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    'entityEnforcementUpdateDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    'facilityEnforcement'?: string;
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    'firstWarningDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    'guarantorsEnforcement'?: string;
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    'guarantorsEnforcementUpdateDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof Collection
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    'lastVisitDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    'lastWarningDate'?: string;
    /**
     * 
     * @type {Loan}
     * @memberof Collection
     */
    'loan': Loan;
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    'number': string;
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    'promiseToPayDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    'startedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof Collection
     */
    'teamLeaderComment'?: string;
}
/**
 * 
 * @export
 * @interface CollectionBrief
 */
export interface CollectionBrief {
    /**
     * 
     * @type {AssigneeBrief}
     * @memberof CollectionBrief
     */
    'assignee'?: AssigneeBrief;
    /**
     * 
     * @type {string}
     * @memberof CollectionBrief
     */
    'businessStatus'?: string;
    /**
     * 
     * @type {Array<CallResultBrief>}
     * @memberof CollectionBrief
     */
    'callResults'?: Array<CallResultBrief>;
    /**
     * 
     * @type {string}
     * @memberof CollectionBrief
     */
    'crStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionBrief
     */
    'enforcementNote'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionBrief
     */
    'entityEnforcement'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionBrief
     */
    'entityEnforcementUpdateDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionBrief
     */
    'facilityEnforcement'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionBrief
     */
    'firstWarningDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionBrief
     */
    'guarantorsEnforcement'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionBrief
     */
    'guarantorsEnforcementUpdateDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof CollectionBrief
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CollectionBrief
     */
    'lastVisitDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionBrief
     */
    'lastWarningDate'?: string;
    /**
     * 
     * @type {LoanBrief}
     * @memberof CollectionBrief
     */
    'loan': LoanBrief;
    /**
     * 
     * @type {string}
     * @memberof CollectionBrief
     */
    'number': string;
    /**
     * 
     * @type {string}
     * @memberof CollectionBrief
     */
    'promiseToPayDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectionBrief
     */
    'startedAt': string;
    /**
     * 
     * @type {string}
     * @memberof CollectionBrief
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof CollectionBrief
     */
    'teamLeaderComment'?: string;
}
/**
 * 
 * @export
 * @interface CommercialRegistry
 */
export interface CommercialRegistry {
    /**
     * 
     * @type {string}
     * @memberof CommercialRegistry
     */
    'entityNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommercialRegistry
     */
    'gregorianExpiryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommercialRegistry
     */
    'gregorianIssueDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommercialRegistry
     */
    'hijriExpiryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommercialRegistry
     */
    'hijriIssueDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommercialRegistry
     */
    'number'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommercialRegistry
     */
    'placeOfIssue'?: string;
}
/**
 * 
 * @export
 * @interface CommercialRegistryBrief
 */
export interface CommercialRegistryBrief {
    /**
     * 
     * @type {string}
     * @memberof CommercialRegistryBrief
     */
    'entityNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommercialRegistryBrief
     */
    'gregorianExpiryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommercialRegistryBrief
     */
    'gregorianIssueDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommercialRegistryBrief
     */
    'hijriExpiryDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommercialRegistryBrief
     */
    'hijriIssueDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommercialRegistryBrief
     */
    'number'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommercialRegistryBrief
     */
    'placeOfIssue'?: string;
}
/**
 * 
 * @export
 * @interface Commodity
 */
export interface Commodity {
    /**
     * 
     * @type {string}
     * @memberof Commodity
     */
    'arabicDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof Commodity
     */
    'englishDescription'?: string;
    /**
     * 
     * @type {number}
     * @memberof Commodity
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Commodity
     */
    'purchasedFromVendorAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof Commodity
     */
    'quantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof Commodity
     */
    'soldToClientAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Commodity
     */
    'soldToThirdPartyAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Commodity
     */
    'transferredToClientAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof Commodity
     */
    'unitPrice'?: number;
    /**
     * 
     * @type {string}
     * @memberof Commodity
     */
    'vendorName'?: string;
}
/**
 * 
 * @export
 * @interface CreditCommittee
 */
export interface CreditCommittee {
    /**
     * 
     * @type {string}
     * @memberof CreditCommittee
     */
    'approvalDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditCommittee
     */
    'commentForNewConditions'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditCommittee
     */
    'finalVote'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditCommittee
     */
    'firstMemberVote'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditCommittee
     */
    'secondMemberVote'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreditCommittee
     */
    'thirdMemberVote'?: string;
}
/**
 * 
 * @export
 * @interface DeploymentRepresentation
 */
export interface DeploymentRepresentation {
    /**
     * 
     * @type {string}
     * @memberof DeploymentRepresentation
     */
    'deploymentTime': string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentRepresentation
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentRepresentation
     */
    'source': string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentRepresentation
     */
    'tenantId'?: string;
}
/**
 * 
 * @export
 * @interface Fee
 */
export interface Fee {
    /**
     * 
     * @type {number}
     * @memberof Fee
     */
    'balance'?: number;
    /**
     * 
     * @type {number}
     * @memberof Fee
     */
    'deductibleBalance'?: number;
    /**
     * 
     * @type {number}
     * @memberof Fee
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Fee
     */
    'legacyKey'?: string;
    /**
     * 
     * @type {number}
     * @memberof Fee
     */
    'paidAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof Fee
     */
    'plannedAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof Fee
     */
    'status'?: string;
    /**
     * 
     * @type {Set<TransactionBrief>}
     * @memberof Fee
     */
    'transactions'?: Set<TransactionBrief>;
    /**
     * 
     * @type {string}
     * @memberof Fee
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface FeeBrief
 */
export interface FeeBrief {
    /**
     * 
     * @type {number}
     * @memberof FeeBrief
     */
    'deductibleBalance'?: number;
    /**
     * 
     * @type {number}
     * @memberof FeeBrief
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FeeBrief
     */
    'legacyKey'?: string;
    /**
     * 
     * @type {number}
     * @memberof FeeBrief
     */
    'paidAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof FeeBrief
     */
    'plannedAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof FeeBrief
     */
    'status'?: string;
    /**
     * 
     * @type {Set<TransactionBrief>}
     * @memberof FeeBrief
     */
    'transactions'?: Set<TransactionBrief>;
    /**
     * 
     * @type {string}
     * @memberof FeeBrief
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface FileBrief
 */
export interface FileBrief {
    /**
     * 
     * @type {number}
     * @memberof FileBrief
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileBrief
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileBrief
     */
    'originalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileBrief
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface FileUploadResponse
 */
export interface FileUploadResponse {
    /**
     * 
     * @type {string}
     * @memberof FileUploadResponse
     */
    'persistedFileName'?: string;
}
/**
 * 
 * @export
 * @interface FinancialFigures
 */
export interface FinancialFigures {
    /**
     * 
     * @type {number}
     * @memberof FinancialFigures
     */
    'ebitda': number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFigures
     */
    'grossProfit': number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFigures
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFigures
     */
    'netDebt': number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFigures
     */
    'netIncome': number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFigures
     */
    'operatingProfit': number;
    /**
     * 
     * @type {ProfitabilityRatios}
     * @memberof FinancialFigures
     */
    'profitabilityRatios'?: ProfitabilityRatios;
    /**
     * 
     * @type {number}
     * @memberof FinancialFigures
     */
    'revenue': number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFigures
     */
    'totalAssets': number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFigures
     */
    'totalLiabilities': number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFigures
     */
    'totalSales': number;
}
/**
 * 
 * @export
 * @interface FinancialFiguresBrief
 */
export interface FinancialFiguresBrief {
    /**
     * 
     * @type {number}
     * @memberof FinancialFiguresBrief
     */
    'ebitda': number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFiguresBrief
     */
    'grossProfit': number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFiguresBrief
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFiguresBrief
     */
    'netDebt': number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFiguresBrief
     */
    'netIncome': number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFiguresBrief
     */
    'operatingProfit': number;
    /**
     * 
     * @type {ProfitabilityRatiosBrief}
     * @memberof FinancialFiguresBrief
     */
    'profitabilityRatios'?: ProfitabilityRatiosBrief;
    /**
     * 
     * @type {number}
     * @memberof FinancialFiguresBrief
     */
    'revenue': number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFiguresBrief
     */
    'totalAssets': number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFiguresBrief
     */
    'totalLiabilities': number;
    /**
     * 
     * @type {number}
     * @memberof FinancialFiguresBrief
     */
    'totalSales': number;
}
/**
 * 
 * @export
 * @interface FinancialInfo
 */
export interface FinancialInfo {
    /**
     * 
     * @type {number}
     * @memberof FinancialInfo
     */
    'annualRevenue'?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialInfo
     */
    'monthlyAverageRevenue'?: number;
}
/**
 * 
 * @export
 * @interface FinancialInfoBrief
 */
export interface FinancialInfoBrief {
    /**
     * 
     * @type {number}
     * @memberof FinancialInfoBrief
     */
    'annualRevenue'?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancialInfoBrief
     */
    'monthlyAverageRevenue'?: number;
}
/**
 * @type GetTransaction200Response
 * @export
 */
export type GetTransaction200Response = LoanApplicationTransaction | LoanTransaction;

/**
 * 
 * @export
 * @interface Guarantor
 */
export interface Guarantor {
    /**
     * 
     * @type {string}
     * @memberof Guarantor
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Guarantor
     */
    'createdBy'?: string;
    /**
     * 
     * @type {number}
     * @memberof Guarantor
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Guarantor
     */
    'legacyKey'?: string;
    /**
     * 
     * @type {number}
     * @memberof Guarantor
     */
    'loanApplicationId': number;
    /**
     * 
     * @type {GuarantorParty}
     * @memberof Guarantor
     */
    'party'?: GuarantorParty;
    /**
     * 
     * @type {string}
     * @memberof Guarantor
     */
    'relationshipType'?: string;
    /**
     * 
     * @type {string}
     * @memberof Guarantor
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof Guarantor
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Guarantor
     */
    'updatedBy'?: string;
}
/**
 * @type GuarantorParty
 * @export
 */
export type GuarantorParty = Individual | LegalEntity;

/**
 * 
 * @export
 * @interface IdDocument
 */
export interface IdDocument {
    /**
     * 
     * @type {Array<FileBrief>}
     * @memberof IdDocument
     */
    'attachments'?: Array<FileBrief>;
    /**
     * 
     * @type {string}
     * @memberof IdDocument
     */
    'expirationDate': string;
    /**
     * 
     * @type {number}
     * @memberof IdDocument
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IdDocument
     */
    'issueDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdDocument
     */
    'number'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdDocument
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface IdDocumentBrief
 */
export interface IdDocumentBrief {
    /**
     * 
     * @type {Array<FileBrief>}
     * @memberof IdDocumentBrief
     */
    'attachments'?: Array<FileBrief>;
    /**
     * 
     * @type {string}
     * @memberof IdDocumentBrief
     */
    'expirationDate': string;
    /**
     * 
     * @type {number}
     * @memberof IdDocumentBrief
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof IdDocumentBrief
     */
    'issueDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdDocumentBrief
     */
    'number'?: string;
    /**
     * 
     * @type {string}
     * @memberof IdDocumentBrief
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface Individual
 */
export interface Individual extends Party {
    /**
     * 
     * @type {string}
     * @memberof Individual
     */
    'birthDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof Individual
     */
    'currentAge'?: number;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Individual
     */
    'details'?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof Individual
     */
    'gender'?: string;
    /**
     * 
     * @type {IdDocument}
     * @memberof Individual
     */
    'idDocument'?: IdDocument;
    /**
     * 
     * @type {KycResults}
     * @memberof Individual
     */
    'kycResults'?: KycResults;
    /**
     * 
     * @type {boolean}
     * @memberof Individual
     */
    'mobileVerified'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Individual
     */
    'onboarded'?: boolean;
    /**
     * 
     * @type {SupportingDocuments}
     * @memberof Individual
     */
    'supportingDocuments'?: SupportingDocuments;
}
/**
 * 
 * @export
 * @interface IndividualBrief
 */
export interface IndividualBrief {
    /**
     * 
     * @type {AddressBrief}
     * @memberof IndividualBrief
     */
    'address'?: AddressBrief;
    /**
     * 
     * @type {string}
     * @memberof IndividualBrief
     */
    'birthDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualBrief
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualBrief
     */
    'createdBy'?: string;
    /**
     * 
     * @type {number}
     * @memberof IndividualBrief
     */
    'currentAge'?: number;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof IndividualBrief
     */
    'details'?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof IndividualBrief
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualBrief
     */
    'englishName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualBrief
     */
    'fullName'?: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualBrief
     */
    'gender'?: string;
    /**
     * 
     * @type {number}
     * @memberof IndividualBrief
     */
    'id'?: number;
    /**
     * 
     * @type {IdDocumentBrief}
     * @memberof IndividualBrief
     */
    'idDocument'?: IdDocumentBrief;
    /**
     * 
     * @type {KycResultsBrief}
     * @memberof IndividualBrief
     */
    'kycResults'?: KycResultsBrief;
    /**
     * 
     * @type {string}
     * @memberof IndividualBrief
     */
    'legacyKey'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IndividualBrief
     */
    'mobileVerified'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IndividualBrief
     */
    'number'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IndividualBrief
     */
    'onboarded'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IndividualBrief
     */
    'phone'?: string;
    /**
     * 
     * @type {object}
     * @memberof IndividualBrief
     */
    'supportingDocuments'?: object;
    /**
     * 
     * @type {string}
     * @memberof IndividualBrief
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualBrief
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof IndividualBrief
     */
    'updatedBy'?: string;
}
/**
 * 
 * @export
 * @interface Kafalah
 */
export interface Kafalah {
    /**
     * 
     * @type {number}
     * @memberof Kafalah
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof Kafalah
     */
    'number'?: string;
    /**
     * 
     * @type {number}
     * @memberof Kafalah
     */
    'percentage'?: number;
    /**
     * 
     * @type {string}
     * @memberof Kafalah
     */
    'requestDate'?: string;
}
/**
 * 
 * @export
 * @interface KycResults
 */
export interface KycResults {
    /**
     * 
     * @type {string}
     * @memberof KycResults
     */
    'createdAt'?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof KycResults
     */
    'details'?: { [key: string]: object; };
    /**
     * 
     * @type {number}
     * @memberof KycResults
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof KycResults
     */
    'providerName'?: string;
}
/**
 * 
 * @export
 * @interface KycResultsBrief
 */
export interface KycResultsBrief {
    /**
     * 
     * @type {string}
     * @memberof KycResultsBrief
     */
    'createdAt'?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof KycResultsBrief
     */
    'details'?: { [key: string]: object; };
    /**
     * 
     * @type {number}
     * @memberof KycResultsBrief
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof KycResultsBrief
     */
    'providerName'?: string;
}
/**
 * 
 * @export
 * @interface LegalEntity
 */
export interface LegalEntity extends Party {
    /**
     * 
     * @type {Assignee}
     * @memberof LegalEntity
     */
    'assignee'?: Assignee;
    /**
     * 
     * @type {boolean}
     * @memberof LegalEntity
     */
    'blacklisted': boolean;
    /**
     * 
     * @type {string}
     * @memberof LegalEntity
     */
    'businessClassification'?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntity
     */
    'businessSamaSector'?: string;
    /**
     * 
     * @type {CommercialRegistry}
     * @memberof LegalEntity
     */
    'commercialRegistry'?: CommercialRegistry;
    /**
     * 
     * @type {string}
     * @memberof LegalEntity
     */
    'corporateType'?: string;
    /**
     * 
     * @type {FinancialInfo}
     * @memberof LegalEntity
     */
    'financialInfo'?: FinancialInfo;
    /**
     * 
     * @type {boolean}
     * @memberof LegalEntity
     */
    'hasNullRequiredAttributes'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LegalEntity
     */
    'industry'?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntity
     */
    'landlinePhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntity
     */
    'location'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LegalEntity
     */
    'marketingConsent'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LegalEntity
     */
    'sharedFolderLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntity
     */
    'source'?: string;
    /**
     * 
     * @type {SupportingDocuments}
     * @memberof LegalEntity
     */
    'supportingDocuments'?: SupportingDocuments;
    /**
     * 
     * @type {string}
     * @memberof LegalEntity
     */
    'vatNumber'?: string;
}
/**
 * 
 * @export
 * @interface LegalEntityBrief
 */
export interface LegalEntityBrief {
    /**
     * 
     * @type {AddressBrief}
     * @memberof LegalEntityBrief
     */
    'address'?: AddressBrief;
    /**
     * 
     * @type {AssigneeBrief}
     * @memberof LegalEntityBrief
     */
    'assignee'?: AssigneeBrief;
    /**
     * 
     * @type {boolean}
     * @memberof LegalEntityBrief
     */
    'blacklisted': boolean;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityBrief
     */
    'businessClassification'?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityBrief
     */
    'businessSamaSector'?: string;
    /**
     * 
     * @type {CommercialRegistryBrief}
     * @memberof LegalEntityBrief
     */
    'commercialRegistry'?: CommercialRegistryBrief;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityBrief
     */
    'corporateType'?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityBrief
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityBrief
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityBrief
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityBrief
     */
    'englishName'?: string;
    /**
     * 
     * @type {FinancialInfoBrief}
     * @memberof LegalEntityBrief
     */
    'financialInfo'?: FinancialInfoBrief;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityBrief
     */
    'fullName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LegalEntityBrief
     */
    'hasNullRequiredAttributes'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LegalEntityBrief
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityBrief
     */
    'industry'?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityBrief
     */
    'landlinePhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityBrief
     */
    'legacyKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityBrief
     */
    'location'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LegalEntityBrief
     */
    'marketingConsent'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityBrief
     */
    'number'?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityBrief
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityBrief
     */
    'sharedFolderLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityBrief
     */
    'source'?: string;
    /**
     * 
     * @type {object}
     * @memberof LegalEntityBrief
     */
    'supportingDocuments'?: object;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityBrief
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityBrief
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityBrief
     */
    'updatedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof LegalEntityBrief
     */
    'vatNumber'?: string;
}
/**
 * 
 * @export
 * @interface Loan
 */
export interface Loan {
    /**
     * 
     * @type {number}
     * @memberof Loan
     */
    '@id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    'activatedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Loan
     */
    'active': boolean;
    /**
     * 
     * @type {LoanSnapshot}
     * @memberof Loan
     */
    'actualSnapshot'?: LoanSnapshot;
    /**
     * 
     * @type {Array<AmortisationSchedulePeriod>}
     * @memberof Loan
     */
    'amortisationSchedule'?: Array<AmortisationSchedulePeriod>;
    /**
     * 
     * @type {Assignee}
     * @memberof Loan
     */
    'assignee'?: Assignee;
    /**
     * 
     * @type {LegalEntity}
     * @memberof Loan
     */
    'borrower'?: LegalEntity;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    'endDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof Loan
     */
    'feeBalanceSum'?: number;
    /**
     * 
     * @type {number}
     * @memberof Loan
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Loan
     */
    'interestedInRefinancing'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    'legacyKey'?: string;
    /**
     * 
     * @type {LoanApplication}
     * @memberof Loan
     */
    'loanApplication': LoanApplication;
    /**
     * 
     * @type {LoanProduct}
     * @memberof Loan
     */
    'loanProduct'?: LoanProduct;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    'maturityDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    'nextPaymentDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    'number': string;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    'plannedEarlySettlementDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    'poaFromClientToArfAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    'securityDepositStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    'status': string;
    /**
     * 
     * @type {SupportingDocuments}
     * @memberof Loan
     */
    'supportingDocuments'?: SupportingDocuments;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    'topUpResponse'?: string;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    'topUpResponseOther'?: string;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    'updatedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    'vaultCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof Loan
     */
    'writeOffReason'?: string;
}
/**
 * 
 * @export
 * @interface LoanApplication
 */
export interface LoanApplication {
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    '@id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LoanApplication
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    'aggregator'?: string;
    /**
     * 
     * @type {Array<AmortisationSchedulePeriod>}
     * @memberof LoanApplication
     */
    'amortisationSchedule'?: Array<AmortisationSchedulePeriod>;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    'amountApproved'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    'amountRequested': number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    'annualFlatRatePct'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    'annualProfitRatePct'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    'annualRevenue'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    'approvedBy'?: string;
    /**
     * 
     * @type {Assignee}
     * @memberof LoanApplication
     */
    'assignee'?: Assignee;
    /**
     * 
     * @type {AuthorisedPerson}
     * @memberof LoanApplication
     */
    'authorisedPerson'?: AuthorisedPerson;
    /**
     * 
     * @type {BankAccount}
     * @memberof LoanApplication
     */
    'bankAccount'?: BankAccount;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplication
     */
    'bankAccountVerification'?: { [key: string]: object; };
    /**
     * 
     * @type {LegalEntity}
     * @memberof LoanApplication
     */
    'borrower'?: LegalEntity;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    'calculationType'?: string;
    /**
     * 
     * @type {Campaign}
     * @memberof LoanApplication
     */
    'campaign'?: Campaign;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    'cancelCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    'canceledBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    'closedAt'?: string;
    /**
     * 
     * @type {Collateral}
     * @memberof LoanApplication
     */
    'collateral'?: Collateral;
    /**
     * 
     * @type {Commodity}
     * @memberof LoanApplication
     */
    'commodity'?: Commodity;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplication
     */
    'complianceInfo'?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    'contractGeneratedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    'contractNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    'contractSignedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    'createdBy'?: string;
    /**
     * 
     * @type {CreditCommittee}
     * @memberof LoanApplication
     */
    'creditCommittee'?: CreditCommittee;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    'creditLimit'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    'creditRiskGrade'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    'declineCode'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LoanApplication
     */
    'declined'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    'declinedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    'disbursedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    'disbursementAmount'?: number;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplication
     */
    'extensions'?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    'extraFeeType'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    'factorRatePct'?: number;
    /**
     * 
     * @type {Array<Fee>}
     * @memberof LoanApplication
     */
    'fees'?: Array<Fee>;
    /**
     * 
     * @type {FinancialFigures}
     * @memberof LoanApplication
     */
    'financialFigures'?: FinancialFigures;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    'firstPaymentDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    'flatRatePct'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    'funder'?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplication
     */
    'furtherInformation'?: { [key: string]: object; };
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    'gracePeriodEffectiveRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    'gracePeriodLength'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LoanApplication
     */
    'hasKafalah'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    'holdbackRatePct'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    'interestRateApprovedPct'?: number;
    /**
     * 
     * @type {Kafalah}
     * @memberof LoanApplication
     */
    'kafalah'?: Kafalah;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    'legacyKey'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    'legacyManagementFeesWithVat'?: number;
    /**
     * 
     * @type {LoanProduct}
     * @memberof LoanApplication
     */
    'loanProduct'?: LoanProduct;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    'ltvPct'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    'managementFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    'managementFeeRatePct'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    'monthlyMinChargeAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    'monthlyMinChargePct'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    'normalEffectiveRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    'normalPeriodLength'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    'number'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    'numberOfPosTerminals'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    'offerSignedAt'?: string;
    /**
     * A date-based amount of time in the ISO 8601 calendar system.
     * @type {string}
     * @memberof LoanApplication
     */
    'paymentFrequency'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    'periodFlatRatePct'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    'poaNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    'profitAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    'profitOnlyEffectiveRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    'profitOnlyPeriodLength'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LoanApplication
     */
    'refinancing'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    'refinancingLoanId'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    'refinancingLoanLegacyKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    'requestedAt': string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    'riskClassification'?: string;
    /**
     * 
     * @type {Scoring}
     * @memberof LoanApplication
     */
    'scoring'?: Scoring;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    'securityDepositPct'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    'securityDepositStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    'sharedFolderLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    'stepUpFlatRate'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LoanApplication
     */
    'stopFactors'?: Array<string>;
    /**
     * 
     * @type {SupportingDocuments}
     * @memberof LoanApplication
     */
    'supportingDocuments'?: SupportingDocuments;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    'termApproved'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LoanApplication
     */
    'topUp'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    'totalExtraFees'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    'totalRefinancingSettlement'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    'totalToPay'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    'updatedBy'?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplication
     */
    'validationResult'?: { [key: string]: object; };
    /**
     * 
     * @type {number}
     * @memberof LoanApplication
     */
    'vat'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplication
     */
    'virtualIban'?: string;
}
/**
 * 
 * @export
 * @interface LoanApplicationBrief
 */
export interface LoanApplicationBrief {
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    '@id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LoanApplicationBrief
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    'aggregator'?: string;
    /**
     * 
     * @type {Array<AmortisationSchedulePeriodBrief>}
     * @memberof LoanApplicationBrief
     */
    'amortisationSchedule'?: Array<AmortisationSchedulePeriodBrief>;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    'amountApproved'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    'amountRequested': number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    'annualFlatRatePct'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    'annualProfitRatePct'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    'annualRevenue'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    'approvedBy'?: string;
    /**
     * 
     * @type {Assignee}
     * @memberof LoanApplicationBrief
     */
    'assignee'?: Assignee;
    /**
     * 
     * @type {AuthorisedPersonBrief}
     * @memberof LoanApplicationBrief
     */
    'authorisedPerson'?: AuthorisedPersonBrief;
    /**
     * 
     * @type {BankAccountBrief}
     * @memberof LoanApplicationBrief
     */
    'bankAccount'?: BankAccountBrief;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplicationBrief
     */
    'bankAccountVerification'?: { [key: string]: object; };
    /**
     * 
     * @type {LegalEntityBrief}
     * @memberof LoanApplicationBrief
     */
    'borrower'?: LegalEntityBrief;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    'calculationType'?: string;
    /**
     * 
     * @type {CampaignBrief}
     * @memberof LoanApplicationBrief
     */
    'campaign'?: CampaignBrief;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    'cancelCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    'canceledBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    'closedAt'?: string;
    /**
     * 
     * @type {CollateralBrief}
     * @memberof LoanApplicationBrief
     */
    'collateral'?: CollateralBrief;
    /**
     * 
     * @type {Commodity}
     * @memberof LoanApplicationBrief
     */
    'commodity'?: Commodity;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplicationBrief
     */
    'complianceInfo'?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    'contractGeneratedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    'contractNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    'contractSignedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    'createdBy'?: string;
    /**
     * 
     * @type {CreditCommittee}
     * @memberof LoanApplicationBrief
     */
    'creditCommittee'?: CreditCommittee;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    'creditLimit'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    'creditRiskGrade'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    'declineCode'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LoanApplicationBrief
     */
    'declined'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    'declinedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    'disbursedAt'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    'disbursementAmount'?: number;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplicationBrief
     */
    'extensions'?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    'extraFeeType'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    'factorRatePct'?: number;
    /**
     * 
     * @type {Array<FeeBrief>}
     * @memberof LoanApplicationBrief
     */
    'fees'?: Array<FeeBrief>;
    /**
     * 
     * @type {FinancialFiguresBrief}
     * @memberof LoanApplicationBrief
     */
    'financialFigures'?: FinancialFiguresBrief;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    'firstPaymentDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    'flatRatePct'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    'funder'?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplicationBrief
     */
    'furtherInformation'?: { [key: string]: object; };
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    'gracePeriodEffectiveRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    'gracePeriodLength'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LoanApplicationBrief
     */
    'hasKafalah'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    'holdbackRatePct'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    'interestRateApprovedPct'?: number;
    /**
     * 
     * @type {Kafalah}
     * @memberof LoanApplicationBrief
     */
    'kafalah'?: Kafalah;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    'legacyKey'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    'legacyManagementFeesWithVat'?: number;
    /**
     * 
     * @type {LoanProductBrief}
     * @memberof LoanApplicationBrief
     */
    'loanProduct'?: LoanProductBrief;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    'ltvPct'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    'managementFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    'managementFeeRatePct'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    'monthlyMinChargeAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    'monthlyMinChargePct'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    'normalEffectiveRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    'normalPeriodLength'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    'number'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    'numberOfPosTerminals'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    'offerSignedAt'?: string;
    /**
     * A date-based amount of time in the ISO 8601 calendar system.
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    'paymentFrequency'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    'periodFlatRatePct'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    'poaNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    'profitAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    'profitOnlyEffectiveRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    'profitOnlyPeriodLength'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LoanApplicationBrief
     */
    'refinancing'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    'refinancingLoanId'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    'refinancingLoanLegacyKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    'requestedAt': string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    'riskClassification'?: string;
    /**
     * 
     * @type {ScoringBrief}
     * @memberof LoanApplicationBrief
     */
    'scoring'?: ScoringBrief;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    'securityDepositPct'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    'securityDepositStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    'sharedFolderLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    'stepUpFlatRate'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LoanApplicationBrief
     */
    'stopFactors'?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof LoanApplicationBrief
     */
    'supportingDocuments'?: object;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    'termApproved'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LoanApplicationBrief
     */
    'topUp'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    'totalExtraFees'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    'totalRefinancingSettlement'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    'totalToPay'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    'updatedBy'?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof LoanApplicationBrief
     */
    'validationResult'?: { [key: string]: object; };
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationBrief
     */
    'vat'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationBrief
     */
    'virtualIban'?: string;
}
/**
 * 
 * @export
 * @interface LoanApplicationTransaction
 */
export interface LoanApplicationTransaction extends TransactionBrief {
    /**
     * 
     * @type {LoanApplication}
     * @memberof LoanApplicationTransaction
     */
    'loanApplication': LoanApplication;
}


/**
 * 
 * @export
 * @interface LoanApplicationTransactionBrief
 */
export interface LoanApplicationTransactionBrief {
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationTransactionBrief
     */
    'accountNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationTransactionBrief
     */
    'alraedahBank'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationTransactionBrief
     */
    'amount': number;
    /**
     * 
     * @type {Array<FileBrief>}
     * @memberof LoanApplicationTransactionBrief
     */
    'attachments'?: Array<FileBrief>;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationTransactionBrief
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationTransactionBrief
     */
    'customerBank'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationTransactionBrief
     */
    'depositDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationTransactionBrief
     */
    'direction': LoanApplicationTransactionBriefDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationTransactionBrief
     */
    'documentNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationTransactionBrief
     */
    'errorMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationTransactionBrief
     */
    'externalPaymentService'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationTransactionBrief
     */
    'externalTransactionId'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationTransactionBrief
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationTransactionBrief
     */
    'initiator'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationTransactionBrief
     */
    'legacyKey'?: string;
    /**
     * 
     * @type {LoanApplicationBrief}
     * @memberof LoanApplicationTransactionBrief
     */
    'loanApplication': LoanApplicationBrief;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationTransactionBrief
     */
    'method'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationTransactionBrief
     */
    'paymentSource'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationTransactionBrief
     */
    'paymentType': string;
    /**
     * 
     * @type {boolean}
     * @memberof LoanApplicationTransactionBrief
     */
    'processed': boolean;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationTransactionBrief
     */
    'processedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationTransactionBrief
     */
    'recordDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationTransactionBrief
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationTransactionBrief
     */
    'type': string;
}

export const LoanApplicationTransactionBriefDirectionEnum = {
    INBOUND: 'INBOUND',
    OUTBOUND: 'OUTBOUND'
} as const;

export type LoanApplicationTransactionBriefDirectionEnum = typeof LoanApplicationTransactionBriefDirectionEnum[keyof typeof LoanApplicationTransactionBriefDirectionEnum];

/**
 * 
 * @export
 * @interface LoanBrief
 */
export interface LoanBrief {
    /**
     * 
     * @type {number}
     * @memberof LoanBrief
     */
    '@id'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanBrief
     */
    'activatedAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LoanBrief
     */
    'active': boolean;
    /**
     * 
     * @type {LoanSnapshotBrief}
     * @memberof LoanBrief
     */
    'actualSnapshot'?: LoanSnapshotBrief;
    /**
     * 
     * @type {Array<AmortisationSchedulePeriodBrief>}
     * @memberof LoanBrief
     */
    'amortisationSchedule'?: Array<AmortisationSchedulePeriodBrief>;
    /**
     * 
     * @type {AssigneeBrief}
     * @memberof LoanBrief
     */
    'assignee'?: AssigneeBrief;
    /**
     * 
     * @type {LegalEntityBrief}
     * @memberof LoanBrief
     */
    'borrower'?: LegalEntityBrief;
    /**
     * 
     * @type {string}
     * @memberof LoanBrief
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanBrief
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanBrief
     */
    'endDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanBrief
     */
    'feeBalanceSum'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanBrief
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LoanBrief
     */
    'interestedInRefinancing'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoanBrief
     */
    'legacyKey'?: string;
    /**
     * 
     * @type {LoanApplicationBrief}
     * @memberof LoanBrief
     */
    'loanApplication': LoanApplicationBrief;
    /**
     * 
     * @type {LoanProductBrief}
     * @memberof LoanBrief
     */
    'loanProduct'?: LoanProductBrief;
    /**
     * 
     * @type {string}
     * @memberof LoanBrief
     */
    'maturityDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanBrief
     */
    'nextPaymentDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanBrief
     */
    'number': string;
    /**
     * 
     * @type {string}
     * @memberof LoanBrief
     */
    'plannedEarlySettlementDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanBrief
     */
    'poaFromClientToArfAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanBrief
     */
    'securityDepositStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanBrief
     */
    'status': string;
    /**
     * 
     * @type {object}
     * @memberof LoanBrief
     */
    'supportingDocuments'?: object;
    /**
     * 
     * @type {string}
     * @memberof LoanBrief
     */
    'topUpResponse'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanBrief
     */
    'topUpResponseOther'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanBrief
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanBrief
     */
    'updatedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanBrief
     */
    'vaultCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanBrief
     */
    'writeOffReason'?: string;
}
/**
 * 
 * @export
 * @interface LoanOperation
 */
export interface LoanOperation {
    /**
     * 
     * @type {number}
     * @memberof LoanOperation
     */
    'amount': number;
    /**
     * 
     * @type {Array<any>}
     * @memberof LoanOperation
     */
    'attachments'?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof LoanOperation
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanOperation
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof LoanOperation
     */
    'id'?: number;
    /**
     * 
     * @type {Loan}
     * @memberof LoanOperation
     */
    'loan': Loan;
    /**
     * 
     * @type {LoanSnapshot}
     * @memberof LoanOperation
     */
    'snapshot': LoanSnapshot;
    /**
     * 
     * @type {string}
     * @memberof LoanOperation
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface LoanOperationRepresentation
 */
export interface LoanOperationRepresentation {
    /**
     * 
     * @type {number}
     * @memberof LoanOperationRepresentation
     */
    'amount': number;
    /**
     * 
     * @type {Array<any>}
     * @memberof LoanOperationRepresentation
     */
    'attachments'?: Array<any>;
    /**
     * 
     * @type {string}
     * @memberof LoanOperationRepresentation
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanOperationRepresentation
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof LoanOperationRepresentation
     */
    'id'?: number;
    /**
     * 
     * @type {Loan}
     * @memberof LoanOperationRepresentation
     */
    'loan'?: Loan;
    /**
     * 
     * @type {LoanSnapshot}
     * @memberof LoanOperationRepresentation
     */
    'snapshot': LoanSnapshot;
    /**
     * 
     * @type {string}
     * @memberof LoanOperationRepresentation
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface LoanProduct
 */
export interface LoanProduct {
    /**
     * 
     * @type {boolean}
     * @memberof LoanProduct
     */
    'active'?: boolean;
    /**
     * 
     * @type {Campaign}
     * @memberof LoanProduct
     */
    'campaign': Campaign;
    /**
     * 
     * @type {string}
     * @memberof LoanProduct
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof LoanProduct
     */
    'contractTemplateName': string;
    /**
     * 
     * @type {string}
     * @memberof LoanProduct
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanProduct
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanProduct
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    'graceInterestRatePct': number;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    'gracePeriodLength': number;
    /**
     * 
     * @type {string}
     * @memberof LoanProduct
     */
    'gracePeriodType'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanProduct
     */
    'legacyKey'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    'maxAmount': number;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    'maxFactorRatePct'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    'maxFlatRatePct'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    'maxHoldbackRatePct'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    'maxTerm': number;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    'minAmount': number;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    'minFactorRatePct'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    'minFlatRatePct'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    'minHoldbackRatePct'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    'minManagementFee': number;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    'minManagementFeeRatePct': number;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    'minTerm': number;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    'minValuationFee': number;
    /**
     * A date-based amount of time in the ISO-8601 calendar system.
     * @type {string}
     * @memberof LoanProduct
     */
    'paymentFrequency'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    'posProcessingFee': number;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    'profitOnlyPeriodLength': number;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    'profitOnlyRatePct': number;
    /**
     * 
     * @type {string}
     * @memberof LoanProduct
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanProduct
     */
    'updatedBy'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanProduct
     */
    'vatRatePct': number;
}
/**
 * 
 * @export
 * @interface LoanProductBrief
 */
export interface LoanProductBrief {
    /**
     * 
     * @type {boolean}
     * @memberof LoanProductBrief
     */
    'active'?: boolean;
    /**
     * 
     * @type {CampaignBrief}
     * @memberof LoanProductBrief
     */
    'campaign': CampaignBrief;
    /**
     * 
     * @type {string}
     * @memberof LoanProductBrief
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof LoanProductBrief
     */
    'contractTemplateName': string;
    /**
     * 
     * @type {string}
     * @memberof LoanProductBrief
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanProductBrief
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanProductBrief
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBrief
     */
    'graceInterestRatePct': number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBrief
     */
    'gracePeriodLength': number;
    /**
     * 
     * @type {string}
     * @memberof LoanProductBrief
     */
    'gracePeriodType'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBrief
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanProductBrief
     */
    'legacyKey'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBrief
     */
    'maxAmount': number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBrief
     */
    'maxFactorRatePct'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBrief
     */
    'maxFlatRatePct'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBrief
     */
    'maxHoldbackRatePct'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBrief
     */
    'maxTerm': number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBrief
     */
    'minAmount': number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBrief
     */
    'minFactorRatePct'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBrief
     */
    'minFlatRatePct'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBrief
     */
    'minHoldbackRatePct'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBrief
     */
    'minManagementFee': number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBrief
     */
    'minManagementFeeRatePct': number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBrief
     */
    'minTerm': number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBrief
     */
    'minValuationFee': number;
    /**
     * A date-based amount of time in the ISO-8601 calendar system.
     * @type {string}
     * @memberof LoanProductBrief
     */
    'paymentFrequency'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBrief
     */
    'posProcessingFee': number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBrief
     */
    'profitOnlyPeriodLength': number;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBrief
     */
    'profitOnlyRatePct': number;
    /**
     * 
     * @type {string}
     * @memberof LoanProductBrief
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanProductBrief
     */
    'updatedBy'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanProductBrief
     */
    'vatRatePct': number;
}
/**
 * 
 * @export
 * @interface LoanSnapshot
 */
export interface LoanSnapshot {
    /**
     * 
     * @type {string}
     * @memberof LoanSnapshot
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanSnapshot
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    'daysInOverdue'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    'earlyRepaymentFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    'fullRepaymentAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    'interestBalance'?: number;
    /**
     * 
     * @type {Loan}
     * @memberof LoanSnapshot
     */
    'loan'?: Loan;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    'outstandingBalance'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanSnapshot
     */
    'overdueDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    'pastDueInstallmentPayment'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    'principalBalance'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    'securityDepositOutstandingBalance'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    'technicalAccount'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshot
     */
    'totalPaidAmount'?: number;
}
/**
 * 
 * @export
 * @interface LoanSnapshotBrief
 */
export interface LoanSnapshotBrief {
    /**
     * 
     * @type {string}
     * @memberof LoanSnapshotBrief
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanSnapshotBrief
     */
    'date': string;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    'daysInOverdue'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    'earlyRepaymentFee'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    'fullRepaymentAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    'interestBalance'?: number;
    /**
     * 
     * @type {LoanBrief}
     * @memberof LoanSnapshotBrief
     */
    'loan'?: LoanBrief;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    'outstandingBalance'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanSnapshotBrief
     */
    'overdueDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    'pastDueInstallmentPayment'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    'principalBalance'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    'securityDepositOutstandingBalance'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    'technicalAccount'?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanSnapshotBrief
     */
    'totalPaidAmount'?: number;
}
/**
 * 
 * @export
 * @interface LoanTransaction
 */
export interface LoanTransaction extends TransactionBrief {
    /**
     * 
     * @type {Loan}
     * @memberof LoanTransaction
     */
    'loan': Loan;
}


/**
 * 
 * @export
 * @interface LoanTransactionBrief
 */
export interface LoanTransactionBrief {
    /**
     * 
     * @type {string}
     * @memberof LoanTransactionBrief
     */
    'accountNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanTransactionBrief
     */
    'alraedahBank'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanTransactionBrief
     */
    'amount': number;
    /**
     * 
     * @type {Array<FileBrief>}
     * @memberof LoanTransactionBrief
     */
    'attachments'?: Array<FileBrief>;
    /**
     * 
     * @type {string}
     * @memberof LoanTransactionBrief
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof LoanTransactionBrief
     */
    'customerBank'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanTransactionBrief
     */
    'depositDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanTransactionBrief
     */
    'direction': LoanTransactionBriefDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof LoanTransactionBrief
     */
    'documentNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanTransactionBrief
     */
    'errorMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanTransactionBrief
     */
    'externalPaymentService'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanTransactionBrief
     */
    'externalTransactionId'?: string;
    /**
     * 
     * @type {number}
     * @memberof LoanTransactionBrief
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanTransactionBrief
     */
    'initiator'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanTransactionBrief
     */
    'legacyKey'?: string;
    /**
     * 
     * @type {LoanBrief}
     * @memberof LoanTransactionBrief
     */
    'loan': LoanBrief;
    /**
     * 
     * @type {string}
     * @memberof LoanTransactionBrief
     */
    'method'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanTransactionBrief
     */
    'paymentSource'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanTransactionBrief
     */
    'paymentType': string;
    /**
     * 
     * @type {boolean}
     * @memberof LoanTransactionBrief
     */
    'processed': boolean;
    /**
     * 
     * @type {string}
     * @memberof LoanTransactionBrief
     */
    'processedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanTransactionBrief
     */
    'recordDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof LoanTransactionBrief
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof LoanTransactionBrief
     */
    'type': string;
}

export const LoanTransactionBriefDirectionEnum = {
    INBOUND: 'INBOUND',
    OUTBOUND: 'OUTBOUND'
} as const;

export type LoanTransactionBriefDirectionEnum = typeof LoanTransactionBriefDirectionEnum[keyof typeof LoanTransactionBriefDirectionEnum];

/**
 * 
 * @export
 * @interface ModelFile
 */
export interface ModelFile {
    /**
     * 
     * @type {number}
     * @memberof ModelFile
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'originalName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface Owner
 */
export interface Owner {
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    'createdBy'?: string;
    /**
     * 
     * @type {number}
     * @memberof Owner
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    'legacyKey'?: string;
    /**
     * 
     * @type {number}
     * @memberof Owner
     */
    'legalEntityId': number;
    /**
     * 
     * @type {GuarantorParty}
     * @memberof Owner
     */
    'party': GuarantorParty;
    /**
     * 
     * @type {number}
     * @memberof Owner
     */
    'percentageShare'?: number;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    'sharedFolderLocation'?: string;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Owner
     */
    'updatedBy'?: string;
}
/**
 * 
 * @export
 * @interface PageActivityAuditTrail
 */
export interface PageActivityAuditTrail {
    /**
     * 
     * @type {Array<ActivityAuditTrail>}
     * @memberof PageActivityAuditTrail
     */
    'content'?: Array<ActivityAuditTrail>;
    /**
     * 
     * @type {boolean}
     * @memberof PageActivityAuditTrail
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageActivityAuditTrail
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageActivityAuditTrail
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageActivityAuditTrail
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageActivityAuditTrail
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageActivityAuditTrail
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageActivityAuditTrail
     */
    'size'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageActivityAuditTrail
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageActivityAuditTrail
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageActivityAuditTrail
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageCampaignBrief
 */
export interface PageCampaignBrief {
    /**
     * 
     * @type {Array<CampaignBrief>}
     * @memberof PageCampaignBrief
     */
    'content'?: Array<CampaignBrief>;
    /**
     * 
     * @type {boolean}
     * @memberof PageCampaignBrief
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCampaignBrief
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCampaignBrief
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageCampaignBrief
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCampaignBrief
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObjectBrief}
     * @memberof PageCampaignBrief
     */
    'pageable'?: PageableObjectBrief;
    /**
     * 
     * @type {number}
     * @memberof PageCampaignBrief
     */
    'size'?: number;
    /**
     * 
     * @type {SortBrief}
     * @memberof PageCampaignBrief
     */
    'sort'?: SortBrief;
    /**
     * 
     * @type {number}
     * @memberof PageCampaignBrief
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCampaignBrief
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageCollectionBrief
 */
export interface PageCollectionBrief {
    /**
     * 
     * @type {Array<CollectionBrief>}
     * @memberof PageCollectionBrief
     */
    'content'?: Array<CollectionBrief>;
    /**
     * 
     * @type {boolean}
     * @memberof PageCollectionBrief
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCollectionBrief
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCollectionBrief
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageCollectionBrief
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCollectionBrief
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObjectBrief}
     * @memberof PageCollectionBrief
     */
    'pageable'?: PageableObjectBrief;
    /**
     * 
     * @type {number}
     * @memberof PageCollectionBrief
     */
    'size'?: number;
    /**
     * 
     * @type {SortBrief}
     * @memberof PageCollectionBrief
     */
    'sort'?: SortBrief;
    /**
     * 
     * @type {number}
     * @memberof PageCollectionBrief
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCollectionBrief
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageIndividualBrief
 */
export interface PageIndividualBrief {
    /**
     * 
     * @type {Array<IndividualBrief>}
     * @memberof PageIndividualBrief
     */
    'content'?: Array<IndividualBrief>;
    /**
     * 
     * @type {boolean}
     * @memberof PageIndividualBrief
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageIndividualBrief
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageIndividualBrief
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageIndividualBrief
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageIndividualBrief
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObjectBrief}
     * @memberof PageIndividualBrief
     */
    'pageable'?: PageableObjectBrief;
    /**
     * 
     * @type {number}
     * @memberof PageIndividualBrief
     */
    'size'?: number;
    /**
     * 
     * @type {SortBrief}
     * @memberof PageIndividualBrief
     */
    'sort'?: SortBrief;
    /**
     * 
     * @type {number}
     * @memberof PageIndividualBrief
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageIndividualBrief
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageLegalEntityBrief
 */
export interface PageLegalEntityBrief {
    /**
     * 
     * @type {Array<LegalEntityBrief>}
     * @memberof PageLegalEntityBrief
     */
    'content'?: Array<LegalEntityBrief>;
    /**
     * 
     * @type {boolean}
     * @memberof PageLegalEntityBrief
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLegalEntityBrief
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLegalEntityBrief
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageLegalEntityBrief
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLegalEntityBrief
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObjectBrief}
     * @memberof PageLegalEntityBrief
     */
    'pageable'?: PageableObjectBrief;
    /**
     * 
     * @type {number}
     * @memberof PageLegalEntityBrief
     */
    'size'?: number;
    /**
     * 
     * @type {SortBrief}
     * @memberof PageLegalEntityBrief
     */
    'sort'?: SortBrief;
    /**
     * 
     * @type {number}
     * @memberof PageLegalEntityBrief
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLegalEntityBrief
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageLoan
 */
export interface PageLoan {
    /**
     * 
     * @type {Array<Loan>}
     * @memberof PageLoan
     */
    'content'?: Array<Loan>;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoan
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoan
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoan
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageLoan
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoan
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageLoan
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageLoan
     */
    'size'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageLoan
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageLoan
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoan
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageLoanApplicationBrief
 */
export interface PageLoanApplicationBrief {
    /**
     * 
     * @type {Array<LoanApplicationBrief>}
     * @memberof PageLoanApplicationBrief
     */
    'content'?: Array<LoanApplicationBrief>;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanApplicationBrief
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanApplicationBrief
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanApplicationBrief
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageLoanApplicationBrief
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanApplicationBrief
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObjectBrief}
     * @memberof PageLoanApplicationBrief
     */
    'pageable'?: PageableObjectBrief;
    /**
     * 
     * @type {number}
     * @memberof PageLoanApplicationBrief
     */
    'size'?: number;
    /**
     * 
     * @type {SortBrief}
     * @memberof PageLoanApplicationBrief
     */
    'sort'?: SortBrief;
    /**
     * 
     * @type {number}
     * @memberof PageLoanApplicationBrief
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanApplicationBrief
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageLoanApplicationTransactionBrief
 */
export interface PageLoanApplicationTransactionBrief {
    /**
     * 
     * @type {Array<LoanApplicationTransactionBrief>}
     * @memberof PageLoanApplicationTransactionBrief
     */
    'content'?: Array<LoanApplicationTransactionBrief>;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanApplicationTransactionBrief
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanApplicationTransactionBrief
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanApplicationTransactionBrief
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageLoanApplicationTransactionBrief
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanApplicationTransactionBrief
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObjectBrief}
     * @memberof PageLoanApplicationTransactionBrief
     */
    'pageable'?: PageableObjectBrief;
    /**
     * 
     * @type {number}
     * @memberof PageLoanApplicationTransactionBrief
     */
    'size'?: number;
    /**
     * 
     * @type {SortBrief}
     * @memberof PageLoanApplicationTransactionBrief
     */
    'sort'?: SortBrief;
    /**
     * 
     * @type {number}
     * @memberof PageLoanApplicationTransactionBrief
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanApplicationTransactionBrief
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageLoanBrief
 */
export interface PageLoanBrief {
    /**
     * 
     * @type {Array<LoanBrief>}
     * @memberof PageLoanBrief
     */
    'content'?: Array<LoanBrief>;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanBrief
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanBrief
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanBrief
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageLoanBrief
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanBrief
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObjectBrief}
     * @memberof PageLoanBrief
     */
    'pageable'?: PageableObjectBrief;
    /**
     * 
     * @type {number}
     * @memberof PageLoanBrief
     */
    'size'?: number;
    /**
     * 
     * @type {SortBrief}
     * @memberof PageLoanBrief
     */
    'sort'?: SortBrief;
    /**
     * 
     * @type {number}
     * @memberof PageLoanBrief
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanBrief
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageLoanOperation
 */
export interface PageLoanOperation {
    /**
     * 
     * @type {Array<LoanOperation>}
     * @memberof PageLoanOperation
     */
    'content'?: Array<LoanOperation>;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanOperation
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanOperation
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanOperation
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageLoanOperation
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanOperation
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageLoanOperation
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageLoanOperation
     */
    'size'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageLoanOperation
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageLoanOperation
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanOperation
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageLoanProduct
 */
export interface PageLoanProduct {
    /**
     * 
     * @type {Array<LoanProduct>}
     * @memberof PageLoanProduct
     */
    'content'?: Array<LoanProduct>;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanProduct
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanProduct
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanProduct
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageLoanProduct
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanProduct
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageLoanProduct
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageLoanProduct
     */
    'size'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageLoanProduct
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageLoanProduct
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanProduct
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageLoanTransactionBrief
 */
export interface PageLoanTransactionBrief {
    /**
     * 
     * @type {Array<LoanTransactionBrief>}
     * @memberof PageLoanTransactionBrief
     */
    'content'?: Array<LoanTransactionBrief>;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanTransactionBrief
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanTransactionBrief
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLoanTransactionBrief
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageLoanTransactionBrief
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanTransactionBrief
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObjectBrief}
     * @memberof PageLoanTransactionBrief
     */
    'pageable'?: PageableObjectBrief;
    /**
     * 
     * @type {number}
     * @memberof PageLoanTransactionBrief
     */
    'size'?: number;
    /**
     * 
     * @type {SortBrief}
     * @memberof PageLoanTransactionBrief
     */
    'sort'?: SortBrief;
    /**
     * 
     * @type {number}
     * @memberof PageLoanTransactionBrief
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLoanTransactionBrief
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageRevisionIntegerCampaign
 */
export interface PageRevisionIntegerCampaign {
    /**
     * 
     * @type {Array<RevisionIntegerCampaign>}
     * @memberof PageRevisionIntegerCampaign
     */
    'content'?: Array<RevisionIntegerCampaign>;
    /**
     * 
     * @type {boolean}
     * @memberof PageRevisionIntegerCampaign
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRevisionIntegerCampaign
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRevisionIntegerCampaign
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerCampaign
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerCampaign
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageRevisionIntegerCampaign
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerCampaign
     */
    'size'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageRevisionIntegerCampaign
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerCampaign
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerCampaign
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageRevisionIntegerLegalEntity
 */
export interface PageRevisionIntegerLegalEntity {
    /**
     * 
     * @type {Array<RevisionIntegerLegalEntity>}
     * @memberof PageRevisionIntegerLegalEntity
     */
    'content'?: Array<RevisionIntegerLegalEntity>;
    /**
     * 
     * @type {boolean}
     * @memberof PageRevisionIntegerLegalEntity
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRevisionIntegerLegalEntity
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRevisionIntegerLegalEntity
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerLegalEntity
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerLegalEntity
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageRevisionIntegerLegalEntity
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerLegalEntity
     */
    'size'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageRevisionIntegerLegalEntity
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerLegalEntity
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerLegalEntity
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageRevisionIntegerLoanApplication
 */
export interface PageRevisionIntegerLoanApplication {
    /**
     * 
     * @type {Array<RevisionIntegerLoanApplication>}
     * @memberof PageRevisionIntegerLoanApplication
     */
    'content'?: Array<RevisionIntegerLoanApplication>;
    /**
     * 
     * @type {boolean}
     * @memberof PageRevisionIntegerLoanApplication
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRevisionIntegerLoanApplication
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRevisionIntegerLoanApplication
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerLoanApplication
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerLoanApplication
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageRevisionIntegerLoanApplication
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerLoanApplication
     */
    'size'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageRevisionIntegerLoanApplication
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerLoanApplication
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerLoanApplication
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageRevisionIntegerLoanProduct
 */
export interface PageRevisionIntegerLoanProduct {
    /**
     * 
     * @type {Array<RevisionIntegerLoanProduct>}
     * @memberof PageRevisionIntegerLoanProduct
     */
    'content'?: Array<RevisionIntegerLoanProduct>;
    /**
     * 
     * @type {boolean}
     * @memberof PageRevisionIntegerLoanProduct
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRevisionIntegerLoanProduct
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRevisionIntegerLoanProduct
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerLoanProduct
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerLoanProduct
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageRevisionIntegerLoanProduct
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerLoanProduct
     */
    'size'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageRevisionIntegerLoanProduct
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerLoanProduct
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRevisionIntegerLoanProduct
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageTaskRepresentation
 */
export interface PageTaskRepresentation {
    /**
     * 
     * @type {Array<TaskRepresentation>}
     * @memberof PageTaskRepresentation
     */
    'content'?: Array<TaskRepresentation>;
    /**
     * 
     * @type {boolean}
     * @memberof PageTaskRepresentation
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTaskRepresentation
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTaskRepresentation
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageTaskRepresentation
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTaskRepresentation
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageTaskRepresentation
     */
    'pageable'?: PageableObject;
    /**
     * 
     * @type {number}
     * @memberof PageTaskRepresentation
     */
    'size'?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageTaskRepresentation
     */
    'sort'?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageTaskRepresentation
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTaskRepresentation
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageTransactionBrief
 */
export interface PageTransactionBrief {
    /**
     * 
     * @type {Array<GetTransaction200Response>}
     * @memberof PageTransactionBrief
     */
    'content'?: Array<GetTransaction200Response>;
    /**
     * 
     * @type {boolean}
     * @memberof PageTransactionBrief
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTransactionBrief
     */
    'first'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageTransactionBrief
     */
    'last'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageTransactionBrief
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTransactionBrief
     */
    'numberOfElements'?: number;
    /**
     * 
     * @type {PageableObjectBrief}
     * @memberof PageTransactionBrief
     */
    'pageable'?: PageableObjectBrief;
    /**
     * 
     * @type {number}
     * @memberof PageTransactionBrief
     */
    'size'?: number;
    /**
     * 
     * @type {SortBrief}
     * @memberof PageTransactionBrief
     */
    'sort'?: SortBrief;
    /**
     * 
     * @type {number}
     * @memberof PageTransactionBrief
     */
    'totalElements'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageTransactionBrief
     */
    'totalPages'?: number;
}
/**
 * 
 * @export
 * @interface PageableObject
 */
export interface PageableObject {
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObject
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'paged'?: boolean;
    /**
     * 
     * @type {Sort}
     * @memberof PageableObject
     */
    'sort'?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObject
     */
    'unpaged'?: boolean;
}
/**
 * 
 * @export
 * @interface PageableObjectBrief
 */
export interface PageableObjectBrief {
    /**
     * 
     * @type {number}
     * @memberof PageableObjectBrief
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObjectBrief
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageableObjectBrief
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObjectBrief
     */
    'paged'?: boolean;
    /**
     * 
     * @type {SortBrief}
     * @memberof PageableObjectBrief
     */
    'sort'?: SortBrief;
    /**
     * 
     * @type {boolean}
     * @memberof PageableObjectBrief
     */
    'unpaged'?: boolean;
}
/**
 * 
 * @export
 * @interface Party
 */
export interface Party {
    /**
     * 
     * @type {Address}
     * @memberof Party
     */
    'address'?: Address;
    /**
     * 
     * @type {string}
     * @memberof Party
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Party
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Party
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof Party
     */
    'englishName'?: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Party
     */
    'extensions'?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof Party
     */
    'fullName'?: string;
    /**
     * 
     * @type {number}
     * @memberof Party
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Party
     */
    'legacyKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof Party
     */
    'number'?: string;
    /**
     * 
     * @type {string}
     * @memberof Party
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof Party
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof Party
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Party
     */
    'updatedBy'?: string;
}
/**
 * 
 * @export
 * @interface Pos
 */
export interface Pos {
    /**
     * 
     * @type {string}
     * @memberof Pos
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Pos
     */
    'createdBy'?: string;
    /**
     * 
     * @type {number}
     * @memberof Pos
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Pos
     */
    'legacyKey'?: string;
    /**
     * 
     * @type {number}
     * @memberof Pos
     */
    'legalEntityId': number;
    /**
     * 
     * @type {string}
     * @memberof Pos
     */
    'paymentGateway'?: string;
    /**
     * 
     * @type {string}
     * @memberof Pos
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof Pos
     */
    'summary'?: string;
    /**
     * 
     * @type {string}
     * @memberof Pos
     */
    'tid'?: string;
    /**
     * 
     * @type {string}
     * @memberof Pos
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Pos
     */
    'updatedBy'?: string;
}
/**
 * 
 * @export
 * @interface ProcessDefinitionRepresentation
 */
export interface ProcessDefinitionRepresentation {
    /**
     * 
     * @type {string}
     * @memberof ProcessDefinitionRepresentation
     */
    'category'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessDefinitionRepresentation
     */
    'deploymentId': string;
    /**
     * 
     * @type {string}
     * @memberof ProcessDefinitionRepresentation
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessDefinitionRepresentation
     */
    'diagram': string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProcessDefinitionRepresentation
     */
    'extensions'?: { [key: string]: object; };
    /**
     * 
     * @type {number}
     * @memberof ProcessDefinitionRepresentation
     */
    'historyTimeToLive'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProcessDefinitionRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProcessDefinitionRepresentation
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof ProcessDefinitionRepresentation
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProcessDefinitionRepresentation
     */
    'resource'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessDefinitionRepresentation
     */
    'startFormKey'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProcessDefinitionRepresentation
     */
    'startableInTasklist'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProcessDefinitionRepresentation
     */
    'suspended': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProcessDefinitionRepresentation
     */
    'tenantId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProcessDefinitionRepresentation
     */
    'version'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProcessDefinitionRepresentation
     */
    'versionTag'?: string;
}
/**
 * 
 * @export
 * @interface ProfitabilityRatios
 */
export interface ProfitabilityRatios {
    /**
     * 
     * @type {number}
     * @memberof ProfitabilityRatios
     */
    'ebitdaMargin': number;
    /**
     * 
     * @type {number}
     * @memberof ProfitabilityRatios
     */
    'grossProfitMargin': number;
    /**
     * 
     * @type {number}
     * @memberof ProfitabilityRatios
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProfitabilityRatios
     */
    'netDebtToEbitda': number;
    /**
     * 
     * @type {number}
     * @memberof ProfitabilityRatios
     */
    'netProfitMargin': number;
    /**
     * 
     * @type {number}
     * @memberof ProfitabilityRatios
     */
    'netWorthToTotalAssets': number;
    /**
     * 
     * @type {number}
     * @memberof ProfitabilityRatios
     */
    'operatingProfitMargin': number;
}
/**
 * 
 * @export
 * @interface ProfitabilityRatiosBrief
 */
export interface ProfitabilityRatiosBrief {
    /**
     * 
     * @type {number}
     * @memberof ProfitabilityRatiosBrief
     */
    'ebitdaMargin': number;
    /**
     * 
     * @type {number}
     * @memberof ProfitabilityRatiosBrief
     */
    'grossProfitMargin': number;
    /**
     * 
     * @type {number}
     * @memberof ProfitabilityRatiosBrief
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProfitabilityRatiosBrief
     */
    'netDebtToEbitda': number;
    /**
     * 
     * @type {number}
     * @memberof ProfitabilityRatiosBrief
     */
    'netProfitMargin': number;
    /**
     * 
     * @type {number}
     * @memberof ProfitabilityRatiosBrief
     */
    'netWorthToTotalAssets': number;
    /**
     * 
     * @type {number}
     * @memberof ProfitabilityRatiosBrief
     */
    'operatingProfitMargin': number;
}
/**
 * 
 * @export
 * @interface RevisionIntegerCampaign
 */
export interface RevisionIntegerCampaign {
    /**
     * 
     * @type {Campaign}
     * @memberof RevisionIntegerCampaign
     */
    'entity'?: Campaign;
    /**
     * 
     * @type {RevisionMetadataInteger}
     * @memberof RevisionIntegerCampaign
     */
    'metadata'?: RevisionMetadataInteger;
    /**
     * 
     * @type {string}
     * @memberof RevisionIntegerCampaign
     */
    'requiredRevisionInstant'?: string;
    /**
     * 
     * @type {number}
     * @memberof RevisionIntegerCampaign
     */
    'requiredRevisionNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof RevisionIntegerCampaign
     */
    'revisionInstant'?: string;
    /**
     * 
     * @type {number}
     * @memberof RevisionIntegerCampaign
     */
    'revisionNumber'?: number;
}
/**
 * 
 * @export
 * @interface RevisionIntegerLegalEntity
 */
export interface RevisionIntegerLegalEntity {
    /**
     * 
     * @type {LegalEntity}
     * @memberof RevisionIntegerLegalEntity
     */
    'entity'?: LegalEntity;
    /**
     * 
     * @type {RevisionMetadataInteger}
     * @memberof RevisionIntegerLegalEntity
     */
    'metadata'?: RevisionMetadataInteger;
    /**
     * 
     * @type {string}
     * @memberof RevisionIntegerLegalEntity
     */
    'requiredRevisionInstant'?: string;
    /**
     * 
     * @type {number}
     * @memberof RevisionIntegerLegalEntity
     */
    'requiredRevisionNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof RevisionIntegerLegalEntity
     */
    'revisionInstant'?: string;
    /**
     * 
     * @type {number}
     * @memberof RevisionIntegerLegalEntity
     */
    'revisionNumber'?: number;
}
/**
 * 
 * @export
 * @interface RevisionIntegerLoanApplication
 */
export interface RevisionIntegerLoanApplication {
    /**
     * 
     * @type {LoanApplication}
     * @memberof RevisionIntegerLoanApplication
     */
    'entity'?: LoanApplication;
    /**
     * 
     * @type {RevisionMetadataInteger}
     * @memberof RevisionIntegerLoanApplication
     */
    'metadata'?: RevisionMetadataInteger;
    /**
     * 
     * @type {string}
     * @memberof RevisionIntegerLoanApplication
     */
    'requiredRevisionInstant'?: string;
    /**
     * 
     * @type {number}
     * @memberof RevisionIntegerLoanApplication
     */
    'requiredRevisionNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof RevisionIntegerLoanApplication
     */
    'revisionInstant'?: string;
    /**
     * 
     * @type {number}
     * @memberof RevisionIntegerLoanApplication
     */
    'revisionNumber'?: number;
}
/**
 * 
 * @export
 * @interface RevisionIntegerLoanProduct
 */
export interface RevisionIntegerLoanProduct {
    /**
     * 
     * @type {LoanProduct}
     * @memberof RevisionIntegerLoanProduct
     */
    'entity'?: LoanProduct;
    /**
     * 
     * @type {RevisionMetadataInteger}
     * @memberof RevisionIntegerLoanProduct
     */
    'metadata'?: RevisionMetadataInteger;
    /**
     * 
     * @type {string}
     * @memberof RevisionIntegerLoanProduct
     */
    'requiredRevisionInstant'?: string;
    /**
     * 
     * @type {number}
     * @memberof RevisionIntegerLoanProduct
     */
    'requiredRevisionNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof RevisionIntegerLoanProduct
     */
    'revisionInstant'?: string;
    /**
     * 
     * @type {number}
     * @memberof RevisionIntegerLoanProduct
     */
    'revisionNumber'?: number;
}
/**
 * 
 * @export
 * @interface RevisionMetadataInteger
 */
export interface RevisionMetadataInteger {
    /**
     * 
     * @type {object}
     * @memberof RevisionMetadataInteger
     */
    'delegate'?: object;
    /**
     * 
     * @type {string}
     * @memberof RevisionMetadataInteger
     */
    'requiredRevisionInstant'?: string;
    /**
     * 
     * @type {number}
     * @memberof RevisionMetadataInteger
     */
    'requiredRevisionNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof RevisionMetadataInteger
     */
    'revisionInstant'?: string;
    /**
     * 
     * @type {number}
     * @memberof RevisionMetadataInteger
     */
    'revisionNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof RevisionMetadataInteger
     */
    'revisionType'?: RevisionMetadataIntegerRevisionTypeEnum;
}

export const RevisionMetadataIntegerRevisionTypeEnum = {
    UNKNOWN: 'UNKNOWN',
    INSERT: 'INSERT',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE'
} as const;

export type RevisionMetadataIntegerRevisionTypeEnum = typeof RevisionMetadataIntegerRevisionTypeEnum[keyof typeof RevisionMetadataIntegerRevisionTypeEnum];

/**
 * 
 * @export
 * @interface Scoring
 */
export interface Scoring {
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof Scoring
     */
    'response'?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof Scoring
     */
    'resultScore'?: string;
}
/**
 * 
 * @export
 * @interface ScoringBrief
 */
export interface ScoringBrief {
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ScoringBrief
     */
    'response'?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof ScoringBrief
     */
    'resultScore'?: string;
}
/**
 * 
 * @export
 * @interface Sort
 */
export interface Sort {
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'sorted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    'unsorted'?: boolean;
}
/**
 * 
 * @export
 * @interface SortBrief
 */
export interface SortBrief {
    /**
     * 
     * @type {boolean}
     * @memberof SortBrief
     */
    'empty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortBrief
     */
    'sorted'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SortBrief
     */
    'unsorted'?: boolean;
}
/**
 * 
 * @export
 * @interface SupportingDocuments
 */
export interface SupportingDocuments {
    /**
     * 
     * @type {Array<any>}
     * @memberof SupportingDocuments
     */
    'articleOfAssociation'?: Array<any>;
    /**
     * 
     * @type {Array<any>}
     * @memberof SupportingDocuments
     */
    'businessLicense'?: Array<any>;
    /**
     * 
     * @type {Array<any>}
     * @memberof SupportingDocuments
     */
    'clientVisitPhoto'?: Array<any>;
    /**
     * 
     * @type {Array<any>}
     * @memberof SupportingDocuments
     */
    'commercialRegistryDocument'?: Array<any>;
    /**
     * 
     * @type {Array<any>}
     * @memberof SupportingDocuments
     */
    'nationalAddress'?: Array<any>;
    /**
     * 
     * @type {Array<any>}
     * @memberof SupportingDocuments
     */
    'otherDocument'?: Array<any>;
}
/**
 * 
 * @export
 * @interface TaskRepresentation
 */
export interface TaskRepresentation {
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    'assignee'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    'caseDefinitionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    'caseExecutionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    'caseInstanceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    'createTime': string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    'delegationState'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    'deploymentId': string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    'dueDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    'executionId': string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof TaskRepresentation
     */
    'extensions'?: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    'followUpDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    'formKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    'owner'?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    'parentTaskId'?: string;
    /**
     * 
     * @type {number}
     * @memberof TaskRepresentation
     */
    'priority'?: number;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    'processDefinitionId': string;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    'processInstanceId': string;
    /**
     * 
     * @type {boolean}
     * @memberof TaskRepresentation
     */
    'suspended': boolean;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    'taskDefinitionKey': string;
    /**
     * 
     * @type {object}
     * @memberof TaskRepresentation
     */
    'taskInfo'?: object;
    /**
     * 
     * @type {string}
     * @memberof TaskRepresentation
     */
    'tenantId'?: string;
}
/**
 * 
 * @export
 * @interface TemplateDescription
 */
export interface TemplateDescription {
    /**
     * 
     * @type {string}
     * @memberof TemplateDescription
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateDescription
     */
    'path'?: string;
}
/**
 * 
 * @export
 * @interface TransactionBrief
 */
export interface TransactionBrief {
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    'accountNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    'alraedahBank'?: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionBrief
     */
    'amount': number;
    /**
     * 
     * @type {Array<FileBrief>}
     * @memberof TransactionBrief
     */
    'attachments'?: Array<FileBrief>;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    'customerBank'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    'depositDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    'direction': TransactionBriefDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    'documentNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    'errorMessage'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    'externalPaymentService'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    'externalTransactionId'?: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionBrief
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    'initiator'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    'legacyKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    'method'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    'paymentSource'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    'paymentType': string;
    /**
     * 
     * @type {boolean}
     * @memberof TransactionBrief
     */
    'processed': boolean;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    'processedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    'recordDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof TransactionBrief
     */
    'type': string;
}

export const TransactionBriefDirectionEnum = {
    INBOUND: 'INBOUND',
    OUTBOUND: 'OUTBOUND'
} as const;

export type TransactionBriefDirectionEnum = typeof TransactionBriefDirectionEnum[keyof typeof TransactionBriefDirectionEnum];

/**
 * 
 * @export
 * @interface UiConfigurations
 */
export interface UiConfigurations {
    /**
     * 
     * @type {string}
     * @memberof UiConfigurations
     */
    'appName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UiConfigurations
     */
    'authClientId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UiConfigurations
     */
    'authRealm'?: string;
    /**
     * 
     * @type {string}
     * @memberof UiConfigurations
     */
    'authServerUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UiConfigurations
     */
    'borrowerType'?: string;
    /**
     * 
     * @type {string}
     * @memberof UiConfigurations
     */
    'defaultLocale'?: string;
    /**
     * 
     * @type {string}
     * @memberof UiConfigurations
     */
    'defaultProcessKey'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UiConfigurations
     */
    'env'?: { [key: string]: string; };
    /**
     * 
     * @type {Array<string>}
     * @memberof UiConfigurations
     */
    'supportedLocales'?: Array<string>;
}

/**
 * BPMSIncidentsManagementApi - axios parameter creator
 * @export
 */
export const BPMSIncidentsManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Asynchronously increase retry count for all insidents in the system
         * @summary Bulk retry incidents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkRetryIncidents: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/incident/bulk-retry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BPMSIncidentsManagementApi - functional programming interface
 * @export
 */
export const BPMSIncidentsManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BPMSIncidentsManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * Asynchronously increase retry count for all insidents in the system
         * @summary Bulk retry incidents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkRetryIncidents(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkRetryIncidentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkRetryIncidents(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BPMSIncidentsManagementApi - factory interface
 * @export
 */
export const BPMSIncidentsManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BPMSIncidentsManagementApiFp(configuration)
    return {
        /**
         * Asynchronously increase retry count for all insidents in the system
         * @summary Bulk retry incidents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkRetryIncidents(options?: AxiosRequestConfig): AxiosPromise<BulkRetryIncidentsResponse> {
            return localVarFp.bulkRetryIncidents(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BPMSIncidentsManagementApi - object-oriented interface
 * @export
 * @class BPMSIncidentsManagementApi
 * @extends {BaseAPI}
 */
export class BPMSIncidentsManagementApi extends BaseAPI {
    /**
     * Asynchronously increase retry count for all insidents in the system
     * @summary Bulk retry incidents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BPMSIncidentsManagementApi
     */
    public bulkRetryIncidents(options?: AxiosRequestConfig) {
        return BPMSIncidentsManagementApiFp(this.configuration).bulkRetryIncidents(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BPMSResourcesManagementApi - axios parameter creator
 * @export
 */
export const BPMSResourcesManagementApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Allowed to upload multiple resources and also packet to ZIP files. Process instances migration to new versions will be performed after deployment. All system insidents will be retried after the migration.
         * @summary Create a deployment with specified resources
         * @param {string} deploymentName Name for the deployment
         * @param {Array<File>} files Resources which the deployment will consist of
         * @param {boolean} [migrate] Migrate all process instances to new deployed versions. To perform perform migration process definitions has to comply conditions/rules explained at https://www.novatec-gmbh.de/blog/automating-process-instance-migration-with-camunda-bpm/ 
         * @param {boolean} [retryIncidents] Bulk retry all incidents in the system after deployment and migration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (deploymentName: string, files: Array<File>, migrate?: boolean, retryIncidents?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deploymentName' is not null or undefined
            assertParamExists('create', 'deploymentName', deploymentName)
            // verify required parameter 'files' is not null or undefined
            assertParamExists('create', 'files', files)
            const localVarPath = `/api/deployment/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)

            if (deploymentName !== undefined) {
                localVarQueryParameter['deployment-name'] = deploymentName;
            }

            if (files) {
                localVarQueryParameter['files'] = files;
            }

            if (migrate !== undefined) {
                localVarQueryParameter['migrate'] = migrate;
            }

            if (retryIncidents !== undefined) {
                localVarQueryParameter['retry-incidents'] = retryIncidents;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a form by key from \'/public\' folder in deployment storage
         * @summary Retrieves a deployed form by key
         * @param {string} formKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForm: async (formKey: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'formKey' is not null or undefined
            assertParamExists('getForm', 'formKey', formKey)
            const localVarPath = `/api/deployment/form`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)

            if (formKey !== undefined) {
                localVarQueryParameter['form-key'] = formKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves latest version of the resource by name from \'/public\' folder from deployment
         * @summary Retrieves a public resource by name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestResource: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/deployment/resource/**`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BPMSResourcesManagementApi - functional programming interface
 * @export
 */
export const BPMSResourcesManagementApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BPMSResourcesManagementApiAxiosParamCreator(configuration)
    return {
        /**
         * Allowed to upload multiple resources and also packet to ZIP files. Process instances migration to new versions will be performed after deployment. All system insidents will be retried after the migration.
         * @summary Create a deployment with specified resources
         * @param {string} deploymentName Name for the deployment
         * @param {Array<File>} files Resources which the deployment will consist of
         * @param {boolean} [migrate] Migrate all process instances to new deployed versions. To perform perform migration process definitions has to comply conditions/rules explained at https://www.novatec-gmbh.de/blog/automating-process-instance-migration-with-camunda-bpm/ 
         * @param {boolean} [retryIncidents] Bulk retry all incidents in the system after deployment and migration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(deploymentName: string, files: Array<File>, migrate?: boolean, retryIncidents?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeploymentRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(deploymentName, files, migrate, retryIncidents, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a form by key from \'/public\' folder in deployment storage
         * @summary Retrieves a deployed form by key
         * @param {string} formKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getForm(formKey: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getForm(formKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves latest version of the resource by name from \'/public\' folder from deployment
         * @summary Retrieves a public resource by name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLatestResource(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestResource(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BPMSResourcesManagementApi - factory interface
 * @export
 */
export const BPMSResourcesManagementApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BPMSResourcesManagementApiFp(configuration)
    return {
        /**
         * Allowed to upload multiple resources and also packet to ZIP files. Process instances migration to new versions will be performed after deployment. All system insidents will be retried after the migration.
         * @summary Create a deployment with specified resources
         * @param {BPMSResourcesManagementApiCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(requestParameters: BPMSResourcesManagementApiCreateRequest, options?: AxiosRequestConfig): AxiosPromise<DeploymentRepresentation> {
            return localVarFp.create(requestParameters.deploymentName, requestParameters.files, requestParameters.migrate, requestParameters.retryIncidents, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a form by key from \'/public\' folder in deployment storage
         * @summary Retrieves a deployed form by key
         * @param {BPMSResourcesManagementApiGetFormRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getForm(requestParameters: BPMSResourcesManagementApiGetFormRequest, options?: AxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.getForm(requestParameters.formKey, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves latest version of the resource by name from \'/public\' folder from deployment
         * @summary Retrieves a public resource by name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLatestResource(options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.getLatestResource(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for create operation in BPMSResourcesManagementApi.
 * @export
 * @interface BPMSResourcesManagementApiCreateRequest
 */
export interface BPMSResourcesManagementApiCreateRequest {
    /**
     * Name for the deployment
     * @type {string}
     * @memberof BPMSResourcesManagementApiCreate
     */
    readonly deploymentName: string

    /**
     * Resources which the deployment will consist of
     * @type {Array<File>}
     * @memberof BPMSResourcesManagementApiCreate
     */
    readonly files: Array<File>

    /**
     * Migrate all process instances to new deployed versions. To perform perform migration process definitions has to comply conditions/rules explained at https://www.novatec-gmbh.de/blog/automating-process-instance-migration-with-camunda-bpm/ 
     * @type {boolean}
     * @memberof BPMSResourcesManagementApiCreate
     */
    readonly migrate?: boolean

    /**
     * Bulk retry all incidents in the system after deployment and migration
     * @type {boolean}
     * @memberof BPMSResourcesManagementApiCreate
     */
    readonly retryIncidents?: boolean
}

/**
 * Request parameters for getForm operation in BPMSResourcesManagementApi.
 * @export
 * @interface BPMSResourcesManagementApiGetFormRequest
 */
export interface BPMSResourcesManagementApiGetFormRequest {
    /**
     * 
     * @type {string}
     * @memberof BPMSResourcesManagementApiGetForm
     */
    readonly formKey: string
}

/**
 * BPMSResourcesManagementApi - object-oriented interface
 * @export
 * @class BPMSResourcesManagementApi
 * @extends {BaseAPI}
 */
export class BPMSResourcesManagementApi extends BaseAPI {
    /**
     * Allowed to upload multiple resources and also packet to ZIP files. Process instances migration to new versions will be performed after deployment. All system insidents will be retried after the migration.
     * @summary Create a deployment with specified resources
     * @param {BPMSResourcesManagementApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BPMSResourcesManagementApi
     */
    public create(requestParameters: BPMSResourcesManagementApiCreateRequest, options?: AxiosRequestConfig) {
        return BPMSResourcesManagementApiFp(this.configuration).create(requestParameters.deploymentName, requestParameters.files, requestParameters.migrate, requestParameters.retryIncidents, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a form by key from \'/public\' folder in deployment storage
     * @summary Retrieves a deployed form by key
     * @param {BPMSResourcesManagementApiGetFormRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BPMSResourcesManagementApi
     */
    public getForm(requestParameters: BPMSResourcesManagementApiGetFormRequest, options?: AxiosRequestConfig) {
        return BPMSResourcesManagementApiFp(this.configuration).getForm(requestParameters.formKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves latest version of the resource by name from \'/public\' folder from deployment
     * @summary Retrieves a public resource by name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BPMSResourcesManagementApi
     */
    public getLatestResource(options?: AxiosRequestConfig) {
        return BPMSResourcesManagementApiFp(this.configuration).getLatestResource(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CampaignApi - axios parameter creator
 * @export
 */
export const CampaignApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create campaign
         * @param {Campaign} campaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCampaign: async (campaign: Campaign, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaign' is not null or undefined
            assertParamExists('createCampaign', 'campaign', campaign)
            const localVarPath = `/api/campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(campaign, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of campaigns
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {boolean} [filterActive] Search by campaign active flag
         * @param {Array<string>} [filterCalculationTypes] Search by campaign calculation types
         * @param {string} [filterSearch] Search by campaign name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllCampaigns: async (page?: number, size?: number, sort?: Array<string>, filterActive?: boolean, filterCalculationTypes?: Array<string>, filterSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/campaigns`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filterActive !== undefined) {
                localVarQueryParameter['filter.active'] = filterActive;
            }

            if (filterCalculationTypes) {
                localVarQueryParameter['filter.calculationTypes'] = filterCalculationTypes;
            }

            if (filterSearch !== undefined) {
                localVarQueryParameter['filter.search'] = filterSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return campaign
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaign: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCampaign', 'id', id)
            const localVarPath = `/api/campaigns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return campaign\'s history
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignHistory: async (id: number, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCampaignHistory', 'id', id)
            const localVarPath = `/api/campaigns/{id}/history`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update campaign
         * @param {number} id 
         * @param {Campaign} campaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCampaign: async (id: number, campaign: Campaign, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateCampaign', 'id', id)
            // verify required parameter 'campaign' is not null or undefined
            assertParamExists('updateCampaign', 'campaign', campaign)
            const localVarPath = `/api/campaigns/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(campaign, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignApi - functional programming interface
 * @export
 */
export const CampaignApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CampaignApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create campaign
         * @param {Campaign} campaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCampaign(campaign: Campaign, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCampaign(campaign, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return list of campaigns
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {boolean} [filterActive] Search by campaign active flag
         * @param {Array<string>} [filterCalculationTypes] Search by campaign calculation types
         * @param {string} [filterSearch] Search by campaign name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllCampaigns(page?: number, size?: number, sort?: Array<string>, filterActive?: boolean, filterCalculationTypes?: Array<string>, filterSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageCampaignBrief>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllCampaigns(page, size, sort, filterActive, filterCalculationTypes, filterSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return campaign
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCampaign(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCampaign(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return campaign\'s history
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCampaignHistory(id: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageRevisionIntegerCampaign>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCampaignHistory(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update campaign
         * @param {number} id 
         * @param {Campaign} campaign 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCampaign(id: number, campaign: Campaign, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCampaign(id, campaign, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CampaignApi - factory interface
 * @export
 */
export const CampaignApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CampaignApiFp(configuration)
    return {
        /**
         * 
         * @summary Create campaign
         * @param {CampaignApiCreateCampaignRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCampaign(requestParameters: CampaignApiCreateCampaignRequest, options?: AxiosRequestConfig): AxiosPromise<Campaign> {
            return localVarFp.createCampaign(requestParameters.campaign, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of campaigns
         * @param {CampaignApiFindAllCampaignsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllCampaigns(requestParameters: CampaignApiFindAllCampaignsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageCampaignBrief> {
            return localVarFp.findAllCampaigns(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.filterActive, requestParameters.filterCalculationTypes, requestParameters.filterSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return campaign
         * @param {CampaignApiGetCampaignRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaign(requestParameters: CampaignApiGetCampaignRequest, options?: AxiosRequestConfig): AxiosPromise<Campaign> {
            return localVarFp.getCampaign(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return campaign\'s history
         * @param {CampaignApiGetCampaignHistoryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCampaignHistory(requestParameters: CampaignApiGetCampaignHistoryRequest, options?: AxiosRequestConfig): AxiosPromise<PageRevisionIntegerCampaign> {
            return localVarFp.getCampaignHistory(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update campaign
         * @param {CampaignApiUpdateCampaignRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCampaign(requestParameters: CampaignApiUpdateCampaignRequest, options?: AxiosRequestConfig): AxiosPromise<Campaign> {
            return localVarFp.updateCampaign(requestParameters.id, requestParameters.campaign, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createCampaign operation in CampaignApi.
 * @export
 * @interface CampaignApiCreateCampaignRequest
 */
export interface CampaignApiCreateCampaignRequest {
    /**
     * 
     * @type {Campaign}
     * @memberof CampaignApiCreateCampaign
     */
    readonly campaign: Campaign
}

/**
 * Request parameters for findAllCampaigns operation in CampaignApi.
 * @export
 * @interface CampaignApiFindAllCampaignsRequest
 */
export interface CampaignApiFindAllCampaignsRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof CampaignApiFindAllCampaigns
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof CampaignApiFindAllCampaigns
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof CampaignApiFindAllCampaigns
     */
    readonly sort?: Array<string>

    /**
     * Search by campaign active flag
     * @type {boolean}
     * @memberof CampaignApiFindAllCampaigns
     */
    readonly filterActive?: boolean

    /**
     * Search by campaign calculation types
     * @type {Array<string>}
     * @memberof CampaignApiFindAllCampaigns
     */
    readonly filterCalculationTypes?: Array<string>

    /**
     * Search by campaign name
     * @type {string}
     * @memberof CampaignApiFindAllCampaigns
     */
    readonly filterSearch?: string
}

/**
 * Request parameters for getCampaign operation in CampaignApi.
 * @export
 * @interface CampaignApiGetCampaignRequest
 */
export interface CampaignApiGetCampaignRequest {
    /**
     * 
     * @type {number}
     * @memberof CampaignApiGetCampaign
     */
    readonly id: number
}

/**
 * Request parameters for getCampaignHistory operation in CampaignApi.
 * @export
 * @interface CampaignApiGetCampaignHistoryRequest
 */
export interface CampaignApiGetCampaignHistoryRequest {
    /**
     * 
     * @type {number}
     * @memberof CampaignApiGetCampaignHistory
     */
    readonly id: number

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof CampaignApiGetCampaignHistory
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof CampaignApiGetCampaignHistory
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof CampaignApiGetCampaignHistory
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for updateCampaign operation in CampaignApi.
 * @export
 * @interface CampaignApiUpdateCampaignRequest
 */
export interface CampaignApiUpdateCampaignRequest {
    /**
     * 
     * @type {number}
     * @memberof CampaignApiUpdateCampaign
     */
    readonly id: number

    /**
     * 
     * @type {Campaign}
     * @memberof CampaignApiUpdateCampaign
     */
    readonly campaign: Campaign
}

/**
 * CampaignApi - object-oriented interface
 * @export
 * @class CampaignApi
 * @extends {BaseAPI}
 */
export class CampaignApi extends BaseAPI {
    /**
     * 
     * @summary Create campaign
     * @param {CampaignApiCreateCampaignRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public createCampaign(requestParameters: CampaignApiCreateCampaignRequest, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).createCampaign(requestParameters.campaign, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of campaigns
     * @param {CampaignApiFindAllCampaignsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public findAllCampaigns(requestParameters: CampaignApiFindAllCampaignsRequest = {}, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).findAllCampaigns(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.filterActive, requestParameters.filterCalculationTypes, requestParameters.filterSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return campaign
     * @param {CampaignApiGetCampaignRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public getCampaign(requestParameters: CampaignApiGetCampaignRequest, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).getCampaign(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return campaign\'s history
     * @param {CampaignApiGetCampaignHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public getCampaignHistory(requestParameters: CampaignApiGetCampaignHistoryRequest, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).getCampaignHistory(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update campaign
     * @param {CampaignApiUpdateCampaignRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public updateCampaign(requestParameters: CampaignApiUpdateCampaignRequest, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).updateCampaign(requestParameters.id, requestParameters.campaign, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CollectionApi - axios parameter creator
 * @export
 */
export const CollectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check collection exists by loan id and statuses
         * @param {number} loanId 
         * @param {Array<string>} statuses 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkCollectionExists: async (loanId: number, statuses: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanId' is not null or undefined
            assertParamExists('checkCollectionExists', 'loanId', loanId)
            // verify required parameter 'statuses' is not null or undefined
            assertParamExists('checkCollectionExists', 'statuses', statuses)
            const localVarPath = `/api/collections/exists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)

            if (loanId !== undefined) {
                localVarQueryParameter['loanId'] = loanId;
            }

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of collections
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterStatuses] Search by collection statuses
         * @param {string} [filterStartedAtFrom] Search by collection started at from
         * @param {string} [filterStartedAtTo] Search by collection started at to
         * @param {string} [filterSearch] Search by collection number; borrower\&#39;s full name, phone, email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllCollections: async (page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterStartedAtFrom?: string, filterStartedAtTo?: string, filterSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/collections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filterStatuses) {
                localVarQueryParameter['filter.statuses'] = filterStatuses;
            }

            if (filterStartedAtFrom !== undefined) {
                localVarQueryParameter['filter.startedAtFrom'] = filterStartedAtFrom;
            }

            if (filterStartedAtTo !== undefined) {
                localVarQueryParameter['filter.startedAtTo'] = filterStartedAtTo;
            }

            if (filterSearch !== undefined) {
                localVarQueryParameter['filter.search'] = filterSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return last collection by loan id and statuses
         * @param {number} loanId 
         * @param {Array<string>} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLast: async (loanId: number, status: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanId' is not null or undefined
            assertParamExists('findLast', 'loanId', loanId)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('findLast', 'status', status)
            const localVarPath = `/api/collections/last-collection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)

            if (loanId !== undefined) {
                localVarQueryParameter['loanId'] = loanId;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return single collection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollection: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getCollection', 'id', id)
            const localVarPath = `/api/collections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CollectionApi - functional programming interface
 * @export
 */
export const CollectionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CollectionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check collection exists by loan id and statuses
         * @param {number} loanId 
         * @param {Array<string>} statuses 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkCollectionExists(loanId: number, statuses: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkCollectionExists(loanId, statuses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return list of collections
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterStatuses] Search by collection statuses
         * @param {string} [filterStartedAtFrom] Search by collection started at from
         * @param {string} [filterStartedAtTo] Search by collection started at to
         * @param {string} [filterSearch] Search by collection number; borrower\&#39;s full name, phone, email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllCollections(page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterStartedAtFrom?: string, filterStartedAtTo?: string, filterSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageCollectionBrief>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllCollections(page, size, sort, filterStatuses, filterStartedAtFrom, filterStartedAtTo, filterSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return last collection by loan id and statuses
         * @param {number} loanId 
         * @param {Array<string>} status 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findLast(loanId: number, status: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Collection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findLast(loanId, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return single collection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCollection(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Collection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCollection(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CollectionApi - factory interface
 * @export
 */
export const CollectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CollectionApiFp(configuration)
    return {
        /**
         * 
         * @summary Check collection exists by loan id and statuses
         * @param {CollectionApiCheckCollectionExistsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkCollectionExists(requestParameters: CollectionApiCheckCollectionExistsRequest, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.checkCollectionExists(requestParameters.loanId, requestParameters.statuses, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of collections
         * @param {CollectionApiFindAllCollectionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllCollections(requestParameters: CollectionApiFindAllCollectionsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageCollectionBrief> {
            return localVarFp.findAllCollections(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.filterStatuses, requestParameters.filterStartedAtFrom, requestParameters.filterStartedAtTo, requestParameters.filterSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return last collection by loan id and statuses
         * @param {CollectionApiFindLastRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findLast(requestParameters: CollectionApiFindLastRequest, options?: AxiosRequestConfig): AxiosPromise<Collection> {
            return localVarFp.findLast(requestParameters.loanId, requestParameters.status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return single collection
         * @param {CollectionApiGetCollectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCollection(requestParameters: CollectionApiGetCollectionRequest, options?: AxiosRequestConfig): AxiosPromise<Collection> {
            return localVarFp.getCollection(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for checkCollectionExists operation in CollectionApi.
 * @export
 * @interface CollectionApiCheckCollectionExistsRequest
 */
export interface CollectionApiCheckCollectionExistsRequest {
    /**
     * 
     * @type {number}
     * @memberof CollectionApiCheckCollectionExists
     */
    readonly loanId: number

    /**
     * 
     * @type {Array<string>}
     * @memberof CollectionApiCheckCollectionExists
     */
    readonly statuses: Array<string>
}

/**
 * Request parameters for findAllCollections operation in CollectionApi.
 * @export
 * @interface CollectionApiFindAllCollectionsRequest
 */
export interface CollectionApiFindAllCollectionsRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof CollectionApiFindAllCollections
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof CollectionApiFindAllCollections
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof CollectionApiFindAllCollections
     */
    readonly sort?: Array<string>

    /**
     * Search by collection statuses
     * @type {Array<string>}
     * @memberof CollectionApiFindAllCollections
     */
    readonly filterStatuses?: Array<string>

    /**
     * Search by collection started at from
     * @type {string}
     * @memberof CollectionApiFindAllCollections
     */
    readonly filterStartedAtFrom?: string

    /**
     * Search by collection started at to
     * @type {string}
     * @memberof CollectionApiFindAllCollections
     */
    readonly filterStartedAtTo?: string

    /**
     * Search by collection number; borrower\&#39;s full name, phone, email
     * @type {string}
     * @memberof CollectionApiFindAllCollections
     */
    readonly filterSearch?: string
}

/**
 * Request parameters for findLast operation in CollectionApi.
 * @export
 * @interface CollectionApiFindLastRequest
 */
export interface CollectionApiFindLastRequest {
    /**
     * 
     * @type {number}
     * @memberof CollectionApiFindLast
     */
    readonly loanId: number

    /**
     * 
     * @type {Array<string>}
     * @memberof CollectionApiFindLast
     */
    readonly status: Array<string>
}

/**
 * Request parameters for getCollection operation in CollectionApi.
 * @export
 * @interface CollectionApiGetCollectionRequest
 */
export interface CollectionApiGetCollectionRequest {
    /**
     * 
     * @type {number}
     * @memberof CollectionApiGetCollection
     */
    readonly id: number
}

/**
 * CollectionApi - object-oriented interface
 * @export
 * @class CollectionApi
 * @extends {BaseAPI}
 */
export class CollectionApi extends BaseAPI {
    /**
     * 
     * @summary Check collection exists by loan id and statuses
     * @param {CollectionApiCheckCollectionExistsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApi
     */
    public checkCollectionExists(requestParameters: CollectionApiCheckCollectionExistsRequest, options?: AxiosRequestConfig) {
        return CollectionApiFp(this.configuration).checkCollectionExists(requestParameters.loanId, requestParameters.statuses, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of collections
     * @param {CollectionApiFindAllCollectionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApi
     */
    public findAllCollections(requestParameters: CollectionApiFindAllCollectionsRequest = {}, options?: AxiosRequestConfig) {
        return CollectionApiFp(this.configuration).findAllCollections(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.filterStatuses, requestParameters.filterStartedAtFrom, requestParameters.filterStartedAtTo, requestParameters.filterSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return last collection by loan id and statuses
     * @param {CollectionApiFindLastRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApi
     */
    public findLast(requestParameters: CollectionApiFindLastRequest, options?: AxiosRequestConfig) {
        return CollectionApiFp(this.configuration).findLast(requestParameters.loanId, requestParameters.status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return single collection
     * @param {CollectionApiGetCollectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CollectionApi
     */
    public getCollection(requestParameters: CollectionApiGetCollectionRequest, options?: AxiosRequestConfig) {
        return CollectionApiFp(this.configuration).getCollection(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DocumentTemplatesApi - axios parameter creator
 * @export
 */
export const DocumentTemplatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of available templates in requested folder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllTemplates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/document-templates/**`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentTemplatesApi - functional programming interface
 * @export
 */
export const DocumentTemplatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentTemplatesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Return list of available templates in requested folder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllTemplates(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TemplateDescription>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllTemplates(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentTemplatesApi - factory interface
 * @export
 */
export const DocumentTemplatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentTemplatesApiFp(configuration)
    return {
        /**
         * 
         * @summary Return list of available templates in requested folder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllTemplates(options?: AxiosRequestConfig): AxiosPromise<Array<TemplateDescription>> {
            return localVarFp.findAllTemplates(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentTemplatesApi - object-oriented interface
 * @export
 * @class DocumentTemplatesApi
 * @extends {BaseAPI}
 */
export class DocumentTemplatesApi extends BaseAPI {
    /**
     * 
     * @summary Return list of available templates in requested folder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentTemplatesApi
     */
    public findAllTemplates(options?: AxiosRequestConfig) {
        return DocumentTemplatesApiFp(this.configuration).findAllTemplates(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FileStorageApi - axios parameter creator
 * @export
 */
export const FileStorageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a file from configured storage
         * @summary File download
         * @param {string} form The file name to retrieve
         * @param {boolean} [asAttachment] The download file content disposition. True - \&#39;attachment\&#39;, false - \&#39;inline\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download: async (form: string, asAttachment?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'form' is not null or undefined
            assertParamExists('download', 'form', form)
            const localVarPath = `/api/file/**`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)

            if (form !== undefined) {
                localVarQueryParameter['form'] = form;
            }

            if (asAttachment !== undefined) {
                localVarQueryParameter['asAttachment'] = asAttachment;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a file and stores it in configured storage
         * @summary File upload
         * @param {string} dir The directory to store file into
         * @param {string} name The file name
         * @param {File} file The file content to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upload: async (dir: string, name: string, file: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dir' is not null or undefined
            assertParamExists('upload', 'dir', dir)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('upload', 'name', name)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('upload', 'file', file)
            const localVarPath = `/api/file/**`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)

            if (dir !== undefined) {
                localVarQueryParameter['dir'] = dir;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileStorageApi - functional programming interface
 * @export
 */
export const FileStorageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileStorageApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves a file from configured storage
         * @summary File download
         * @param {string} form The file name to retrieve
         * @param {boolean} [asAttachment] The download file content disposition. True - \&#39;attachment\&#39;, false - \&#39;inline\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async download(form: string, asAttachment?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.download(form, asAttachment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a file and stores it in configured storage
         * @summary File upload
         * @param {string} dir The directory to store file into
         * @param {string} name The file name
         * @param {File} file The file content to store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upload(dir: string, name: string, file: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUploadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upload(dir, name, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileStorageApi - factory interface
 * @export
 */
export const FileStorageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileStorageApiFp(configuration)
    return {
        /**
         * Retrieves a file from configured storage
         * @summary File download
         * @param {FileStorageApiDownloadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download(requestParameters: FileStorageApiDownloadRequest, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.download(requestParameters.form, requestParameters.asAttachment, options).then((request) => request(axios, basePath));
        },
        /**
         * Receives a file and stores it in configured storage
         * @summary File upload
         * @param {FileStorageApiUploadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upload(requestParameters: FileStorageApiUploadRequest, options?: AxiosRequestConfig): AxiosPromise<FileUploadResponse> {
            return localVarFp.upload(requestParameters.dir, requestParameters.name, requestParameters.file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for download operation in FileStorageApi.
 * @export
 * @interface FileStorageApiDownloadRequest
 */
export interface FileStorageApiDownloadRequest {
    /**
     * The file name to retrieve
     * @type {string}
     * @memberof FileStorageApiDownload
     */
    readonly form: string

    /**
     * The download file content disposition. True - \&#39;attachment\&#39;, false - \&#39;inline\&#39;
     * @type {boolean}
     * @memberof FileStorageApiDownload
     */
    readonly asAttachment?: boolean
}

/**
 * Request parameters for upload operation in FileStorageApi.
 * @export
 * @interface FileStorageApiUploadRequest
 */
export interface FileStorageApiUploadRequest {
    /**
     * The directory to store file into
     * @type {string}
     * @memberof FileStorageApiUpload
     */
    readonly dir: string

    /**
     * The file name
     * @type {string}
     * @memberof FileStorageApiUpload
     */
    readonly name: string

    /**
     * The file content to store
     * @type {File}
     * @memberof FileStorageApiUpload
     */
    readonly file: File
}

/**
 * FileStorageApi - object-oriented interface
 * @export
 * @class FileStorageApi
 * @extends {BaseAPI}
 */
export class FileStorageApi extends BaseAPI {
    /**
     * Retrieves a file from configured storage
     * @summary File download
     * @param {FileStorageApiDownloadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStorageApi
     */
    public download(requestParameters: FileStorageApiDownloadRequest, options?: AxiosRequestConfig) {
        return FileStorageApiFp(this.configuration).download(requestParameters.form, requestParameters.asAttachment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Receives a file and stores it in configured storage
     * @summary File upload
     * @param {FileStorageApiUploadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileStorageApi
     */
    public upload(requestParameters: FileStorageApiUploadRequest, options?: AxiosRequestConfig) {
        return FileStorageApiFp(this.configuration).upload(requestParameters.dir, requestParameters.name, requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * IndividualApi - axios parameter creator
 * @export
 */
export const IndividualApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of individuals
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [filterSearch] Search by individual\&#39;s full name, phone, email
         * @param {boolean} [filterMobileVerified] Search by individual with verified mobile
         * @param {string} [filterFullName] Search by individual\&#39;s full name
         * @param {Array<number>} [filterExcludedIds] Search excluding individual IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllIndividuals: async (page?: number, size?: number, sort?: Array<string>, filterSearch?: string, filterMobileVerified?: boolean, filterFullName?: string, filterExcludedIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/individuals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filterSearch !== undefined) {
                localVarQueryParameter['filter.search'] = filterSearch;
            }

            if (filterMobileVerified !== undefined) {
                localVarQueryParameter['filter.mobileVerified'] = filterMobileVerified;
            }

            if (filterFullName !== undefined) {
                localVarQueryParameter['filter.fullName'] = filterFullName;
            }

            if (filterExcludedIds) {
                localVarQueryParameter['filter.excludedIds'] = filterExcludedIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return single individual
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndividual: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getIndividual', 'id', id)
            const localVarPath = `/api/individuals/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IndividualApi - functional programming interface
 * @export
 */
export const IndividualApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IndividualApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Return list of individuals
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [filterSearch] Search by individual\&#39;s full name, phone, email
         * @param {boolean} [filterMobileVerified] Search by individual with verified mobile
         * @param {string} [filterFullName] Search by individual\&#39;s full name
         * @param {Array<number>} [filterExcludedIds] Search excluding individual IDs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllIndividuals(page?: number, size?: number, sort?: Array<string>, filterSearch?: string, filterMobileVerified?: boolean, filterFullName?: string, filterExcludedIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageIndividualBrief>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllIndividuals(page, size, sort, filterSearch, filterMobileVerified, filterFullName, filterExcludedIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return single individual
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIndividual(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Individual>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIndividual(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IndividualApi - factory interface
 * @export
 */
export const IndividualApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IndividualApiFp(configuration)
    return {
        /**
         * 
         * @summary Return list of individuals
         * @param {IndividualApiFindAllIndividualsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllIndividuals(requestParameters: IndividualApiFindAllIndividualsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageIndividualBrief> {
            return localVarFp.findAllIndividuals(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.filterSearch, requestParameters.filterMobileVerified, requestParameters.filterFullName, requestParameters.filterExcludedIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return single individual
         * @param {IndividualApiGetIndividualRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndividual(requestParameters: IndividualApiGetIndividualRequest, options?: AxiosRequestConfig): AxiosPromise<Individual> {
            return localVarFp.getIndividual(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for findAllIndividuals operation in IndividualApi.
 * @export
 * @interface IndividualApiFindAllIndividualsRequest
 */
export interface IndividualApiFindAllIndividualsRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof IndividualApiFindAllIndividuals
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof IndividualApiFindAllIndividuals
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof IndividualApiFindAllIndividuals
     */
    readonly sort?: Array<string>

    /**
     * Search by individual\&#39;s full name, phone, email
     * @type {string}
     * @memberof IndividualApiFindAllIndividuals
     */
    readonly filterSearch?: string

    /**
     * Search by individual with verified mobile
     * @type {boolean}
     * @memberof IndividualApiFindAllIndividuals
     */
    readonly filterMobileVerified?: boolean

    /**
     * Search by individual\&#39;s full name
     * @type {string}
     * @memberof IndividualApiFindAllIndividuals
     */
    readonly filterFullName?: string

    /**
     * Search excluding individual IDs
     * @type {Array<number>}
     * @memberof IndividualApiFindAllIndividuals
     */
    readonly filterExcludedIds?: Array<number>
}

/**
 * Request parameters for getIndividual operation in IndividualApi.
 * @export
 * @interface IndividualApiGetIndividualRequest
 */
export interface IndividualApiGetIndividualRequest {
    /**
     * 
     * @type {number}
     * @memberof IndividualApiGetIndividual
     */
    readonly id: number
}

/**
 * IndividualApi - object-oriented interface
 * @export
 * @class IndividualApi
 * @extends {BaseAPI}
 */
export class IndividualApi extends BaseAPI {
    /**
     * 
     * @summary Return list of individuals
     * @param {IndividualApiFindAllIndividualsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualApi
     */
    public findAllIndividuals(requestParameters: IndividualApiFindAllIndividualsRequest = {}, options?: AxiosRequestConfig) {
        return IndividualApiFp(this.configuration).findAllIndividuals(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.filterSearch, requestParameters.filterMobileVerified, requestParameters.filterFullName, requestParameters.filterExcludedIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return single individual
     * @param {IndividualApiGetIndividualRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndividualApi
     */
    public getIndividual(requestParameters: IndividualApiGetIndividualRequest, options?: AxiosRequestConfig) {
        return IndividualApiFp(this.configuration).getIndividual(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LegalEntityApi - axios parameter creator
 * @export
 */
export const LegalEntityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create legal entity
         * @param {LegalEntity} legalEntity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLegalEntity: async (legalEntity: LegalEntity, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'legalEntity' is not null or undefined
            assertParamExists('createLegalEntity', 'legalEntity', legalEntity)
            const localVarPath = `/api/legal-entities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(legalEntity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of legal entities
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {boolean} [filterBlacklisted] Search by legal entity\&#39;s blacklisted flag
         * @param {string} [filterFullName] Search by legal entity\&#39;s full name
         * @param {Array<number>} [filterExcludedIds] Search excluding legal entity IDs
         * @param {string} [filterSearch] Search by legal entity\&#39;s full name, phone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllLegalEntities: async (page?: number, size?: number, sort?: Array<string>, filterBlacklisted?: boolean, filterFullName?: string, filterExcludedIds?: Array<number>, filterSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legal-entities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filterBlacklisted !== undefined) {
                localVarQueryParameter['filter.blacklisted'] = filterBlacklisted;
            }

            if (filterFullName !== undefined) {
                localVarQueryParameter['filter.fullName'] = filterFullName;
            }

            if (filterExcludedIds) {
                localVarQueryParameter['filter.excludedIds'] = filterExcludedIds;
            }

            if (filterSearch !== undefined) {
                localVarQueryParameter['filter.search'] = filterSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return legal entity\'s black list record if exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBlacklistEntry: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findBlacklistEntry', 'id', id)
            const localVarPath = `/api/legal-entities/{id}/blacklist-entry`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return legal entity\'s owners
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOwnersById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOwnersById', 'id', id)
            const localVarPath = `/api/legal-entities/{id}/owners`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return legal entity\'s POS terminals
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPosTerminalsById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findPosTerminalsById', 'id', id)
            const localVarPath = `/api/legal-entities/{id}/pos-terminals`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return single legal entity
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalEntity: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLegalEntity', 'id', id)
            const localVarPath = `/api/legal-entities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return legal entity\'s history
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalEntityHistory: async (id: number, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLegalEntityHistory', 'id', id)
            const localVarPath = `/api/legal-entities/{id}/history`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if borrower has loan applications with appropriate statuses
         * @param {number} id 
         * @param {Array<string>} statuses 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasBorrowerLoanApplicationsWithAppropriateStatuses: async (id: number, statuses: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('hasBorrowerLoanApplicationsWithAppropriateStatuses', 'id', id)
            // verify required parameter 'statuses' is not null or undefined
            assertParamExists('hasBorrowerLoanApplicationsWithAppropriateStatuses', 'statuses', statuses)
            const localVarPath = `/api/legal-entities/{id}/check-loan-applications-status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)

            if (statuses) {
                localVarQueryParameter['statuses'] = statuses;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update legal entity
         * @param {number} id 
         * @param {LegalEntity} legalEntity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLegalEntity: async (id: number, legalEntity: LegalEntity, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateLegalEntity', 'id', id)
            // verify required parameter 'legalEntity' is not null or undefined
            assertParamExists('updateLegalEntity', 'legalEntity', legalEntity)
            const localVarPath = `/api/legal-entities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(legalEntity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LegalEntityApi - functional programming interface
 * @export
 */
export const LegalEntityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LegalEntityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create legal entity
         * @param {LegalEntity} legalEntity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLegalEntity(legalEntity: LegalEntity, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLegalEntity(legalEntity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return list of legal entities
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {boolean} [filterBlacklisted] Search by legal entity\&#39;s blacklisted flag
         * @param {string} [filterFullName] Search by legal entity\&#39;s full name
         * @param {Array<number>} [filterExcludedIds] Search excluding legal entity IDs
         * @param {string} [filterSearch] Search by legal entity\&#39;s full name, phone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllLegalEntities(page?: number, size?: number, sort?: Array<string>, filterBlacklisted?: boolean, filterFullName?: string, filterExcludedIds?: Array<number>, filterSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLegalEntityBrief>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllLegalEntities(page, size, sort, filterBlacklisted, filterFullName, filterExcludedIds, filterSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return legal entity\'s black list record if exists
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findBlacklistEntry(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BlacklistEntry>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findBlacklistEntry(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return legal entity\'s owners
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOwnersById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Owner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOwnersById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return legal entity\'s POS terminals
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findPosTerminalsById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Pos>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findPosTerminalsById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return single legal entity
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLegalEntity(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLegalEntity(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return legal entity\'s history
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLegalEntityHistory(id: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageRevisionIntegerLegalEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLegalEntityHistory(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check if borrower has loan applications with appropriate statuses
         * @param {number} id 
         * @param {Array<string>} statuses 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hasBorrowerLoanApplicationsWithAppropriateStatuses(id: number, statuses: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hasBorrowerLoanApplicationsWithAppropriateStatuses(id, statuses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update legal entity
         * @param {number} id 
         * @param {LegalEntity} legalEntity 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLegalEntity(id: number, legalEntity: LegalEntity, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LegalEntity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLegalEntity(id, legalEntity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LegalEntityApi - factory interface
 * @export
 */
export const LegalEntityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LegalEntityApiFp(configuration)
    return {
        /**
         * 
         * @summary Create legal entity
         * @param {LegalEntityApiCreateLegalEntityRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLegalEntity(requestParameters: LegalEntityApiCreateLegalEntityRequest, options?: AxiosRequestConfig): AxiosPromise<LegalEntity> {
            return localVarFp.createLegalEntity(requestParameters.legalEntity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of legal entities
         * @param {LegalEntityApiFindAllLegalEntitiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllLegalEntities(requestParameters: LegalEntityApiFindAllLegalEntitiesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageLegalEntityBrief> {
            return localVarFp.findAllLegalEntities(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.filterBlacklisted, requestParameters.filterFullName, requestParameters.filterExcludedIds, requestParameters.filterSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return legal entity\'s black list record if exists
         * @param {LegalEntityApiFindBlacklistEntryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findBlacklistEntry(requestParameters: LegalEntityApiFindBlacklistEntryRequest, options?: AxiosRequestConfig): AxiosPromise<BlacklistEntry> {
            return localVarFp.findBlacklistEntry(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return legal entity\'s owners
         * @param {LegalEntityApiFindOwnersByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOwnersById(requestParameters: LegalEntityApiFindOwnersByIdRequest, options?: AxiosRequestConfig): AxiosPromise<Array<Owner>> {
            return localVarFp.findOwnersById(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return legal entity\'s POS terminals
         * @param {LegalEntityApiFindPosTerminalsByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findPosTerminalsById(requestParameters: LegalEntityApiFindPosTerminalsByIdRequest, options?: AxiosRequestConfig): AxiosPromise<Array<Pos>> {
            return localVarFp.findPosTerminalsById(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return single legal entity
         * @param {LegalEntityApiGetLegalEntityRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalEntity(requestParameters: LegalEntityApiGetLegalEntityRequest, options?: AxiosRequestConfig): AxiosPromise<LegalEntity> {
            return localVarFp.getLegalEntity(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return legal entity\'s history
         * @param {LegalEntityApiGetLegalEntityHistoryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLegalEntityHistory(requestParameters: LegalEntityApiGetLegalEntityHistoryRequest, options?: AxiosRequestConfig): AxiosPromise<PageRevisionIntegerLegalEntity> {
            return localVarFp.getLegalEntityHistory(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if borrower has loan applications with appropriate statuses
         * @param {LegalEntityApiHasBorrowerLoanApplicationsWithAppropriateStatusesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasBorrowerLoanApplicationsWithAppropriateStatuses(requestParameters: LegalEntityApiHasBorrowerLoanApplicationsWithAppropriateStatusesRequest, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.hasBorrowerLoanApplicationsWithAppropriateStatuses(requestParameters.id, requestParameters.statuses, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update legal entity
         * @param {LegalEntityApiUpdateLegalEntityRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLegalEntity(requestParameters: LegalEntityApiUpdateLegalEntityRequest, options?: AxiosRequestConfig): AxiosPromise<LegalEntity> {
            return localVarFp.updateLegalEntity(requestParameters.id, requestParameters.legalEntity, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createLegalEntity operation in LegalEntityApi.
 * @export
 * @interface LegalEntityApiCreateLegalEntityRequest
 */
export interface LegalEntityApiCreateLegalEntityRequest {
    /**
     * 
     * @type {LegalEntity}
     * @memberof LegalEntityApiCreateLegalEntity
     */
    readonly legalEntity: LegalEntity
}

/**
 * Request parameters for findAllLegalEntities operation in LegalEntityApi.
 * @export
 * @interface LegalEntityApiFindAllLegalEntitiesRequest
 */
export interface LegalEntityApiFindAllLegalEntitiesRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof LegalEntityApiFindAllLegalEntities
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof LegalEntityApiFindAllLegalEntities
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof LegalEntityApiFindAllLegalEntities
     */
    readonly sort?: Array<string>

    /**
     * Search by legal entity\&#39;s blacklisted flag
     * @type {boolean}
     * @memberof LegalEntityApiFindAllLegalEntities
     */
    readonly filterBlacklisted?: boolean

    /**
     * Search by legal entity\&#39;s full name
     * @type {string}
     * @memberof LegalEntityApiFindAllLegalEntities
     */
    readonly filterFullName?: string

    /**
     * Search excluding legal entity IDs
     * @type {Array<number>}
     * @memberof LegalEntityApiFindAllLegalEntities
     */
    readonly filterExcludedIds?: Array<number>

    /**
     * Search by legal entity\&#39;s full name, phone
     * @type {string}
     * @memberof LegalEntityApiFindAllLegalEntities
     */
    readonly filterSearch?: string
}

/**
 * Request parameters for findBlacklistEntry operation in LegalEntityApi.
 * @export
 * @interface LegalEntityApiFindBlacklistEntryRequest
 */
export interface LegalEntityApiFindBlacklistEntryRequest {
    /**
     * 
     * @type {string}
     * @memberof LegalEntityApiFindBlacklistEntry
     */
    readonly id: string
}

/**
 * Request parameters for findOwnersById operation in LegalEntityApi.
 * @export
 * @interface LegalEntityApiFindOwnersByIdRequest
 */
export interface LegalEntityApiFindOwnersByIdRequest {
    /**
     * 
     * @type {number}
     * @memberof LegalEntityApiFindOwnersById
     */
    readonly id: number
}

/**
 * Request parameters for findPosTerminalsById operation in LegalEntityApi.
 * @export
 * @interface LegalEntityApiFindPosTerminalsByIdRequest
 */
export interface LegalEntityApiFindPosTerminalsByIdRequest {
    /**
     * 
     * @type {number}
     * @memberof LegalEntityApiFindPosTerminalsById
     */
    readonly id: number
}

/**
 * Request parameters for getLegalEntity operation in LegalEntityApi.
 * @export
 * @interface LegalEntityApiGetLegalEntityRequest
 */
export interface LegalEntityApiGetLegalEntityRequest {
    /**
     * 
     * @type {number}
     * @memberof LegalEntityApiGetLegalEntity
     */
    readonly id: number
}

/**
 * Request parameters for getLegalEntityHistory operation in LegalEntityApi.
 * @export
 * @interface LegalEntityApiGetLegalEntityHistoryRequest
 */
export interface LegalEntityApiGetLegalEntityHistoryRequest {
    /**
     * 
     * @type {number}
     * @memberof LegalEntityApiGetLegalEntityHistory
     */
    readonly id: number

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof LegalEntityApiGetLegalEntityHistory
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof LegalEntityApiGetLegalEntityHistory
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof LegalEntityApiGetLegalEntityHistory
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for hasBorrowerLoanApplicationsWithAppropriateStatuses operation in LegalEntityApi.
 * @export
 * @interface LegalEntityApiHasBorrowerLoanApplicationsWithAppropriateStatusesRequest
 */
export interface LegalEntityApiHasBorrowerLoanApplicationsWithAppropriateStatusesRequest {
    /**
     * 
     * @type {number}
     * @memberof LegalEntityApiHasBorrowerLoanApplicationsWithAppropriateStatuses
     */
    readonly id: number

    /**
     * 
     * @type {Array<string>}
     * @memberof LegalEntityApiHasBorrowerLoanApplicationsWithAppropriateStatuses
     */
    readonly statuses: Array<string>
}

/**
 * Request parameters for updateLegalEntity operation in LegalEntityApi.
 * @export
 * @interface LegalEntityApiUpdateLegalEntityRequest
 */
export interface LegalEntityApiUpdateLegalEntityRequest {
    /**
     * 
     * @type {number}
     * @memberof LegalEntityApiUpdateLegalEntity
     */
    readonly id: number

    /**
     * 
     * @type {LegalEntity}
     * @memberof LegalEntityApiUpdateLegalEntity
     */
    readonly legalEntity: LegalEntity
}

/**
 * LegalEntityApi - object-oriented interface
 * @export
 * @class LegalEntityApi
 * @extends {BaseAPI}
 */
export class LegalEntityApi extends BaseAPI {
    /**
     * 
     * @summary Create legal entity
     * @param {LegalEntityApiCreateLegalEntityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalEntityApi
     */
    public createLegalEntity(requestParameters: LegalEntityApiCreateLegalEntityRequest, options?: AxiosRequestConfig) {
        return LegalEntityApiFp(this.configuration).createLegalEntity(requestParameters.legalEntity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of legal entities
     * @param {LegalEntityApiFindAllLegalEntitiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalEntityApi
     */
    public findAllLegalEntities(requestParameters: LegalEntityApiFindAllLegalEntitiesRequest = {}, options?: AxiosRequestConfig) {
        return LegalEntityApiFp(this.configuration).findAllLegalEntities(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.filterBlacklisted, requestParameters.filterFullName, requestParameters.filterExcludedIds, requestParameters.filterSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return legal entity\'s black list record if exists
     * @param {LegalEntityApiFindBlacklistEntryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalEntityApi
     */
    public findBlacklistEntry(requestParameters: LegalEntityApiFindBlacklistEntryRequest, options?: AxiosRequestConfig) {
        return LegalEntityApiFp(this.configuration).findBlacklistEntry(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return legal entity\'s owners
     * @param {LegalEntityApiFindOwnersByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalEntityApi
     */
    public findOwnersById(requestParameters: LegalEntityApiFindOwnersByIdRequest, options?: AxiosRequestConfig) {
        return LegalEntityApiFp(this.configuration).findOwnersById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return legal entity\'s POS terminals
     * @param {LegalEntityApiFindPosTerminalsByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalEntityApi
     */
    public findPosTerminalsById(requestParameters: LegalEntityApiFindPosTerminalsByIdRequest, options?: AxiosRequestConfig) {
        return LegalEntityApiFp(this.configuration).findPosTerminalsById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return single legal entity
     * @param {LegalEntityApiGetLegalEntityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalEntityApi
     */
    public getLegalEntity(requestParameters: LegalEntityApiGetLegalEntityRequest, options?: AxiosRequestConfig) {
        return LegalEntityApiFp(this.configuration).getLegalEntity(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return legal entity\'s history
     * @param {LegalEntityApiGetLegalEntityHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalEntityApi
     */
    public getLegalEntityHistory(requestParameters: LegalEntityApiGetLegalEntityHistoryRequest, options?: AxiosRequestConfig) {
        return LegalEntityApiFp(this.configuration).getLegalEntityHistory(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if borrower has loan applications with appropriate statuses
     * @param {LegalEntityApiHasBorrowerLoanApplicationsWithAppropriateStatusesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalEntityApi
     */
    public hasBorrowerLoanApplicationsWithAppropriateStatuses(requestParameters: LegalEntityApiHasBorrowerLoanApplicationsWithAppropriateStatusesRequest, options?: AxiosRequestConfig) {
        return LegalEntityApiFp(this.configuration).hasBorrowerLoanApplicationsWithAppropriateStatuses(requestParameters.id, requestParameters.statuses, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update legal entity
     * @param {LegalEntityApiUpdateLegalEntityRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalEntityApi
     */
    public updateLegalEntity(requestParameters: LegalEntityApiUpdateLegalEntityRequest, options?: AxiosRequestConfig) {
        return LegalEntityApiFp(this.configuration).updateLegalEntity(requestParameters.id, requestParameters.legalEntity, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LoanApi - axios parameter creator
 * @export
 */
export const LoanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of borrower\'s loans
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByBorrowerId: async (id: number, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findAllByBorrowerId', 'id', id)
            const localVarPath = `/api/loans/borrower/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of loans
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterStatuses] Search by loan statuses
         * @param {string} [filterActivatedAtFrom] Search by loan activated from
         * @param {string} [filterActivatedAtTo] Search by loan activated to
         * @param {string} [filterSearch] Search by loan number; borrower\&#39;s full name, phone, email; loan product code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllLoans: async (page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterActivatedAtFrom?: string, filterActivatedAtTo?: string, filterSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/loans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filterStatuses) {
                localVarQueryParameter['filter.statuses'] = filterStatuses;
            }

            if (filterActivatedAtFrom !== undefined) {
                localVarQueryParameter['filter.activatedAtFrom'] = filterActivatedAtFrom;
            }

            if (filterActivatedAtTo !== undefined) {
                localVarQueryParameter['filter.activatedAtTo'] = filterActivatedAtTo;
            }

            if (filterSearch !== undefined) {
                localVarQueryParameter['filter.search'] = filterSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of loan\'s operations
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllOperationsByLoanId: async (id: number, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findAllOperationsByLoanId', 'id', id)
            const localVarPath = `/api/loans/{id}/operations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of borrower\'s loans available for refinancing
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {boolean} [filterActive] 
         * @param {string} [filterType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllRefinancedLoansByBorrowerId: async (id: number, page?: number, size?: number, sort?: Array<string>, filterActive?: boolean, filterType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findAllRefinancedLoansByBorrowerId', 'id', id)
            const localVarPath = `/api/loans/refinancing/borrower/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filterActive !== undefined) {
                localVarQueryParameter['filter.active'] = filterActive;
            }

            if (filterType !== undefined) {
                localVarQueryParameter['filter.type'] = filterType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of loan\'s operations
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOperationById: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findOperationById', 'id', id)
            const localVarPath = `/api/loans/loan/operations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return single loan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoan: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLoan', 'id', id)
            const localVarPath = `/api/loans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoanApi - functional programming interface
 * @export
 */
export const LoanApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoanApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Return list of borrower\'s loans
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllByBorrowerId(id: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanBrief>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllByBorrowerId(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return list of loans
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterStatuses] Search by loan statuses
         * @param {string} [filterActivatedAtFrom] Search by loan activated from
         * @param {string} [filterActivatedAtTo] Search by loan activated to
         * @param {string} [filterSearch] Search by loan number; borrower\&#39;s full name, phone, email; loan product code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllLoans(page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterActivatedAtFrom?: string, filterActivatedAtTo?: string, filterSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanBrief>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllLoans(page, size, sort, filterStatuses, filterActivatedAtFrom, filterActivatedAtTo, filterSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return list of loan\'s operations
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllOperationsByLoanId(id: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanOperation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllOperationsByLoanId(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return list of borrower\'s loans available for refinancing
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {boolean} [filterActive] 
         * @param {string} [filterType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllRefinancedLoansByBorrowerId(id: number, page?: number, size?: number, sort?: Array<string>, filterActive?: boolean, filterType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllRefinancedLoansByBorrowerId(id, page, size, sort, filterActive, filterType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return list of loan\'s operations
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findOperationById(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanOperationRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findOperationById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return single loan
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoan(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Loan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoan(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoanApi - factory interface
 * @export
 */
export const LoanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoanApiFp(configuration)
    return {
        /**
         * 
         * @summary Return list of borrower\'s loans
         * @param {LoanApiFindAllByBorrowerIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByBorrowerId(requestParameters: LoanApiFindAllByBorrowerIdRequest, options?: AxiosRequestConfig): AxiosPromise<PageLoanBrief> {
            return localVarFp.findAllByBorrowerId(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of loans
         * @param {LoanApiFindAllLoansRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllLoans(requestParameters: LoanApiFindAllLoansRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageLoanBrief> {
            return localVarFp.findAllLoans(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.filterStatuses, requestParameters.filterActivatedAtFrom, requestParameters.filterActivatedAtTo, requestParameters.filterSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of loan\'s operations
         * @param {LoanApiFindAllOperationsByLoanIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllOperationsByLoanId(requestParameters: LoanApiFindAllOperationsByLoanIdRequest, options?: AxiosRequestConfig): AxiosPromise<PageLoanOperation> {
            return localVarFp.findAllOperationsByLoanId(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of borrower\'s loans available for refinancing
         * @param {LoanApiFindAllRefinancedLoansByBorrowerIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllRefinancedLoansByBorrowerId(requestParameters: LoanApiFindAllRefinancedLoansByBorrowerIdRequest, options?: AxiosRequestConfig): AxiosPromise<PageLoan> {
            return localVarFp.findAllRefinancedLoansByBorrowerId(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.filterActive, requestParameters.filterType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of loan\'s operations
         * @param {LoanApiFindOperationByIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findOperationById(requestParameters: LoanApiFindOperationByIdRequest, options?: AxiosRequestConfig): AxiosPromise<LoanOperationRepresentation> {
            return localVarFp.findOperationById(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return single loan
         * @param {LoanApiGetLoanRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoan(requestParameters: LoanApiGetLoanRequest, options?: AxiosRequestConfig): AxiosPromise<Loan> {
            return localVarFp.getLoan(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for findAllByBorrowerId operation in LoanApi.
 * @export
 * @interface LoanApiFindAllByBorrowerIdRequest
 */
export interface LoanApiFindAllByBorrowerIdRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanApiFindAllByBorrowerId
     */
    readonly id: number

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof LoanApiFindAllByBorrowerId
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof LoanApiFindAllByBorrowerId
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof LoanApiFindAllByBorrowerId
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for findAllLoans operation in LoanApi.
 * @export
 * @interface LoanApiFindAllLoansRequest
 */
export interface LoanApiFindAllLoansRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof LoanApiFindAllLoans
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof LoanApiFindAllLoans
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof LoanApiFindAllLoans
     */
    readonly sort?: Array<string>

    /**
     * Search by loan statuses
     * @type {Array<string>}
     * @memberof LoanApiFindAllLoans
     */
    readonly filterStatuses?: Array<string>

    /**
     * Search by loan activated from
     * @type {string}
     * @memberof LoanApiFindAllLoans
     */
    readonly filterActivatedAtFrom?: string

    /**
     * Search by loan activated to
     * @type {string}
     * @memberof LoanApiFindAllLoans
     */
    readonly filterActivatedAtTo?: string

    /**
     * Search by loan number; borrower\&#39;s full name, phone, email; loan product code
     * @type {string}
     * @memberof LoanApiFindAllLoans
     */
    readonly filterSearch?: string
}

/**
 * Request parameters for findAllOperationsByLoanId operation in LoanApi.
 * @export
 * @interface LoanApiFindAllOperationsByLoanIdRequest
 */
export interface LoanApiFindAllOperationsByLoanIdRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanApiFindAllOperationsByLoanId
     */
    readonly id: number

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof LoanApiFindAllOperationsByLoanId
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof LoanApiFindAllOperationsByLoanId
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof LoanApiFindAllOperationsByLoanId
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for findAllRefinancedLoansByBorrowerId operation in LoanApi.
 * @export
 * @interface LoanApiFindAllRefinancedLoansByBorrowerIdRequest
 */
export interface LoanApiFindAllRefinancedLoansByBorrowerIdRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanApiFindAllRefinancedLoansByBorrowerId
     */
    readonly id: number

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof LoanApiFindAllRefinancedLoansByBorrowerId
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof LoanApiFindAllRefinancedLoansByBorrowerId
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof LoanApiFindAllRefinancedLoansByBorrowerId
     */
    readonly sort?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof LoanApiFindAllRefinancedLoansByBorrowerId
     */
    readonly filterActive?: boolean

    /**
     * 
     * @type {string}
     * @memberof LoanApiFindAllRefinancedLoansByBorrowerId
     */
    readonly filterType?: string
}

/**
 * Request parameters for findOperationById operation in LoanApi.
 * @export
 * @interface LoanApiFindOperationByIdRequest
 */
export interface LoanApiFindOperationByIdRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanApiFindOperationById
     */
    readonly id: number
}

/**
 * Request parameters for getLoan operation in LoanApi.
 * @export
 * @interface LoanApiGetLoanRequest
 */
export interface LoanApiGetLoanRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanApiGetLoan
     */
    readonly id: number
}

/**
 * LoanApi - object-oriented interface
 * @export
 * @class LoanApi
 * @extends {BaseAPI}
 */
export class LoanApi extends BaseAPI {
    /**
     * 
     * @summary Return list of borrower\'s loans
     * @param {LoanApiFindAllByBorrowerIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApi
     */
    public findAllByBorrowerId(requestParameters: LoanApiFindAllByBorrowerIdRequest, options?: AxiosRequestConfig) {
        return LoanApiFp(this.configuration).findAllByBorrowerId(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of loans
     * @param {LoanApiFindAllLoansRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApi
     */
    public findAllLoans(requestParameters: LoanApiFindAllLoansRequest = {}, options?: AxiosRequestConfig) {
        return LoanApiFp(this.configuration).findAllLoans(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.filterStatuses, requestParameters.filterActivatedAtFrom, requestParameters.filterActivatedAtTo, requestParameters.filterSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of loan\'s operations
     * @param {LoanApiFindAllOperationsByLoanIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApi
     */
    public findAllOperationsByLoanId(requestParameters: LoanApiFindAllOperationsByLoanIdRequest, options?: AxiosRequestConfig) {
        return LoanApiFp(this.configuration).findAllOperationsByLoanId(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of borrower\'s loans available for refinancing
     * @param {LoanApiFindAllRefinancedLoansByBorrowerIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApi
     */
    public findAllRefinancedLoansByBorrowerId(requestParameters: LoanApiFindAllRefinancedLoansByBorrowerIdRequest, options?: AxiosRequestConfig) {
        return LoanApiFp(this.configuration).findAllRefinancedLoansByBorrowerId(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.filterActive, requestParameters.filterType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of loan\'s operations
     * @param {LoanApiFindOperationByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApi
     */
    public findOperationById(requestParameters: LoanApiFindOperationByIdRequest, options?: AxiosRequestConfig) {
        return LoanApiFp(this.configuration).findOperationById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return single loan
     * @param {LoanApiGetLoanRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApi
     */
    public getLoan(requestParameters: LoanApiGetLoanRequest, options?: AxiosRequestConfig) {
        return LoanApiFp(this.configuration).getLoan(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LoanApplicationApi - axios parameter creator
 * @export
 */
export const LoanApplicationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of loan applications
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterStatuses] Search by loan application statuses
         * @param {string} [filterRequestedAtFrom] Search by loan application requested at (by a borrower) from
         * @param {string} [filterRequestedAtTo] Search by loan application requested at (by a borrower) to
         * @param {string} [filterSearch] Search by loan application number; borrower\&#39;s full name, phone, email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllApplications: async (page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterRequestedAtFrom?: string, filterRequestedAtTo?: string, filterSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/loan-applications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filterStatuses) {
                localVarQueryParameter['filter.statuses'] = filterStatuses;
            }

            if (filterRequestedAtFrom !== undefined) {
                localVarQueryParameter['filter.requestedAtFrom'] = filterRequestedAtFrom;
            }

            if (filterRequestedAtTo !== undefined) {
                localVarQueryParameter['filter.requestedAtTo'] = filterRequestedAtTo;
            }

            if (filterSearch !== undefined) {
                localVarQueryParameter['filter.search'] = filterSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of borrower\'s loan applications
         * @param {number} id Borrower id
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByBorrowerId1: async (id: number, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findAllByBorrowerId1', 'id', id)
            const localVarPath = `/api/loan-applications/borrower/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return loan application guarantors
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findGuarantorsByLoanApplicationId: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findGuarantorsByLoanApplicationId', 'id', id)
            const localVarPath = `/api/loan-applications/{id}/guarantors`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return single loan application
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplication: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getApplication', 'id', id)
            const localVarPath = `/api/loan-applications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return audit trail for the loan application
         * @param {string} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditTrail: async (id: string, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAuditTrail', 'id', id)
            const localVarPath = `/api/loan-applications/{id}/audit-trail`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return loan application\'s history
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoanApplicationHistory: async (id: number, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLoanApplicationHistory', 'id', id)
            const localVarPath = `/api/loan-applications/{id}/history`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoanApplicationApi - functional programming interface
 * @export
 */
export const LoanApplicationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoanApplicationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Return list of loan applications
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterStatuses] Search by loan application statuses
         * @param {string} [filterRequestedAtFrom] Search by loan application requested at (by a borrower) from
         * @param {string} [filterRequestedAtTo] Search by loan application requested at (by a borrower) to
         * @param {string} [filterSearch] Search by loan application number; borrower\&#39;s full name, phone, email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllApplications(page?: number, size?: number, sort?: Array<string>, filterStatuses?: Array<string>, filterRequestedAtFrom?: string, filterRequestedAtTo?: string, filterSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanApplicationBrief>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllApplications(page, size, sort, filterStatuses, filterRequestedAtFrom, filterRequestedAtTo, filterSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return list of borrower\'s loan applications
         * @param {number} id Borrower id
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllByBorrowerId1(id: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanApplicationBrief>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllByBorrowerId1(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return loan application guarantors
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findGuarantorsByLoanApplicationId(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Guarantor>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findGuarantorsByLoanApplicationId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return single loan application
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplication(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApplication(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return audit trail for the loan application
         * @param {string} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuditTrail(id: string, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageActivityAuditTrail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuditTrail(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return loan application\'s history
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoanApplicationHistory(id: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageRevisionIntegerLoanApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoanApplicationHistory(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoanApplicationApi - factory interface
 * @export
 */
export const LoanApplicationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoanApplicationApiFp(configuration)
    return {
        /**
         * 
         * @summary Return list of loan applications
         * @param {LoanApplicationApiFindAllApplicationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllApplications(requestParameters: LoanApplicationApiFindAllApplicationsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageLoanApplicationBrief> {
            return localVarFp.findAllApplications(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.filterStatuses, requestParameters.filterRequestedAtFrom, requestParameters.filterRequestedAtTo, requestParameters.filterSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of borrower\'s loan applications
         * @param {LoanApplicationApiFindAllByBorrowerId1Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByBorrowerId1(requestParameters: LoanApplicationApiFindAllByBorrowerId1Request, options?: AxiosRequestConfig): AxiosPromise<PageLoanApplicationBrief> {
            return localVarFp.findAllByBorrowerId1(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return loan application guarantors
         * @param {LoanApplicationApiFindGuarantorsByLoanApplicationIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findGuarantorsByLoanApplicationId(requestParameters: LoanApplicationApiFindGuarantorsByLoanApplicationIdRequest, options?: AxiosRequestConfig): AxiosPromise<Array<Guarantor>> {
            return localVarFp.findGuarantorsByLoanApplicationId(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return single loan application
         * @param {LoanApplicationApiGetApplicationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplication(requestParameters: LoanApplicationApiGetApplicationRequest, options?: AxiosRequestConfig): AxiosPromise<LoanApplication> {
            return localVarFp.getApplication(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return audit trail for the loan application
         * @param {LoanApplicationApiGetAuditTrailRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuditTrail(requestParameters: LoanApplicationApiGetAuditTrailRequest, options?: AxiosRequestConfig): AxiosPromise<PageActivityAuditTrail> {
            return localVarFp.getAuditTrail(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return loan application\'s history
         * @param {LoanApplicationApiGetLoanApplicationHistoryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoanApplicationHistory(requestParameters: LoanApplicationApiGetLoanApplicationHistoryRequest, options?: AxiosRequestConfig): AxiosPromise<PageRevisionIntegerLoanApplication> {
            return localVarFp.getLoanApplicationHistory(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for findAllApplications operation in LoanApplicationApi.
 * @export
 * @interface LoanApplicationApiFindAllApplicationsRequest
 */
export interface LoanApplicationApiFindAllApplicationsRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof LoanApplicationApiFindAllApplications
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof LoanApplicationApiFindAllApplications
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof LoanApplicationApiFindAllApplications
     */
    readonly sort?: Array<string>

    /**
     * Search by loan application statuses
     * @type {Array<string>}
     * @memberof LoanApplicationApiFindAllApplications
     */
    readonly filterStatuses?: Array<string>

    /**
     * Search by loan application requested at (by a borrower) from
     * @type {string}
     * @memberof LoanApplicationApiFindAllApplications
     */
    readonly filterRequestedAtFrom?: string

    /**
     * Search by loan application requested at (by a borrower) to
     * @type {string}
     * @memberof LoanApplicationApiFindAllApplications
     */
    readonly filterRequestedAtTo?: string

    /**
     * Search by loan application number; borrower\&#39;s full name, phone, email
     * @type {string}
     * @memberof LoanApplicationApiFindAllApplications
     */
    readonly filterSearch?: string
}

/**
 * Request parameters for findAllByBorrowerId1 operation in LoanApplicationApi.
 * @export
 * @interface LoanApplicationApiFindAllByBorrowerId1Request
 */
export interface LoanApplicationApiFindAllByBorrowerId1Request {
    /**
     * Borrower id
     * @type {number}
     * @memberof LoanApplicationApiFindAllByBorrowerId1
     */
    readonly id: number

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof LoanApplicationApiFindAllByBorrowerId1
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof LoanApplicationApiFindAllByBorrowerId1
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof LoanApplicationApiFindAllByBorrowerId1
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for findGuarantorsByLoanApplicationId operation in LoanApplicationApi.
 * @export
 * @interface LoanApplicationApiFindGuarantorsByLoanApplicationIdRequest
 */
export interface LoanApplicationApiFindGuarantorsByLoanApplicationIdRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationApiFindGuarantorsByLoanApplicationId
     */
    readonly id: number
}

/**
 * Request parameters for getApplication operation in LoanApplicationApi.
 * @export
 * @interface LoanApplicationApiGetApplicationRequest
 */
export interface LoanApplicationApiGetApplicationRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationApiGetApplication
     */
    readonly id: number
}

/**
 * Request parameters for getAuditTrail operation in LoanApplicationApi.
 * @export
 * @interface LoanApplicationApiGetAuditTrailRequest
 */
export interface LoanApplicationApiGetAuditTrailRequest {
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationApiGetAuditTrail
     */
    readonly id: string

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof LoanApplicationApiGetAuditTrail
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof LoanApplicationApiGetAuditTrail
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof LoanApplicationApiGetAuditTrail
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getLoanApplicationHistory operation in LoanApplicationApi.
 * @export
 * @interface LoanApplicationApiGetLoanApplicationHistoryRequest
 */
export interface LoanApplicationApiGetLoanApplicationHistoryRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationApiGetLoanApplicationHistory
     */
    readonly id: number

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof LoanApplicationApiGetLoanApplicationHistory
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof LoanApplicationApiGetLoanApplicationHistory
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof LoanApplicationApiGetLoanApplicationHistory
     */
    readonly sort?: Array<string>
}

/**
 * LoanApplicationApi - object-oriented interface
 * @export
 * @class LoanApplicationApi
 * @extends {BaseAPI}
 */
export class LoanApplicationApi extends BaseAPI {
    /**
     * 
     * @summary Return list of loan applications
     * @param {LoanApplicationApiFindAllApplicationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApplicationApi
     */
    public findAllApplications(requestParameters: LoanApplicationApiFindAllApplicationsRequest = {}, options?: AxiosRequestConfig) {
        return LoanApplicationApiFp(this.configuration).findAllApplications(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.filterStatuses, requestParameters.filterRequestedAtFrom, requestParameters.filterRequestedAtTo, requestParameters.filterSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of borrower\'s loan applications
     * @param {LoanApplicationApiFindAllByBorrowerId1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApplicationApi
     */
    public findAllByBorrowerId1(requestParameters: LoanApplicationApiFindAllByBorrowerId1Request, options?: AxiosRequestConfig) {
        return LoanApplicationApiFp(this.configuration).findAllByBorrowerId1(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return loan application guarantors
     * @param {LoanApplicationApiFindGuarantorsByLoanApplicationIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApplicationApi
     */
    public findGuarantorsByLoanApplicationId(requestParameters: LoanApplicationApiFindGuarantorsByLoanApplicationIdRequest, options?: AxiosRequestConfig) {
        return LoanApplicationApiFp(this.configuration).findGuarantorsByLoanApplicationId(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return single loan application
     * @param {LoanApplicationApiGetApplicationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApplicationApi
     */
    public getApplication(requestParameters: LoanApplicationApiGetApplicationRequest, options?: AxiosRequestConfig) {
        return LoanApplicationApiFp(this.configuration).getApplication(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return audit trail for the loan application
     * @param {LoanApplicationApiGetAuditTrailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApplicationApi
     */
    public getAuditTrail(requestParameters: LoanApplicationApiGetAuditTrailRequest, options?: AxiosRequestConfig) {
        return LoanApplicationApiFp(this.configuration).getAuditTrail(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return loan application\'s history
     * @param {LoanApplicationApiGetLoanApplicationHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApplicationApi
     */
    public getLoanApplicationHistory(requestParameters: LoanApplicationApiGetLoanApplicationHistoryRequest, options?: AxiosRequestConfig) {
        return LoanApplicationApiFp(this.configuration).getLoanApplicationHistory(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LoanProductApi - axios parameter creator
 * @export
 */
export const LoanProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create loan product
         * @param {LoanProduct} loanProduct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct: async (loanProduct: LoanProduct, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanProduct' is not null or undefined
            assertParamExists('createProduct', 'loanProduct', loanProduct)
            const localVarPath = `/api/loan-products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loanProduct, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of campaign\'s loan products
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByCampaignId: async (id: number, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findAllByCampaignId', 'id', id)
            const localVarPath = `/api/loan-products/campaign/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of loan products
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [filterAmount] Search by loan product amount
         * @param {number} [filterTerm] Search by loan product term
         * @param {number} [filterCampaignId] Search by loan campaign id
         * @param {string} [filterPaymentFrequency] Search by loan product payment frequency
         * @param {boolean} [filterActive] Search by loan product active flag
         * @param {string} [filterSearch] Search by loan product code
         * @param {number} [filterInterestRate] Search by loan product interest rate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllProducts: async (page?: number, size?: number, sort?: Array<string>, filterAmount?: number, filterTerm?: number, filterCampaignId?: number, filterPaymentFrequency?: string, filterActive?: boolean, filterSearch?: string, filterInterestRate?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/loan-products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filterAmount !== undefined) {
                localVarQueryParameter['filter.amount'] = filterAmount;
            }

            if (filterTerm !== undefined) {
                localVarQueryParameter['filter.term'] = filterTerm;
            }

            if (filterCampaignId !== undefined) {
                localVarQueryParameter['filter.campaignId'] = filterCampaignId;
            }

            if (filterPaymentFrequency !== undefined) {
                localVarQueryParameter['filter.paymentFrequency'] = filterPaymentFrequency;
            }

            if (filterActive !== undefined) {
                localVarQueryParameter['filter.active'] = filterActive;
            }

            if (filterSearch !== undefined) {
                localVarQueryParameter['filter.search'] = filterSearch;
            }

            if (filterInterestRate !== undefined) {
                localVarQueryParameter['filter.interestRate'] = filterInterestRate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return loan product\'s history
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoanProductHistory: async (id: number, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLoanProductHistory', 'id', id)
            const localVarPath = `/api/loan-products/{id}/history`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return single loan product
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProduct: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProduct', 'id', id)
            const localVarPath = `/api/loan-products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update loan product
         * @param {number} id 
         * @param {LoanProduct} loanProduct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct: async (id: number, loanProduct: LoanProduct, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProduct', 'id', id)
            // verify required parameter 'loanProduct' is not null or undefined
            assertParamExists('updateProduct', 'loanProduct', loanProduct)
            const localVarPath = `/api/loan-products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loanProduct, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoanProductApi - functional programming interface
 * @export
 */
export const LoanProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoanProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create loan product
         * @param {LoanProduct} loanProduct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProduct(loanProduct: LoanProduct, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProduct(loanProduct, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return list of campaign\'s loan products
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllByCampaignId(id: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllByCampaignId(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return list of loan products
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {number} [filterAmount] Search by loan product amount
         * @param {number} [filterTerm] Search by loan product term
         * @param {number} [filterCampaignId] Search by loan campaign id
         * @param {string} [filterPaymentFrequency] Search by loan product payment frequency
         * @param {boolean} [filterActive] Search by loan product active flag
         * @param {string} [filterSearch] Search by loan product code
         * @param {number} [filterInterestRate] Search by loan product interest rate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllProducts(page?: number, size?: number, sort?: Array<string>, filterAmount?: number, filterTerm?: number, filterCampaignId?: number, filterPaymentFrequency?: string, filterActive?: boolean, filterSearch?: string, filterInterestRate?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllProducts(page, size, sort, filterAmount, filterTerm, filterCampaignId, filterPaymentFrequency, filterActive, filterSearch, filterInterestRate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return loan product\'s history
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLoanProductHistory(id: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageRevisionIntegerLoanProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLoanProductHistory(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return single loan product
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProduct(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProduct(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update loan product
         * @param {number} id 
         * @param {LoanProduct} loanProduct 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProduct(id: number, loanProduct: LoanProduct, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoanProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProduct(id, loanProduct, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoanProductApi - factory interface
 * @export
 */
export const LoanProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoanProductApiFp(configuration)
    return {
        /**
         * 
         * @summary Create loan product
         * @param {LoanProductApiCreateProductRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProduct(requestParameters: LoanProductApiCreateProductRequest, options?: AxiosRequestConfig): AxiosPromise<LoanProduct> {
            return localVarFp.createProduct(requestParameters.loanProduct, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of campaign\'s loan products
         * @param {LoanProductApiFindAllByCampaignIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByCampaignId(requestParameters: LoanProductApiFindAllByCampaignIdRequest, options?: AxiosRequestConfig): AxiosPromise<PageLoanProduct> {
            return localVarFp.findAllByCampaignId(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of loan products
         * @param {LoanProductApiFindAllProductsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllProducts(requestParameters: LoanProductApiFindAllProductsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageLoanProduct> {
            return localVarFp.findAllProducts(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.filterAmount, requestParameters.filterTerm, requestParameters.filterCampaignId, requestParameters.filterPaymentFrequency, requestParameters.filterActive, requestParameters.filterSearch, requestParameters.filterInterestRate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return loan product\'s history
         * @param {LoanProductApiGetLoanProductHistoryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoanProductHistory(requestParameters: LoanProductApiGetLoanProductHistoryRequest, options?: AxiosRequestConfig): AxiosPromise<PageRevisionIntegerLoanProduct> {
            return localVarFp.getLoanProductHistory(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return single loan product
         * @param {LoanProductApiGetProductRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProduct(requestParameters: LoanProductApiGetProductRequest, options?: AxiosRequestConfig): AxiosPromise<LoanProduct> {
            return localVarFp.getProduct(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update loan product
         * @param {LoanProductApiUpdateProductRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProduct(requestParameters: LoanProductApiUpdateProductRequest, options?: AxiosRequestConfig): AxiosPromise<LoanProduct> {
            return localVarFp.updateProduct(requestParameters.id, requestParameters.loanProduct, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createProduct operation in LoanProductApi.
 * @export
 * @interface LoanProductApiCreateProductRequest
 */
export interface LoanProductApiCreateProductRequest {
    /**
     * 
     * @type {LoanProduct}
     * @memberof LoanProductApiCreateProduct
     */
    readonly loanProduct: LoanProduct
}

/**
 * Request parameters for findAllByCampaignId operation in LoanProductApi.
 * @export
 * @interface LoanProductApiFindAllByCampaignIdRequest
 */
export interface LoanProductApiFindAllByCampaignIdRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanProductApiFindAllByCampaignId
     */
    readonly id: number

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof LoanProductApiFindAllByCampaignId
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof LoanProductApiFindAllByCampaignId
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof LoanProductApiFindAllByCampaignId
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for findAllProducts operation in LoanProductApi.
 * @export
 * @interface LoanProductApiFindAllProductsRequest
 */
export interface LoanProductApiFindAllProductsRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof LoanProductApiFindAllProducts
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof LoanProductApiFindAllProducts
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof LoanProductApiFindAllProducts
     */
    readonly sort?: Array<string>

    /**
     * Search by loan product amount
     * @type {number}
     * @memberof LoanProductApiFindAllProducts
     */
    readonly filterAmount?: number

    /**
     * Search by loan product term
     * @type {number}
     * @memberof LoanProductApiFindAllProducts
     */
    readonly filterTerm?: number

    /**
     * Search by loan campaign id
     * @type {number}
     * @memberof LoanProductApiFindAllProducts
     */
    readonly filterCampaignId?: number

    /**
     * Search by loan product payment frequency
     * @type {string}
     * @memberof LoanProductApiFindAllProducts
     */
    readonly filterPaymentFrequency?: string

    /**
     * Search by loan product active flag
     * @type {boolean}
     * @memberof LoanProductApiFindAllProducts
     */
    readonly filterActive?: boolean

    /**
     * Search by loan product code
     * @type {string}
     * @memberof LoanProductApiFindAllProducts
     */
    readonly filterSearch?: string

    /**
     * Search by loan product interest rate
     * @type {number}
     * @memberof LoanProductApiFindAllProducts
     */
    readonly filterInterestRate?: number
}

/**
 * Request parameters for getLoanProductHistory operation in LoanProductApi.
 * @export
 * @interface LoanProductApiGetLoanProductHistoryRequest
 */
export interface LoanProductApiGetLoanProductHistoryRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanProductApiGetLoanProductHistory
     */
    readonly id: number

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof LoanProductApiGetLoanProductHistory
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof LoanProductApiGetLoanProductHistory
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof LoanProductApiGetLoanProductHistory
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for getProduct operation in LoanProductApi.
 * @export
 * @interface LoanProductApiGetProductRequest
 */
export interface LoanProductApiGetProductRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanProductApiGetProduct
     */
    readonly id: number
}

/**
 * Request parameters for updateProduct operation in LoanProductApi.
 * @export
 * @interface LoanProductApiUpdateProductRequest
 */
export interface LoanProductApiUpdateProductRequest {
    /**
     * 
     * @type {number}
     * @memberof LoanProductApiUpdateProduct
     */
    readonly id: number

    /**
     * 
     * @type {LoanProduct}
     * @memberof LoanProductApiUpdateProduct
     */
    readonly loanProduct: LoanProduct
}

/**
 * LoanProductApi - object-oriented interface
 * @export
 * @class LoanProductApi
 * @extends {BaseAPI}
 */
export class LoanProductApi extends BaseAPI {
    /**
     * 
     * @summary Create loan product
     * @param {LoanProductApiCreateProductRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanProductApi
     */
    public createProduct(requestParameters: LoanProductApiCreateProductRequest, options?: AxiosRequestConfig) {
        return LoanProductApiFp(this.configuration).createProduct(requestParameters.loanProduct, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of campaign\'s loan products
     * @param {LoanProductApiFindAllByCampaignIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanProductApi
     */
    public findAllByCampaignId(requestParameters: LoanProductApiFindAllByCampaignIdRequest, options?: AxiosRequestConfig) {
        return LoanProductApiFp(this.configuration).findAllByCampaignId(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of loan products
     * @param {LoanProductApiFindAllProductsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanProductApi
     */
    public findAllProducts(requestParameters: LoanProductApiFindAllProductsRequest = {}, options?: AxiosRequestConfig) {
        return LoanProductApiFp(this.configuration).findAllProducts(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.filterAmount, requestParameters.filterTerm, requestParameters.filterCampaignId, requestParameters.filterPaymentFrequency, requestParameters.filterActive, requestParameters.filterSearch, requestParameters.filterInterestRate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return loan product\'s history
     * @param {LoanProductApiGetLoanProductHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanProductApi
     */
    public getLoanProductHistory(requestParameters: LoanProductApiGetLoanProductHistoryRequest, options?: AxiosRequestConfig) {
        return LoanProductApiFp(this.configuration).getLoanProductHistory(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return single loan product
     * @param {LoanProductApiGetProductRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanProductApi
     */
    public getProduct(requestParameters: LoanProductApiGetProductRequest, options?: AxiosRequestConfig) {
        return LoanProductApiFp(this.configuration).getProduct(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update loan product
     * @param {LoanProductApiUpdateProductRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanProductApi
     */
    public updateProduct(requestParameters: LoanProductApiUpdateProductRequest, options?: AxiosRequestConfig) {
        return LoanProductApiFp(this.configuration).updateProduct(requestParameters.id, requestParameters.loanProduct, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProcessDefinitionManagerApi - axios parameter creator
 * @export
 */
export const ProcessDefinitionManagerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Starts a process instance of latest definition and return reslut at first wait state. If allowed to access. The process definition has to have an extension: tag = calculator.
         * @summary Start a process instance with result in return
         * @param {string} key Process definition key
         * @param {{ [key: string]: object; }} variables Input variables
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateGet: async (key: string, variables: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('calculateGet', 'key', key)
            // verify required parameter 'variables' is not null or undefined
            assertParamExists('calculateGet', 'variables', variables)
            const localVarPath = `/api/process-definition/key/{key}/calculate`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)

            if (variables !== undefined) {
                localVarQueryParameter['variables'] = variables;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Starts a process instance of latest definition and return result at first wait state. If allowed to access. The process definition has to have an extension: tag = calculator.
         * @summary Start a process instance with result in return
         * @param {string} key Process definition key
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculatePost: async (key: string, requestBody: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('calculatePost', 'key', key)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('calculatePost', 'requestBody', requestBody)
            const localVarPath = `/api/process-definition/key/{key}/calculate`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List process definitions allowed to start by the user performed the request
         * @summary List process definitions allowed to start
         * @param {string} key Process definition key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessDefinition: async (key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getProcessDefinition', 'key', key)
            const localVarPath = `/api/process-definition/key/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the process start form JSON description in FormIO format. Embedded forms are expanded in single form.
         * @summary Retrieve start form for the process
         * @param {string} key Process definition key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStartForm: async (key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getStartForm', 'key', key)
            const localVarPath = `/api/process-definition/key/{key}/start-form`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of start form variables of a latest process definition if allowed to access.
         * @summary Retrieve start form variables
         * @param {string} key Process definition key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStartFormVariables: async (key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getStartFormVariables', 'key', key)
            const localVarPath = `/api/process-definition/key/{key}/form-variables`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the process start form JSON description in FormIO format. Embedded forms are expanded in single form. Form variables are included into the response in FormIO structure format.
         * @summary Retrieve start form for the process with form variables included
         * @param {string} key Process definition key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStartFormWithData: async (key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getStartFormWithData', 'key', key)
            const localVarPath = `/api/process-definition/key/{key}/start-form-with-data`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List process definitions allowed to start by the user performed the request
         * @summary List process definitions allowed to start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStartableByUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/process-definition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Starts a process instance of latest definition by form submission if allowed to access.
         * @summary Start a process instance by form submission
         * @param {string} key Process definition key
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitStartFrom: async (key: string, requestBody: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('submitStartFrom', 'key', key)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('submitStartFrom', 'requestBody', requestBody)
            const localVarPath = `/api/process-definition/key/{key}/submit-form`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProcessDefinitionManagerApi - functional programming interface
 * @export
 */
export const ProcessDefinitionManagerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProcessDefinitionManagerApiAxiosParamCreator(configuration)
    return {
        /**
         * Starts a process instance of latest definition and return reslut at first wait state. If allowed to access. The process definition has to have an extension: tag = calculator.
         * @summary Start a process instance with result in return
         * @param {string} key Process definition key
         * @param {{ [key: string]: object; }} variables Input variables
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateGet(key: string, variables: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateGet(key, variables, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Starts a process instance of latest definition and return result at first wait state. If allowed to access. The process definition has to have an extension: tag = calculator.
         * @summary Start a process instance with result in return
         * @param {string} key Process definition key
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculatePost(key: string, requestBody: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculatePost(key, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List process definitions allowed to start by the user performed the request
         * @summary List process definitions allowed to start
         * @param {string} key Process definition key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProcessDefinition(key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessDefinitionRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProcessDefinition(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the process start form JSON description in FormIO format. Embedded forms are expanded in single form.
         * @summary Retrieve start form for the process
         * @param {string} key Process definition key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStartForm(key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStartForm(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List of start form variables of a latest process definition if allowed to access.
         * @summary Retrieve start form variables
         * @param {string} key Process definition key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStartFormVariables(key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStartFormVariables(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the process start form JSON description in FormIO format. Embedded forms are expanded in single form. Form variables are included into the response in FormIO structure format.
         * @summary Retrieve start form for the process with form variables included
         * @param {string} key Process definition key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStartFormWithData(key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStartFormWithData(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List process definitions allowed to start by the user performed the request
         * @summary List process definitions allowed to start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStartableByUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProcessDefinitionRepresentation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listStartableByUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Starts a process instance of latest definition by form submission if allowed to access.
         * @summary Start a process instance by form submission
         * @param {string} key Process definition key
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitStartFrom(key: string, requestBody: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskRepresentation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitStartFrom(key, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProcessDefinitionManagerApi - factory interface
 * @export
 */
export const ProcessDefinitionManagerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProcessDefinitionManagerApiFp(configuration)
    return {
        /**
         * Starts a process instance of latest definition and return reslut at first wait state. If allowed to access. The process definition has to have an extension: tag = calculator.
         * @summary Start a process instance with result in return
         * @param {ProcessDefinitionManagerApiCalculateGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateGet(requestParameters: ProcessDefinitionManagerApiCalculateGetRequest, options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: object; }> {
            return localVarFp.calculateGet(requestParameters.key, requestParameters.variables, options).then((request) => request(axios, basePath));
        },
        /**
         * Starts a process instance of latest definition and return result at first wait state. If allowed to access. The process definition has to have an extension: tag = calculator.
         * @summary Start a process instance with result in return
         * @param {ProcessDefinitionManagerApiCalculatePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculatePost(requestParameters: ProcessDefinitionManagerApiCalculatePostRequest, options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: object; }> {
            return localVarFp.calculatePost(requestParameters.key, requestParameters.requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * List process definitions allowed to start by the user performed the request
         * @summary List process definitions allowed to start
         * @param {ProcessDefinitionManagerApiGetProcessDefinitionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProcessDefinition(requestParameters: ProcessDefinitionManagerApiGetProcessDefinitionRequest, options?: AxiosRequestConfig): AxiosPromise<ProcessDefinitionRepresentation> {
            return localVarFp.getProcessDefinition(requestParameters.key, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the process start form JSON description in FormIO format. Embedded forms are expanded in single form.
         * @summary Retrieve start form for the process
         * @param {ProcessDefinitionManagerApiGetStartFormRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStartForm(requestParameters: ProcessDefinitionManagerApiGetStartFormRequest, options?: AxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.getStartForm(requestParameters.key, options).then((request) => request(axios, basePath));
        },
        /**
         * List of start form variables of a latest process definition if allowed to access.
         * @summary Retrieve start form variables
         * @param {ProcessDefinitionManagerApiGetStartFormVariablesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStartFormVariables(requestParameters: ProcessDefinitionManagerApiGetStartFormVariablesRequest, options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: object; }> {
            return localVarFp.getStartFormVariables(requestParameters.key, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the process start form JSON description in FormIO format. Embedded forms are expanded in single form. Form variables are included into the response in FormIO structure format.
         * @summary Retrieve start form for the process with form variables included
         * @param {ProcessDefinitionManagerApiGetStartFormWithDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStartFormWithData(requestParameters: ProcessDefinitionManagerApiGetStartFormWithDataRequest, options?: AxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.getStartFormWithData(requestParameters.key, options).then((request) => request(axios, basePath));
        },
        /**
         * List process definitions allowed to start by the user performed the request
         * @summary List process definitions allowed to start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStartableByUser(options?: AxiosRequestConfig): AxiosPromise<Array<ProcessDefinitionRepresentation>> {
            return localVarFp.listStartableByUser(options).then((request) => request(axios, basePath));
        },
        /**
         * Starts a process instance of latest definition by form submission if allowed to access.
         * @summary Start a process instance by form submission
         * @param {ProcessDefinitionManagerApiSubmitStartFromRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitStartFrom(requestParameters: ProcessDefinitionManagerApiSubmitStartFromRequest, options?: AxiosRequestConfig): AxiosPromise<Array<TaskRepresentation>> {
            return localVarFp.submitStartFrom(requestParameters.key, requestParameters.requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for calculateGet operation in ProcessDefinitionManagerApi.
 * @export
 * @interface ProcessDefinitionManagerApiCalculateGetRequest
 */
export interface ProcessDefinitionManagerApiCalculateGetRequest {
    /**
     * Process definition key
     * @type {string}
     * @memberof ProcessDefinitionManagerApiCalculateGet
     */
    readonly key: string

    /**
     * Input variables
     * @type {{ [key: string]: object; }}
     * @memberof ProcessDefinitionManagerApiCalculateGet
     */
    readonly variables: { [key: string]: object; }
}

/**
 * Request parameters for calculatePost operation in ProcessDefinitionManagerApi.
 * @export
 * @interface ProcessDefinitionManagerApiCalculatePostRequest
 */
export interface ProcessDefinitionManagerApiCalculatePostRequest {
    /**
     * Process definition key
     * @type {string}
     * @memberof ProcessDefinitionManagerApiCalculatePost
     */
    readonly key: string

    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProcessDefinitionManagerApiCalculatePost
     */
    readonly requestBody: { [key: string]: object; }
}

/**
 * Request parameters for getProcessDefinition operation in ProcessDefinitionManagerApi.
 * @export
 * @interface ProcessDefinitionManagerApiGetProcessDefinitionRequest
 */
export interface ProcessDefinitionManagerApiGetProcessDefinitionRequest {
    /**
     * Process definition key
     * @type {string}
     * @memberof ProcessDefinitionManagerApiGetProcessDefinition
     */
    readonly key: string
}

/**
 * Request parameters for getStartForm operation in ProcessDefinitionManagerApi.
 * @export
 * @interface ProcessDefinitionManagerApiGetStartFormRequest
 */
export interface ProcessDefinitionManagerApiGetStartFormRequest {
    /**
     * Process definition key
     * @type {string}
     * @memberof ProcessDefinitionManagerApiGetStartForm
     */
    readonly key: string
}

/**
 * Request parameters for getStartFormVariables operation in ProcessDefinitionManagerApi.
 * @export
 * @interface ProcessDefinitionManagerApiGetStartFormVariablesRequest
 */
export interface ProcessDefinitionManagerApiGetStartFormVariablesRequest {
    /**
     * Process definition key
     * @type {string}
     * @memberof ProcessDefinitionManagerApiGetStartFormVariables
     */
    readonly key: string
}

/**
 * Request parameters for getStartFormWithData operation in ProcessDefinitionManagerApi.
 * @export
 * @interface ProcessDefinitionManagerApiGetStartFormWithDataRequest
 */
export interface ProcessDefinitionManagerApiGetStartFormWithDataRequest {
    /**
     * Process definition key
     * @type {string}
     * @memberof ProcessDefinitionManagerApiGetStartFormWithData
     */
    readonly key: string
}

/**
 * Request parameters for submitStartFrom operation in ProcessDefinitionManagerApi.
 * @export
 * @interface ProcessDefinitionManagerApiSubmitStartFromRequest
 */
export interface ProcessDefinitionManagerApiSubmitStartFromRequest {
    /**
     * Process definition key
     * @type {string}
     * @memberof ProcessDefinitionManagerApiSubmitStartFrom
     */
    readonly key: string

    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof ProcessDefinitionManagerApiSubmitStartFrom
     */
    readonly requestBody: { [key: string]: object; }
}

/**
 * ProcessDefinitionManagerApi - object-oriented interface
 * @export
 * @class ProcessDefinitionManagerApi
 * @extends {BaseAPI}
 */
export class ProcessDefinitionManagerApi extends BaseAPI {
    /**
     * Starts a process instance of latest definition and return reslut at first wait state. If allowed to access. The process definition has to have an extension: tag = calculator.
     * @summary Start a process instance with result in return
     * @param {ProcessDefinitionManagerApiCalculateGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessDefinitionManagerApi
     */
    public calculateGet(requestParameters: ProcessDefinitionManagerApiCalculateGetRequest, options?: AxiosRequestConfig) {
        return ProcessDefinitionManagerApiFp(this.configuration).calculateGet(requestParameters.key, requestParameters.variables, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Starts a process instance of latest definition and return result at first wait state. If allowed to access. The process definition has to have an extension: tag = calculator.
     * @summary Start a process instance with result in return
     * @param {ProcessDefinitionManagerApiCalculatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessDefinitionManagerApi
     */
    public calculatePost(requestParameters: ProcessDefinitionManagerApiCalculatePostRequest, options?: AxiosRequestConfig) {
        return ProcessDefinitionManagerApiFp(this.configuration).calculatePost(requestParameters.key, requestParameters.requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List process definitions allowed to start by the user performed the request
     * @summary List process definitions allowed to start
     * @param {ProcessDefinitionManagerApiGetProcessDefinitionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessDefinitionManagerApi
     */
    public getProcessDefinition(requestParameters: ProcessDefinitionManagerApiGetProcessDefinitionRequest, options?: AxiosRequestConfig) {
        return ProcessDefinitionManagerApiFp(this.configuration).getProcessDefinition(requestParameters.key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the process start form JSON description in FormIO format. Embedded forms are expanded in single form.
     * @summary Retrieve start form for the process
     * @param {ProcessDefinitionManagerApiGetStartFormRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessDefinitionManagerApi
     */
    public getStartForm(requestParameters: ProcessDefinitionManagerApiGetStartFormRequest, options?: AxiosRequestConfig) {
        return ProcessDefinitionManagerApiFp(this.configuration).getStartForm(requestParameters.key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of start form variables of a latest process definition if allowed to access.
     * @summary Retrieve start form variables
     * @param {ProcessDefinitionManagerApiGetStartFormVariablesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessDefinitionManagerApi
     */
    public getStartFormVariables(requestParameters: ProcessDefinitionManagerApiGetStartFormVariablesRequest, options?: AxiosRequestConfig) {
        return ProcessDefinitionManagerApiFp(this.configuration).getStartFormVariables(requestParameters.key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the process start form JSON description in FormIO format. Embedded forms are expanded in single form. Form variables are included into the response in FormIO structure format.
     * @summary Retrieve start form for the process with form variables included
     * @param {ProcessDefinitionManagerApiGetStartFormWithDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessDefinitionManagerApi
     */
    public getStartFormWithData(requestParameters: ProcessDefinitionManagerApiGetStartFormWithDataRequest, options?: AxiosRequestConfig) {
        return ProcessDefinitionManagerApiFp(this.configuration).getStartFormWithData(requestParameters.key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List process definitions allowed to start by the user performed the request
     * @summary List process definitions allowed to start
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessDefinitionManagerApi
     */
    public listStartableByUser(options?: AxiosRequestConfig) {
        return ProcessDefinitionManagerApiFp(this.configuration).listStartableByUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Starts a process instance of latest definition by form submission if allowed to access.
     * @summary Start a process instance by form submission
     * @param {ProcessDefinitionManagerApiSubmitStartFromRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProcessDefinitionManagerApi
     */
    public submitStartFrom(requestParameters: ProcessDefinitionManagerApiSubmitStartFromRequest, options?: AxiosRequestConfig) {
        return ProcessDefinitionManagerApiFp(this.configuration).submitStartFrom(requestParameters.key, requestParameters.requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TaskManagerApi - axios parameter creator
 * @export
 */
export const TaskManagerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Assign the task to current user if available.
         * @summary Claim a task
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claim: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('claim', 'id', id)
            const localVarPath = `/api/task/{id}/claim`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the task form JSON description in FormIO format. Embedded forms are expanded in single form.
         * @summary Retrieve form for the task
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskForm: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTaskForm', 'id', id)
            const localVarPath = `/api/task/{id}/form`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of task form variables of a latest process definition if allowed to access.
         * @summary Retrieve task form variables
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskFormVariables: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTaskFormVariables', 'id', id)
            const localVarPath = `/api/task/{id}/form-variables`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the task form JSON description in FormIO format with form variales as submission/data. Embedded forms are expanded in single form. Form variables are included into the response in FormIO structure format.
         * @summary Retrieve form with data for the task
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskFormWithData: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTaskFormWithData', 'id', id)
            const localVarPath = `/api/task/{id}/form-with-data`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve brief info for a single task if available.
         * @summary Get task info
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskInfo: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTaskInfo', 'id', id)
            const localVarPath = `/api/task/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List tasks assigned to a user performed the request
         * @summary List assigned tasks
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAssigned: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/task/assigned`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List unassigned tasks where the user is a candidate for execution according to their keycloak roles.
         * @summary List tasks available for claim
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAvailable: async (page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/task/available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Complete the task by form submission if the user is assignee.
         * @summary Complete a task by form submission
         * @param {string} id Task id
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitForm: async (id: string, requestBody: { [key: string]: object; }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('submitForm', 'id', id)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('submitForm', 'requestBody', requestBody)
            const localVarPath = `/api/task/{id}/submit-form`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Unassign the task from current user.
         * @summary Unclaim a task
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unclaim: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('unclaim', 'id', id)
            const localVarPath = `/api/task/{id}/unclaim`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaskManagerApi - functional programming interface
 * @export
 */
export const TaskManagerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaskManagerApiAxiosParamCreator(configuration)
    return {
        /**
         * Assign the task to current user if available.
         * @summary Claim a task
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async claim(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.claim(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the task form JSON description in FormIO format. Embedded forms are expanded in single form.
         * @summary Retrieve form for the task
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaskForm(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaskForm(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List of task form variables of a latest process definition if allowed to access.
         * @summary Retrieve task form variables
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaskFormVariables(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: object; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaskFormVariables(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the task form JSON description in FormIO format with form variales as submission/data. Embedded forms are expanded in single form. Form variables are included into the response in FormIO structure format.
         * @summary Retrieve form with data for the task
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaskFormWithData(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaskFormWithData(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve brief info for a single task if available.
         * @summary Get task info
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaskInfo(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaskInfo(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List tasks assigned to a user performed the request
         * @summary List assigned tasks
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAssigned(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTaskRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAssigned(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List unassigned tasks where the user is a candidate for execution according to their keycloak roles.
         * @summary List tasks available for claim
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAvailable(page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTaskRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAvailable(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Complete the task by form submission if the user is assignee.
         * @summary Complete a task by form submission
         * @param {string} id Task id
         * @param {{ [key: string]: object; }} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitForm(id: string, requestBody: { [key: string]: object; }, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskRepresentation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitForm(id, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Unassign the task from current user.
         * @summary Unclaim a task
         * @param {string} id Task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unclaim(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unclaim(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaskManagerApi - factory interface
 * @export
 */
export const TaskManagerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaskManagerApiFp(configuration)
    return {
        /**
         * Assign the task to current user if available.
         * @summary Claim a task
         * @param {TaskManagerApiClaimRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        claim(requestParameters: TaskManagerApiClaimRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.claim(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the task form JSON description in FormIO format. Embedded forms are expanded in single form.
         * @summary Retrieve form for the task
         * @param {TaskManagerApiGetTaskFormRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskForm(requestParameters: TaskManagerApiGetTaskFormRequest, options?: AxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.getTaskForm(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * List of task form variables of a latest process definition if allowed to access.
         * @summary Retrieve task form variables
         * @param {TaskManagerApiGetTaskFormVariablesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskFormVariables(requestParameters: TaskManagerApiGetTaskFormVariablesRequest, options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: object; }> {
            return localVarFp.getTaskFormVariables(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the task form JSON description in FormIO format with form variales as submission/data. Embedded forms are expanded in single form. Form variables are included into the response in FormIO structure format.
         * @summary Retrieve form with data for the task
         * @param {TaskManagerApiGetTaskFormWithDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskFormWithData(requestParameters: TaskManagerApiGetTaskFormWithDataRequest, options?: AxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.getTaskFormWithData(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve brief info for a single task if available.
         * @summary Get task info
         * @param {TaskManagerApiGetTaskInfoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaskInfo(requestParameters: TaskManagerApiGetTaskInfoRequest, options?: AxiosRequestConfig): AxiosPromise<TaskRepresentation> {
            return localVarFp.getTaskInfo(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * List tasks assigned to a user performed the request
         * @summary List assigned tasks
         * @param {TaskManagerApiListAssignedRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAssigned(requestParameters: TaskManagerApiListAssignedRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageTaskRepresentation> {
            return localVarFp.listAssigned(requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * List unassigned tasks where the user is a candidate for execution according to their keycloak roles.
         * @summary List tasks available for claim
         * @param {TaskManagerApiListAvailableRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAvailable(requestParameters: TaskManagerApiListAvailableRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageTaskRepresentation> {
            return localVarFp.listAvailable(requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Complete the task by form submission if the user is assignee.
         * @summary Complete a task by form submission
         * @param {TaskManagerApiSubmitFormRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitForm(requestParameters: TaskManagerApiSubmitFormRequest, options?: AxiosRequestConfig): AxiosPromise<Array<TaskRepresentation>> {
            return localVarFp.submitForm(requestParameters.id, requestParameters.requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Unassign the task from current user.
         * @summary Unclaim a task
         * @param {TaskManagerApiUnclaimRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unclaim(requestParameters: TaskManagerApiUnclaimRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.unclaim(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for claim operation in TaskManagerApi.
 * @export
 * @interface TaskManagerApiClaimRequest
 */
export interface TaskManagerApiClaimRequest {
    /**
     * Task id
     * @type {string}
     * @memberof TaskManagerApiClaim
     */
    readonly id: string
}

/**
 * Request parameters for getTaskForm operation in TaskManagerApi.
 * @export
 * @interface TaskManagerApiGetTaskFormRequest
 */
export interface TaskManagerApiGetTaskFormRequest {
    /**
     * Task id
     * @type {string}
     * @memberof TaskManagerApiGetTaskForm
     */
    readonly id: string
}

/**
 * Request parameters for getTaskFormVariables operation in TaskManagerApi.
 * @export
 * @interface TaskManagerApiGetTaskFormVariablesRequest
 */
export interface TaskManagerApiGetTaskFormVariablesRequest {
    /**
     * Task id
     * @type {string}
     * @memberof TaskManagerApiGetTaskFormVariables
     */
    readonly id: string
}

/**
 * Request parameters for getTaskFormWithData operation in TaskManagerApi.
 * @export
 * @interface TaskManagerApiGetTaskFormWithDataRequest
 */
export interface TaskManagerApiGetTaskFormWithDataRequest {
    /**
     * Task id
     * @type {string}
     * @memberof TaskManagerApiGetTaskFormWithData
     */
    readonly id: string
}

/**
 * Request parameters for getTaskInfo operation in TaskManagerApi.
 * @export
 * @interface TaskManagerApiGetTaskInfoRequest
 */
export interface TaskManagerApiGetTaskInfoRequest {
    /**
     * Task id
     * @type {string}
     * @memberof TaskManagerApiGetTaskInfo
     */
    readonly id: string
}

/**
 * Request parameters for listAssigned operation in TaskManagerApi.
 * @export
 * @interface TaskManagerApiListAssignedRequest
 */
export interface TaskManagerApiListAssignedRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof TaskManagerApiListAssigned
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof TaskManagerApiListAssigned
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof TaskManagerApiListAssigned
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for listAvailable operation in TaskManagerApi.
 * @export
 * @interface TaskManagerApiListAvailableRequest
 */
export interface TaskManagerApiListAvailableRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof TaskManagerApiListAvailable
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof TaskManagerApiListAvailable
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof TaskManagerApiListAvailable
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for submitForm operation in TaskManagerApi.
 * @export
 * @interface TaskManagerApiSubmitFormRequest
 */
export interface TaskManagerApiSubmitFormRequest {
    /**
     * Task id
     * @type {string}
     * @memberof TaskManagerApiSubmitForm
     */
    readonly id: string

    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof TaskManagerApiSubmitForm
     */
    readonly requestBody: { [key: string]: object; }
}

/**
 * Request parameters for unclaim operation in TaskManagerApi.
 * @export
 * @interface TaskManagerApiUnclaimRequest
 */
export interface TaskManagerApiUnclaimRequest {
    /**
     * Task id
     * @type {string}
     * @memberof TaskManagerApiUnclaim
     */
    readonly id: string
}

/**
 * TaskManagerApi - object-oriented interface
 * @export
 * @class TaskManagerApi
 * @extends {BaseAPI}
 */
export class TaskManagerApi extends BaseAPI {
    /**
     * Assign the task to current user if available.
     * @summary Claim a task
     * @param {TaskManagerApiClaimRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskManagerApi
     */
    public claim(requestParameters: TaskManagerApiClaimRequest, options?: AxiosRequestConfig) {
        return TaskManagerApiFp(this.configuration).claim(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the task form JSON description in FormIO format. Embedded forms are expanded in single form.
     * @summary Retrieve form for the task
     * @param {TaskManagerApiGetTaskFormRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskManagerApi
     */
    public getTaskForm(requestParameters: TaskManagerApiGetTaskFormRequest, options?: AxiosRequestConfig) {
        return TaskManagerApiFp(this.configuration).getTaskForm(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of task form variables of a latest process definition if allowed to access.
     * @summary Retrieve task form variables
     * @param {TaskManagerApiGetTaskFormVariablesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskManagerApi
     */
    public getTaskFormVariables(requestParameters: TaskManagerApiGetTaskFormVariablesRequest, options?: AxiosRequestConfig) {
        return TaskManagerApiFp(this.configuration).getTaskFormVariables(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the task form JSON description in FormIO format with form variales as submission/data. Embedded forms are expanded in single form. Form variables are included into the response in FormIO structure format.
     * @summary Retrieve form with data for the task
     * @param {TaskManagerApiGetTaskFormWithDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskManagerApi
     */
    public getTaskFormWithData(requestParameters: TaskManagerApiGetTaskFormWithDataRequest, options?: AxiosRequestConfig) {
        return TaskManagerApiFp(this.configuration).getTaskFormWithData(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve brief info for a single task if available.
     * @summary Get task info
     * @param {TaskManagerApiGetTaskInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskManagerApi
     */
    public getTaskInfo(requestParameters: TaskManagerApiGetTaskInfoRequest, options?: AxiosRequestConfig) {
        return TaskManagerApiFp(this.configuration).getTaskInfo(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List tasks assigned to a user performed the request
     * @summary List assigned tasks
     * @param {TaskManagerApiListAssignedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskManagerApi
     */
    public listAssigned(requestParameters: TaskManagerApiListAssignedRequest = {}, options?: AxiosRequestConfig) {
        return TaskManagerApiFp(this.configuration).listAssigned(requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List unassigned tasks where the user is a candidate for execution according to their keycloak roles.
     * @summary List tasks available for claim
     * @param {TaskManagerApiListAvailableRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskManagerApi
     */
    public listAvailable(requestParameters: TaskManagerApiListAvailableRequest = {}, options?: AxiosRequestConfig) {
        return TaskManagerApiFp(this.configuration).listAvailable(requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Complete the task by form submission if the user is assignee.
     * @summary Complete a task by form submission
     * @param {TaskManagerApiSubmitFormRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskManagerApi
     */
    public submitForm(requestParameters: TaskManagerApiSubmitFormRequest, options?: AxiosRequestConfig) {
        return TaskManagerApiFp(this.configuration).submitForm(requestParameters.id, requestParameters.requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Unassign the task from current user.
     * @summary Unclaim a task
     * @param {TaskManagerApiUnclaimRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaskManagerApi
     */
    public unclaim(requestParameters: TaskManagerApiUnclaimRequest, options?: AxiosRequestConfig) {
        return TaskManagerApiFp(this.configuration).unclaim(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TransactionApi - axios parameter creator
 * @export
 */
export const TransactionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return list of loan application\'s transactions
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByLoanApplicationId: async (id: number, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findAllByLoanApplicationId', 'id', id)
            const localVarPath = `/api/transactions/loan-application/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return list of loan\'s transactions
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByLoanId: async (id: number, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findAllByLoanId', 'id', id)
            const localVarPath = `/api/transactions/loan/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns list of transactions
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterTypes] Search by transaction payment types
         * @param {Array<string>} [filterStatuses] Search by transaction statuses
         * @param {string} [filterCreatedAtFrom] Search by transaction created at from
         * @param {string} [filterCreatedAtTo] Search by transaction created at to
         * @param {string} [filterProcessedAtFrom] Search by transaction processed at from
         * @param {string} [filterProcessedAtTo] Search by transaction processed at to
         * @param {string} [filterSearch] Search by transaction id, external transaction id; loan number; borrower\&#39;s full name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllTransactions: async (page?: number, size?: number, sort?: Array<string>, filterTypes?: Array<string>, filterStatuses?: Array<string>, filterCreatedAtFrom?: string, filterCreatedAtTo?: string, filterProcessedAtFrom?: string, filterProcessedAtTo?: string, filterSearch?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (filterTypes) {
                localVarQueryParameter['filter.types'] = filterTypes;
            }

            if (filterStatuses) {
                localVarQueryParameter['filter.statuses'] = filterStatuses;
            }

            if (filterCreatedAtFrom !== undefined) {
                localVarQueryParameter['filter.createdAtFrom'] = filterCreatedAtFrom;
            }

            if (filterCreatedAtTo !== undefined) {
                localVarQueryParameter['filter.createdAtTo'] = filterCreatedAtTo;
            }

            if (filterProcessedAtFrom !== undefined) {
                localVarQueryParameter['filter.processedAtFrom'] = filterProcessedAtFrom;
            }

            if (filterProcessedAtTo !== undefined) {
                localVarQueryParameter['filter.processedAtTo'] = filterProcessedAtTo;
            }

            if (filterSearch !== undefined) {
                localVarQueryParameter['filter.search'] = filterSearch;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Return single transaction
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransaction: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTransaction', 'id', id)
            const localVarPath = `/api/transactions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionApi - functional programming interface
 * @export
 */
export const TransactionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Return list of loan application\'s transactions
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllByLoanApplicationId(id: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanApplicationTransactionBrief>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllByLoanApplicationId(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return list of loan\'s transactions
         * @param {number} id 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllByLoanId(id: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageLoanTransactionBrief>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllByLoanId(id, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns list of transactions
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {Array<string>} [filterTypes] Search by transaction payment types
         * @param {Array<string>} [filterStatuses] Search by transaction statuses
         * @param {string} [filterCreatedAtFrom] Search by transaction created at from
         * @param {string} [filterCreatedAtTo] Search by transaction created at to
         * @param {string} [filterProcessedAtFrom] Search by transaction processed at from
         * @param {string} [filterProcessedAtTo] Search by transaction processed at to
         * @param {string} [filterSearch] Search by transaction id, external transaction id; loan number; borrower\&#39;s full name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findAllTransactions(page?: number, size?: number, sort?: Array<string>, filterTypes?: Array<string>, filterStatuses?: Array<string>, filterCreatedAtFrom?: string, filterCreatedAtTo?: string, filterProcessedAtFrom?: string, filterProcessedAtTo?: string, filterSearch?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageTransactionBrief>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findAllTransactions(page, size, sort, filterTypes, filterStatuses, filterCreatedAtFrom, filterCreatedAtTo, filterProcessedAtFrom, filterProcessedAtTo, filterSearch, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Return single transaction
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransaction(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTransaction200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransaction(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TransactionApi - factory interface
 * @export
 */
export const TransactionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionApiFp(configuration)
    return {
        /**
         * 
         * @summary Return list of loan application\'s transactions
         * @param {TransactionApiFindAllByLoanApplicationIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByLoanApplicationId(requestParameters: TransactionApiFindAllByLoanApplicationIdRequest, options?: AxiosRequestConfig): AxiosPromise<PageLoanApplicationTransactionBrief> {
            return localVarFp.findAllByLoanApplicationId(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return list of loan\'s transactions
         * @param {TransactionApiFindAllByLoanIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByLoanId(requestParameters: TransactionApiFindAllByLoanIdRequest, options?: AxiosRequestConfig): AxiosPromise<PageLoanTransactionBrief> {
            return localVarFp.findAllByLoanId(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns list of transactions
         * @param {TransactionApiFindAllTransactionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllTransactions(requestParameters: TransactionApiFindAllTransactionsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageTransactionBrief> {
            return localVarFp.findAllTransactions(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.filterTypes, requestParameters.filterStatuses, requestParameters.filterCreatedAtFrom, requestParameters.filterCreatedAtTo, requestParameters.filterProcessedAtFrom, requestParameters.filterProcessedAtTo, requestParameters.filterSearch, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Return single transaction
         * @param {TransactionApiGetTransactionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransaction(requestParameters: TransactionApiGetTransactionRequest, options?: AxiosRequestConfig): AxiosPromise<GetTransaction200Response> {
            return localVarFp.getTransaction(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for findAllByLoanApplicationId operation in TransactionApi.
 * @export
 * @interface TransactionApiFindAllByLoanApplicationIdRequest
 */
export interface TransactionApiFindAllByLoanApplicationIdRequest {
    /**
     * 
     * @type {number}
     * @memberof TransactionApiFindAllByLoanApplicationId
     */
    readonly id: number

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof TransactionApiFindAllByLoanApplicationId
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof TransactionApiFindAllByLoanApplicationId
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof TransactionApiFindAllByLoanApplicationId
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for findAllByLoanId operation in TransactionApi.
 * @export
 * @interface TransactionApiFindAllByLoanIdRequest
 */
export interface TransactionApiFindAllByLoanIdRequest {
    /**
     * 
     * @type {number}
     * @memberof TransactionApiFindAllByLoanId
     */
    readonly id: number

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof TransactionApiFindAllByLoanId
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof TransactionApiFindAllByLoanId
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof TransactionApiFindAllByLoanId
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for findAllTransactions operation in TransactionApi.
 * @export
 * @interface TransactionApiFindAllTransactionsRequest
 */
export interface TransactionApiFindAllTransactionsRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof TransactionApiFindAllTransactions
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof TransactionApiFindAllTransactions
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof TransactionApiFindAllTransactions
     */
    readonly sort?: Array<string>

    /**
     * Search by transaction payment types
     * @type {Array<string>}
     * @memberof TransactionApiFindAllTransactions
     */
    readonly filterTypes?: Array<string>

    /**
     * Search by transaction statuses
     * @type {Array<string>}
     * @memberof TransactionApiFindAllTransactions
     */
    readonly filterStatuses?: Array<string>

    /**
     * Search by transaction created at from
     * @type {string}
     * @memberof TransactionApiFindAllTransactions
     */
    readonly filterCreatedAtFrom?: string

    /**
     * Search by transaction created at to
     * @type {string}
     * @memberof TransactionApiFindAllTransactions
     */
    readonly filterCreatedAtTo?: string

    /**
     * Search by transaction processed at from
     * @type {string}
     * @memberof TransactionApiFindAllTransactions
     */
    readonly filterProcessedAtFrom?: string

    /**
     * Search by transaction processed at to
     * @type {string}
     * @memberof TransactionApiFindAllTransactions
     */
    readonly filterProcessedAtTo?: string

    /**
     * Search by transaction id, external transaction id; loan number; borrower\&#39;s full name
     * @type {string}
     * @memberof TransactionApiFindAllTransactions
     */
    readonly filterSearch?: string
}

/**
 * Request parameters for getTransaction operation in TransactionApi.
 * @export
 * @interface TransactionApiGetTransactionRequest
 */
export interface TransactionApiGetTransactionRequest {
    /**
     * 
     * @type {number}
     * @memberof TransactionApiGetTransaction
     */
    readonly id: number
}

/**
 * TransactionApi - object-oriented interface
 * @export
 * @class TransactionApi
 * @extends {BaseAPI}
 */
export class TransactionApi extends BaseAPI {
    /**
     * 
     * @summary Return list of loan application\'s transactions
     * @param {TransactionApiFindAllByLoanApplicationIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public findAllByLoanApplicationId(requestParameters: TransactionApiFindAllByLoanApplicationIdRequest, options?: AxiosRequestConfig) {
        return TransactionApiFp(this.configuration).findAllByLoanApplicationId(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return list of loan\'s transactions
     * @param {TransactionApiFindAllByLoanIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public findAllByLoanId(requestParameters: TransactionApiFindAllByLoanIdRequest, options?: AxiosRequestConfig) {
        return TransactionApiFp(this.configuration).findAllByLoanId(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns list of transactions
     * @param {TransactionApiFindAllTransactionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public findAllTransactions(requestParameters: TransactionApiFindAllTransactionsRequest = {}, options?: AxiosRequestConfig) {
        return TransactionApiFp(this.configuration).findAllTransactions(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.filterTypes, requestParameters.filterStatuses, requestParameters.filterCreatedAtFrom, requestParameters.filterCreatedAtTo, requestParameters.filterProcessedAtFrom, requestParameters.filterProcessedAtTo, requestParameters.filterSearch, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Return single transaction
     * @param {TransactionApiGetTransactionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public getTransaction(requestParameters: TransactionApiGetTransactionRequest, options?: AxiosRequestConfig) {
        return TransactionApiFp(this.configuration).getTransaction(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UISettingsApi - axios parameter creator
 * @export
 */
export const UISettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ui-settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UISettingsApi - functional programming interface
 * @export
 */
export const UISettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UISettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UiConfigurations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UISettingsApi - factory interface
 * @export
 */
export const UISettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UISettingsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings(options?: AxiosRequestConfig): AxiosPromise<Array<UiConfigurations>> {
            return localVarFp.getSettings(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UISettingsApi - object-oriented interface
 * @export
 * @class UISettingsApi
 * @extends {BaseAPI}
 */
export class UISettingsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UISettingsApi
     */
    public getSettings(options?: AxiosRequestConfig) {
        return UISettingsApiFp(this.configuration).getSettings(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Find backoffice users by role
         * @param {string} role This parameter should be a single string, each word with a capital letter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUsersByRole: async (role: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'role' is not null or undefined
            assertParamExists('findUsersByRole', 'role', role)
            const localVarPath = `/api/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bo-portal required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "bo-portal", [], configuration)

            // authentication integration-client required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "integration-client", [], configuration)

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Find backoffice users by role
         * @param {string} role This parameter should be a single string, each word with a capital letter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findUsersByRole(role: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findUsersByRole(role, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Find backoffice users by role
         * @param {UserApiFindUsersByRoleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findUsersByRole(requestParameters: UserApiFindUsersByRoleRequest, options?: AxiosRequestConfig): AxiosPromise<Array<string>> {
            return localVarFp.findUsersByRole(requestParameters.role, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for findUsersByRole operation in UserApi.
 * @export
 * @interface UserApiFindUsersByRoleRequest
 */
export interface UserApiFindUsersByRoleRequest {
    /**
     * This parameter should be a single string, each word with a capital letter
     * @type {string}
     * @memberof UserApiFindUsersByRole
     */
    readonly role: string
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Find backoffice users by role
     * @param {UserApiFindUsersByRoleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public findUsersByRole(requestParameters: UserApiFindUsersByRoleRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).findUsersByRole(requestParameters.role, options).then((request) => request(this.axios, this.basePath));
    }
}



