import { basePath } from 'src/env';

export const RoutePaths = {
    index: `${basePath}/`,
    tasks: `${basePath}/tasks/:taskId?`,
    process: `${basePath}/process/:processKey`,
    entityTypeProcess: `${basePath}/:entityType/process/:processKey`,
    entityProcess: `${basePath}/:entityType/:id/process/:processKey`,
    loanApplications: `${basePath}/loan-applications`,
    loanApplicationDetails: `${basePath}/loan-applications/:loanApplicationId`,
    loanApplicationCreate: `${basePath}/loan-applications/create`,
    loans: `${basePath}/loans`,
    loanDetails: `${basePath}/loans/:loanId`,
    loanCreate: `${basePath}/loans/create`,
    products: `${basePath}/products`,
    productDetails: `${basePath}/products/:productId`,
    productCreate: `${basePath}/products/create`,
    campaigns: `${basePath}/campaigns`,
    campaignsDetails: `${basePath}/campaigns/:campaignId`,
    campaignsCreate: `${basePath}/campaigns/create`,
    transactions: `${basePath}/transactions`,
    transactionDetails: `${basePath}/transactions/:transactionId`,
    operations: `${basePath}/loans/:loanId/operations`,
    operationDetails: `${basePath}/loans/:loanId/operations/:operationId`,
    borrowers: `${basePath}/borrowers`,
    borrowerDetails: `${basePath}/borrowers/:borrowerId`,
    borrowersCreate: `${basePath}/borrowers/create`,
    collections: `${basePath}/collections`,
    collectionsDetail: `${basePath}/collections/:collectionId`,
    notAccessPage: `${basePath}/notAccessPage`,
    formioSandBox: `${basePath}/formio-sandbox`,
    leads: `${basePath}/leads`,
    leadsDetails: `${basePath}/leads/:leadId`,
    leadsCreate: `${basePath}/leads/create`,
    viewProfile: `${basePath}/profile`,
    viewProfileDetails: `${basePath}/profile/:me`,
    individuals: `${basePath}/individuals`,
    individualDetails: `${basePath}/individuals/:individualId`,
    individualCreate: `${basePath}/individuals/create`,
    legalEntities: `${basePath}/legal-entities`,
    legalEntityDetails: `${basePath}/legal-entities/:legalEntityId`,
    legalEntityCreate: `${basePath}/legal-entities/create`,
};
