import _ from 'lodash';
import { action, computed, observable, when } from 'mobx';
import { TaskRepresentation } from 'src/generated-api-client';
import { taskManagerApi } from 'src/services/apiServices';
import { AssignedTasksStore } from 'src/stores/AssignedTasksStore/AssignedTasksStore';
import { AuthStore } from 'src/stores/AuthStore/AuthStore';
import { AvailableTasksStore } from 'src/stores/AvailabaleTasksStore/AvailableTasksStore';
import { AsyncOperationWithStatus } from 'src/utils/BasicStore/AsyncOperationWithStatus';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { RequestHelper } from 'src/utils/RequestHelper';

export class TasksStoreClass extends BasicStore<TaskRepresentation> {
    api: BasicStoreApi<TaskRepresentation> = {};
    itemDetailsFormKey = '';
    @observable currentAssignedTask?: TaskRepresentation;
    @observable currentTaskDefinition?: TaskRepresentation;
    @observable taskType = '*';

    assignedTaskListLoader = new AsyncOperationWithStatus(async (...args) => {
        const options = RequestHelper.getOptionsFromArgs(args);
        const resp = await RequestHelper.unwrapFromAxiosPromise(
            taskManagerApi.listAssigned(options),
        );

        return resp.content;
    });

    availableTaskListLoader = new AsyncOperationWithStatus(async (...args) => {
        const options = RequestHelper.getOptionsFromArgs(args);
        const resp = await RequestHelper.unwrapFromAxiosPromise(
            taskManagerApi.listAvailable(options),
        );

        return resp.content;
    });

    currentTaskDefinitionLoader = new AsyncOperationWithStatus(
        (id: string, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                taskManagerApi.getTaskInfo({ id }, options),
            );
        },
    );

    private filterTasksByType(
        tasks: TaskRepresentation[] | undefined,
        type: string,
    ) {
        if (type === '*') {
            return tasks;
        }

        return tasks?.filter(
            (task) =>
                (task.extensions as Record<string, any> | undefined)
                    ?.taskType === type,
        );
    }

    @computed get assignedTasks() {
        return this.filterTasksByType(
            this.assignedTaskListLoader.data,
            this.taskType,
        );
    }

    @computed get availableTasks() {
        return this.filterTasksByType(
            this.availableTaskListLoader.data,
            this.taskType,
        );
    }

    @action async loadAssignedTaskList() {
        await when(() => AuthStore.authenticated);
        await this.assignedTaskListLoader.call();
    }

    @action async loadAvailableTaskList() {
        await when(() => AuthStore.authenticated);
        await this.availableTaskListLoader.call();
    }

    @action async loadCurrentTaskDefinition(id: string) {
        const resp = await this.currentTaskDefinitionLoader.call(id);

        if (!this.currentTaskDefinitionLoader.hasError) {
            this.currentTaskDefinition = resp;
        }
    }

    @action async loadList() {
        await Promise.allSettled([
            AssignedTasksStore.loadList(),
            AvailableTasksStore.loadList(),
        ]);
    }

    @computed get list() {
        return [
            ...(AssignedTasksStore.list || []),
            ...(AvailableTasksStore.list || []),
        ];
    }

    @action async hiddenLoadAvailableTasksList() {
        AvailableTasksStore.listLoader.turnOnSilentMode();
        await AvailableTasksStore.loadList(true);
    }

    @action async hiddenLoadAssignedTasksList() {
        AssignedTasksStore.listLoader.turnOnSilentMode();
        await AssignedTasksStore.loadList(true);
    }

    @computed get taskTypes() {
        return _.uniq(
            this.list
                .map(
                    (task) =>
                        (task.extensions as Record<string, any> | undefined)
                            ?.taskType,
                )
                .filter((taskType) => Boolean(taskType)),
        ) as string[];
    }

    @computed get hasTaskTypes() {
        return this.taskTypes.length > 0;
    }

    @action setTaskType(type: string) {
        this.taskType = type;
    }

    @computed get taskDefinition() {
        return this.currentTaskDefinition;
    }
}

export const TasksStore = new TasksStoreClass();
