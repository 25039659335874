import { computed } from 'mobx';
import {
    IndividualApiFindAllIndividualsRequest,
    IndividualBrief,
} from 'src/generated-api-client';
import {
    bpmsResourcesManagerApi,
    individualsApi,
} from 'src/services/apiServices';
import { initialInitialFilters } from 'src/stores/IndividualsStore/InitialFilters';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import ObjectHelper from 'src/utils/ObjectHelper';
import { RequestHelper } from 'src/utils/RequestHelper';

export type IndividualsFilter = Pick<
    IndividualApiFindAllIndividualsRequest,
    'filterFullName' | 'filterMobileVerified' | 'filterSearch'
>;

// TODO: Make refactoring for this
type IndividualItemDetails = IndividualBrief;
export class IndividualsStoreClass extends BasicStore<
    IndividualItemDetails,
    IndividualsFilter
> {
    itemDetailsFormKey = 'embedded:deployment:forms/bo/bo-44-contact.json';

    pager = new Pager();
    api: BasicStoreApi<IndividualItemDetails> = {
        loadList: async (...args: any[]) => {
            const options = RequestHelper.getOptionsFromArgs(args);
            const resp = await RequestHelper.unwrapFromAxiosPromise(
                individualsApi.findAllIndividuals(
                    {
                        ...this.pager,
                        ...initialInitialFilters,
                        ...this.filterCriteria.filter,
                    },
                    options,
                ),
            );

            this.pager.setTotal(resp.totalElements || 0);

            return resp.content || [];
        },
        loadItemForm: (formKey: string, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                bpmsResourcesManagerApi.getForm({ formKey }, options),
            );
        },
        loadItem: (id: number, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                individualsApi.getIndividual({ id }, options),
            );
        },
    };

    filterCriteria: FilterCriteria<IndividualsFilter> = new FilterCriteria({});

    @computed get hasFilter() {
        return [Object.values(this.filterCriteria.filter)].some(
            ObjectHelper.isValuable,
        );
    }
}

export const IndividualsStore = new IndividualsStoreClass();
