import { observer } from 'mobx-react-lite';
import { useRouteMatch } from 'react-router-dom';
import { ProcessStartForm } from 'src/components/ProcessStartForm';
import styled from 'styled-components';

type ProcessPageRouterParams = {
    processKey: string;
};
export const ProcessPage = observer(() => {
    const { params } = useRouteMatch<ProcessPageRouterParams>();

    return (
        <StyledProcessWrapper className="container-fluid">
            <ProcessStartForm processKey={params.processKey} />
        </StyledProcessWrapper>
    );
});

const StyledProcessWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: var(--app-header-height);
`;
