import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import 'react-image-lightbox/style.css';
import { extractFirstPageAsImage } from 'src/components/Preview/PDFRenderer';
import { Preview } from 'src/components/Preview/Preview';
import { PreviewController } from 'src/components/Preview/PreviewController';

type ImageGalleryWrapperProps = {
    containerRef: any;
};

export const ImageGalleryWrapper = ({
    containerRef,
}: ImageGalleryWrapperProps) => {
    useEffect(() => {
        const mo = new MutationObserver(
            _.debounce(() => {
                const files = Array.from(
                    document.querySelectorAll(
                        '.formio-component files_images-list .file[data-type="application/pdf"]:not(.processed)',
                    ),
                );
                Promise.all(
                    files.map((file) =>
                        extractFirstPageAsImage(
                            file.getAttribute('data-url') as string,
                        ),
                    ),
                ).then((images) => {
                    images.forEach((image, index) => {
                        (
                            files[index].querySelector(
                                '.file__thumbnail',
                            ) as any
                        ).style.backgroundImage = `url(${image})`;
                        files[index].classList.add('processed');
                    });
                });
            }, 200),
        );

        mo.observe(document.body, {
            childList: true,
        });

        return () => {
            mo.disconnect();
        };
    }, []);

    const onFileClick = useMemo(() => {
        return getOnFileClick(window.document.body);
    }, []);

    useEffect(() => {
        window.document.body.addEventListener('click', onFileClick as any);

        return () => {
            window.document.body.removeEventListener(
                'click',
                onFileClick as any,
            );
        };
    }, [containerRef.current]);

    return <Preview />;
};

const getOnFileClick =
    (current: HTMLElement) => (e: React.MouseEvent<HTMLElement>) => {
        const el = (e.target as HTMLElement).closest('.files .file');

        if (
            el &&
            ((e.target as HTMLElement).tagName !== 'A' ||
                !(e.target as HTMLElement).closest('.file-action'))
        ) {
            const allFiles = Array.from(
                current.querySelectorAll('.formio-component-file .files .file'),
            );

            PreviewController.show(
                allFiles.map((fileEl) => ({
                    uri: fileEl.getAttribute('data-url'),
                    name: fileEl.getAttribute('data-name'),
                    originalName: fileEl.getAttribute('data-original-name'),
                })),
                allFiles.indexOf(el),
            );
        }
    };
