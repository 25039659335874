import { List } from 'antd';
import { observer } from 'mobx-react-lite';
import { EntityPresentation } from 'src/components/EntityPresentation/EntityPresentation';
import { MasterDetailsAside } from 'src/components/MasterDetailsPage/MasterDetailsAside';
import { MasterDetailsPage } from 'src/components/MasterDetailsPage/MasterDetailsPage';
import { LegalEntity } from 'src/generated-api-client';
import { LegalEntitiesHeaderPanel } from 'src/pages/LegalEntitiesPage/LegalEntitiesHeaderPanel';
import { LegalEntityListItem } from 'src/pages/LegalEntitiesPage/LegalEntityListItem';
import { LegalEntitiesStore } from 'src/stores/LegalEntitiesStore/LegalEntitiesStore';

const store = LegalEntitiesStore;

export const LegalEntitiesPage = observer(() => {
    return (
        <MasterDetailsPage
            pageName="legal-entities"
            store={store}
            asidePanel={<LegalEntitiesAsidePanel />}
        >
            <EntityPresentation store={store} entityName="legalEntity" />
        </MasterDetailsPage>
    );
});

const LegalEntitiesAsidePanel = observer(() => {
    return (
        <MasterDetailsAside
            store={store}
            renderItem={(item: LegalEntity) => {
                return (
                    <List.Item key={item.id}>
                        <LegalEntityListItem item={item} />
                    </List.Item>
                );
            }}
            header={<LegalEntitiesHeaderPanel />}
        />
    );
});
