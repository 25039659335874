import {
    convertMapToList,
    DEFAULT_STATUS_KEY,
    StatusInfo,
} from 'src/components/EntityStatusInfo/EntityStatusInfo';
import { CssColors } from 'src/styles/CssVariables';

export const LoanStatusInfoMap: Record<string, StatusInfo> = {
    PENDING: {
        title: 'Pending',
        color: CssColors.mainGray,
        borderColor: CssColors.mainGray,
        backgroundColor: CssColors.mainGray,
    },
    ON_TRACK: {
        title: 'On-track',
        color: CssColors.mainGreen,
        borderColor: CssColors.mainGreen,
        backgroundColor: CssColors.mainGreen,
    },
    FULLY_PAID: {
        title: 'Fully paid',
        color: CssColors.mainGray,
        borderColor: CssColors.mainGray,
        backgroundColor: CssColors.mainGray,
    },
    LATE: {
        title: 'Late',
        color: CssColors.mainRed,
        borderColor: CssColors.mainRed,
        backgroundColor: CssColors.mainRed,
    },
    EXTREMELY_LATE: {
        title: 'Extremely late',
        color: CssColors.mainRed,
        borderColor: CssColors.mainRed,
        backgroundColor: CssColors.mainRed,
    },
    CLOSING_IN_PROGRESS: {
        title: 'Closing in progress',
        color: CssColors.mainGray,
        borderColor: CssColors.mainGray,
        backgroundColor: CssColors.mainGray,
    },
    ERROR: {
        title: 'Error',
        color: CssColors.mainRed,
        borderColor: CssColors.mainRed,
        backgroundColor: CssColors.mainRed,
    },
    [DEFAULT_STATUS_KEY]: {
        title: '',
        color: CssColors.mainGray,
        borderColor: CssColors.mainGray,
        backgroundColor: CssColors.mainGray,
    },
};

export const getLoanStatuses = () => {
    return convertMapToList(LoanStatusInfoMap);
};
