import { action, computed } from 'mobx';
import { DataHelper } from 'src/utils/DataHelper';
import { AsyncOperationWithStatus } from 'src/utils/mobx/AsyncOperationWithStatus';
import {
    BasicStoreApi,
    Entity,
} from 'src/utils/mobx/BasicStore/BasicStore.types';
import { FilterCriteria } from 'src/utils/mobx/FilterCriteria';
import { Pager } from 'src/utils/mobx/Pager';
import ObjectHelper from 'src/utils/ObjectHelper';

export abstract class BasicStore<
    Item extends Entity,
    Filter extends Record<string, any> = any,
    ItemForUpdate = Item,
    ItemForCreate = Item,
    ItemDetails = Item,
> {
    abstract api: BasicStoreApi<Item, ItemDetails>;
    itemDetailsFormKey?: string;
    itemCreateFormKey?: string;
    itemEditFormKey?: string;
    itemPathKey?: string;
    filterCriteria?: FilterCriteria<Filter>;
    pager?: Pager;

    listLoader = new AsyncOperationWithStatus((...args: any[]) => {
        return this.api.loadList?.(...args);
    });

    itemLoader = new AsyncOperationWithStatus((...args: any[]) => {
        return this.api.loadItem?.(...args);
    });

    formLoader = new AsyncOperationWithStatus((...args: any[]) => {
        return this.api.loadItemForm?.(...args);
    });

    updateItemLoader = new AsyncOperationWithStatus(
        (id: string | number, data: ItemForUpdate) => {
            return this.api.updateItem?.(id, data);
        },
    );

    createItemLoader = new AsyncOperationWithStatus((data: ItemForCreate) => {
        return this.api.createItem?.(data);
    });

    @action async loadList(preventListReset = false) {
        if (!preventListReset) {
            this.listLoader.reset();
        }
        await this.listLoader.call();
    }

    @action async loadItem(id: string | number) {
        this.itemLoader.reset();
        await this.itemLoader.call(id);
    }

    @action async loadForm(key?: string) {
        const formKey = key || this.itemDetailsFormKey;
        if (!formKey) {
            return;
        }
        this.formLoader.reset();
        await this.formLoader.call(formKey);
    }

    @action async update(id: string | number, data: ItemForUpdate) {
        this.updateItemLoader.reset();

        // eslint-disable-next-line no-return-await
        return await this.updateItemLoader.call(id, data);
    }

    @action async create(data: ItemForCreate) {
        this.createItemLoader.reset();

        // eslint-disable-next-line no-return-await
        return await this.createItemLoader.call(data);
    }

    @computed get list() {
        return (this.listLoader.data || []) as any as Item[];
    }

    @computed get currentItem() {
        return this.itemLoader.data;
    }

    @computed get currentItemForm() {
        return this.formLoader.data;
    }

    @computed get hasFilter() {
        if (!this.filterCriteria) {
            return false;
        }

        return Object.values(this.filterCriteria.filter || {}).some(
            ObjectHelper.isValuable,
        );
    }

    @computed get hasSearchOrFilterValues() {
        if (!this.filterCriteria) {
            return false;
        }

        return Object.values(this.filterCriteria.filter || {}).some((value) =>
            DataHelper.hasValue(value),
        );
    }

    @computed get hasFilterValues() {
        if (!this.filterCriteria) {
            return false;
        }

        return Object.entries(this.filterCriteria.filter || {})
            .filter(([key]) => key !== 'filterSearch')
            .map(([_, value]) => value)
            .some(ObjectHelper.isValuable);
    }
}
