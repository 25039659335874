import { generatePath } from 'react-router-dom';
import { AppFeature } from 'src/app-configuration/AppConfiguration.types';
import { RoutePaths } from 'src/core/router/RoutePaths';
import { UrlHelper } from 'src/utils/UrlHelper';

type Links = {
    list: () => string;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    itemDetails: (id?: string | number) => string;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    itemEdit?: (id?: string | number) => string;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    process?: (key: string, id?: string | number) => string;

    create?: () => string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const EntityLinks: { [key in AppFeature]: Links } = {
    tasks: {
        list: () =>
            generatePath(RoutePaths.tasks, {
                taskId: undefined,
            }),
        itemDetails: (id) =>
            generatePath(RoutePaths.tasks, {
                taskId: id,
            }),
    },
    applications: {
        list: () => RoutePaths.loanApplications,
        itemDetails: (id) =>
            generatePath(RoutePaths.loanApplicationDetails, {
                loanApplicationId: id,
            }),
        create: () => RoutePaths.loanApplicationCreate,
    },
    loans: {
        list: () => RoutePaths.loans,
        itemDetails: (id) =>
            generatePath(RoutePaths.loanDetails, {
                loanId: id,
            }),
        create: () => RoutePaths.loanCreate,
    },
    products: {
        list: () => RoutePaths.products,
        itemDetails: (id) =>
            generatePath(RoutePaths.productDetails, {
                productId: id,
            }),
        create: () => RoutePaths.productCreate,
    },

    campaigns: {
        list: () => RoutePaths.campaigns,
        itemDetails: (id) =>
            generatePath(RoutePaths.campaignsDetails, {
                campaignId: id,
            }),
        create: () => RoutePaths.campaignsCreate,
    },

    borrowers: {
        list: () => RoutePaths.borrowers,
        itemDetails: (id) =>
            generatePath(RoutePaths.borrowerDetails, {
                productId: id,
            }),
    },

    transactions: {
        list: () => RoutePaths.transactions,
        itemDetails: (id) =>
            generatePath(RoutePaths.transactionDetails, {
                transactionId: id,
            }),
        process(processKey: string, id?: string | number) {
            const entityType = 'transactions';
            if (id) {
                return generatePath(RoutePaths.entityProcess, {
                    entityType,
                    id,
                    processKey,
                });
            }

            return generatePath(RoutePaths.entityTypeProcess, {
                entityType,
                processKey,
            });
        },
    },

    operations: {
        list: () => RoutePaths.operations,
        itemDetails: (id) =>
            generatePath(RoutePaths.operationDetails, {
                operationId: id,
            }),
    },
    collections: {
        list: () => RoutePaths.collections,
        itemDetails: (id) =>
            generatePath(RoutePaths.collectionsDetail, {
                collectionId: id,
            }),
    },
    individuals: {
        list: () => RoutePaths.individuals,
        itemDetails: (id) =>
            generatePath(RoutePaths.individualDetails, {
                individualId: id,
            }),
        create: () =>
            UrlHelper.getTargetUrl(RoutePaths.entityTypeProcess, {
                entityType: 'individuals',
                processKey: 'CreateIndividualProcess',
            }),
    },
    legalEntities: {
        list: () => RoutePaths.borrowers,
        itemDetails: (id) =>
            generatePath(RoutePaths.borrowerDetails, {
                borrowerId: id,
            }),
        create: () => RoutePaths.borrowersCreate,
    },
    profile: {
        list(): string {
            throw new Error('Function not implemented.');
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        itemDetails(id?: string | number | undefined): string {
            throw new Error('Function not implemented.');
        },
        itemEdit: undefined,
        process: undefined,
        create: undefined,
    },
};
