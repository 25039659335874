/* eslint-disable max-lines */
import { Form, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from 'src/components/DateRangePicker/DateRangePicker';
import { SearchPanel } from 'src/components/SearchPanel/SearchPanel';
import {
    SearchPanelFilterForm,
    SearchPanelFilterFormProps,
} from 'src/components/SearchPanel/SearchPanelFilterForm';
import { getCollectionStatuses } from 'src/pages/CollectionsPage/CollectionsStatusInfoMap';
import { CollectionsStore } from 'src/stores/CollectionsStore/CollectionsStore';
import styled from 'styled-components';

const store = CollectionsStore;

export const CollectionsHeaderPanel = observer(() => {
    const { t } = useTranslation();

    return (
        <StyledHeaderWrapper>
            <SearchPanel
                store={store}
                renderPanel={(props) => <FilterPanel {...props} />}
                placeholder={t(
                    'Page.Collections.AsideHeader.Search.Placeholder',
                    {
                        defaultValue: 'Search',
                    },
                )}
            />
        </StyledHeaderWrapper>
    );
});

const StyledHeaderWrapper = styled.header`
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto;
`;

const FilterPanel = observer((props: SearchPanelFilterFormProps) => {
    const statuses = getCollectionStatuses();
    const { t } = useTranslation();

    return (
        <SearchPanelFilterForm
            {...props}
            initialValues={{ startedAt: [] }}
            onFinish={(values) => {
                const { issuedAt, ...other } = values;
                store.pager.reset();
                const [filterStartedAtFrom, filterStartedAtTo] = issuedAt || [
                    undefined,
                    undefined,
                ];
                filterStartedAtFrom
                    ?.set('hour', 0)
                    .set('minute', 0)
                    .set('second', 0);
                filterStartedAtTo
                    ?.set('hour', 23)
                    .set('minute', 59)
                    .set('second', 59);
                store.filterCriteria?.applyFilter({
                    ...other,
                    filterStartedAtFrom: filterStartedAtFrom?.toISOString(true),
                    filterStartedAtTo: filterStartedAtTo?.toISOString(),
                });
                store.loadList();
            }}
        >
            <StyledSelect
                label={t('Page.Collections.Filter.Status', {
                    defaultValue: 'Status',
                })}
                name="filterStatuses"
            >
                <Select mode="multiple" allowClear>
                    {statuses.map((item) => (
                        <Select.Option value={item.id} key={item.id}>
                            {t(`data:status.collection.${item.id}`)}
                        </Select.Option>
                    ))}
                </Select>
            </StyledSelect>
            <Form.Item
                label={t('Page.Collections.Filter.IssuedAt')}
                name="issuedAt"
            >
                <DateRangePicker />
            </Form.Item>
        </SearchPanelFilterForm>
    );
});

const StyledSelect = styled(Form.Item)`
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        display: flex;
        align-items: center;
        min-height: 2.7rem;
        border-radius: 0.5rem;
    }
`;
