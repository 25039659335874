import {
    convertMapToList,
    DEFAULT_STATUS_KEY,
    StatusInfo,
} from 'src/components/EntityStatusInfo/EntityStatusInfo';
import { CssColors } from 'src/styles/CssVariables';

export const ApplicationStatusInfoMap: Record<string, StatusInfo> = {
    INTERESTED: {
        title: 'Interested',
        color: CssColors.mainGray,
        borderColor: CssColors.mainGray,
        backgroundColor: CssColors.mainGray,
    },
    QUALIFIED: {
        title: 'Qualified',
        color: CssColors.mainGray,
        borderColor: CssColors.mainGray,
        backgroundColor: CssColors.mainGray,
    },
    OFFER_ACCEPTANCE: {
        title: 'Offer acceptance',
        color: CssColors.mainGray,
        borderColor: CssColors.mainGray,
        backgroundColor: CssColors.mainGray,
    },
    CREDIT_REVIEW: {
        title: 'Credit review',
        color: CssColors.mainGray,
        borderColor: CssColors.mainGray,
        backgroundColor: CssColors.mainGray,
    },
    CREDIT_PROPOSAL: {
        title: 'Credit proposal',
        color: CssColors.mainGray,
        borderColor: CssColors.mainGray,
        backgroundColor: CssColors.mainGray,
    },
    COMMITTEE_REVIEW: {
        title: 'Committee review',
        color: CssColors.mainGray,
        borderColor: CssColors.mainGray,
        backgroundColor: CssColors.mainGray,
    },
    DOCUMENTS_CHECK: {
        title: 'Documents check',
        color: CssColors.mainGray,
        borderColor: CssColors.mainGray,
        backgroundColor: CssColors.mainGray,
    },
    CONTRACT_SIGNING: {
        title: 'Contract signing',
        color: CssColors.mainGray,
        borderColor: CssColors.mainGray,
        backgroundColor: CssColors.mainGray,
    },
    DEVICE_SETUP: {
        title: 'Device setup',
        color: CssColors.mainGray,
        borderColor: CssColors.mainGray,
        backgroundColor: CssColors.mainGray,
    },
    CAD_REVIEW: {
        title: 'CAD review',
        color: CssColors.mainGray,
        borderColor: CssColors.mainGray,
        backgroundColor: CssColors.mainGray,
    },
    CAD_FINAL_REVIEW: {
        title: 'CAD final review',
        color: CssColors.mainGray,
        borderColor: CssColors.mainGray,
        backgroundColor: CssColors.mainGray,
    },
    SENT_TO_DISBURSEMENT: {
        title: 'Approved',
        color: CssColors.mainGreen,
        borderColor: CssColors.mainGreen,
        backgroundColor: CssColors.mainGreen,
    },
    DISBURSED: {
        title: 'Disbursed',
        color: CssColors.mainGreen,
        borderColor: CssColors.mainGreen,
        backgroundColor: CssColors.mainGreen,
    },
    DECLINED: {
        title: 'Declined',
        color: CssColors.mainRed,
        borderColor: CssColors.mainRed,
        backgroundColor: CssColors.mainRed,
    },
    CANCELLED: {
        title: 'Cancelled',
        color: CssColors.mainRed,
        borderColor: CssColors.mainRed,
        backgroundColor: CssColors.mainRed,
    },
    ERROR: {
        title: 'Error',
        color: CssColors.mainRed,
        borderColor: CssColors.mainRed,
        backgroundColor: CssColors.mainRed,
    },
    [DEFAULT_STATUS_KEY]: {
        title: '',
        color: CssColors.mainGray,
        borderColor: CssColors.mainGray,
        backgroundColor: CssColors.mainGray,
    },
};

export const getApplicationStatuses = () => {
    return convertMapToList(ApplicationStatusInfoMap);
};
