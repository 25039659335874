export enum AppName {
    BO_PORTAL = 'bo-portal',
}

export type EnvType = {
    appName: AppName | string;
    isDev: boolean;
    isBOportal: boolean;
};

const appName = process.env.REACT_APP_APP_NAME || AppName.BO_PORTAL;
export const AppEnv: EnvType = {
    appName,
    isDev: process.env.NODE_ENV === 'development',
    isBOportal: appName === AppName.BO_PORTAL,
};
