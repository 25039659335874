import { FileActions } from 'src/formio/components/FileComponent/types';
import { UserRole } from 'src/stores/UserStore/UserRole';

export const verifyAllowedFileActions = (
    fileActions: FileActions[],
    userRoles: string[],
): { canDelete: boolean; canDownload: boolean } => {
    const canDelete = [
        fileActions?.includes(FileActions.DELETE),
        userRoles.includes(UserRole.Admin),
    ].some((i) => Boolean(i));

    return {
        canDelete,
        canDownload: fileActions?.includes(FileActions.DOWNLOAD),
    };
};
