import { Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { EntityStatusInfo } from 'src/components/EntityStatusInfo/EntityStatusInfo';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { CollectionBrief } from 'src/generated-api-client';
import { CollectionsStatusInfoMap } from 'src/pages/CollectionsPage/CollectionsStatusInfoMap';
import { SettingsStore } from 'src/stores/SettingsStore/SettingsStore';
import { DateHelper } from 'src/utils/DateHelper';
import { FullNameHelper } from 'src/utils/FullNameHelper';
import { NumberHelper } from 'src/utils/NumberHelper';
import styled from 'styled-components';

type CollectionListItemProps = { item: CollectionBrief };

export const CollectionListItem = observer(
    ({ item }: CollectionListItemProps) => {
        const currencyMnemonic = SettingsStore.currencyMnemonic as string;
        const { i18n, t } = useTranslation();

        const fullName = FullNameHelper(item?.loan?.borrower as any);

        return (
            <StyledNavLink
                to={EntityLinks.collections.itemDetails(item?.id)}
                onClick={(e) => {
                    if (
                        (e.currentTarget as HTMLElement).classList.contains(
                            'active',
                        )
                    ) {
                        e.preventDefault();
                    }
                }}
            >
                <MainText>
                    <Tooltip title={item?.number}>
                        <EllipsisContainer>
                            <strong>{item?.number}</strong>
                        </EllipsisContainer>
                    </Tooltip>
                </MainText>
                <StyledPaymentContainer>
                    <MainText>
                        <Tooltip
                            placement="bottomLeft"
                            title={t('Page.Collections.Item.Label.Installment')}
                        >
                            <EllipsisContainer>
                                {NumberHelper.currencyFormatter(
                                    item.pastDueInstallmentPayment || 0,
                                    2,
                                    currencyMnemonic,
                                    i18n.language,
                                )}
                            </EllipsisContainer>
                        </Tooltip>
                    </MainText>
                </StyledPaymentContainer>
                <EntityStatusInfo
                    statusInfoMap={CollectionsStatusInfoMap}
                    status={item?.status}
                    entityName="collection"
                />
                <MainText>
                    <Tooltip title={fullName}>
                        <EllipsisContainer>{fullName}</EllipsisContainer>
                    </Tooltip>
                </MainText>

                <Footer>
                    <SecondaryTextOneLine>
                        <strong>Balance: </strong>
                        {NumberHelper.currencyFormatter(
                            item.outstandingBalance || 0,
                            2,
                            currencyMnemonic,
                            i18n.language,
                        )}
                    </SecondaryTextOneLine>
                    <SecondaryTextOneLine>
                        {DateHelper.formatDate(item?.startedAt as any)}
                    </SecondaryTextOneLine>
                </Footer>
            </StyledNavLink>
        );
    },
);

const Footer = styled.div`
    margin-top: 0.5rem;
    display: grid;
    gap: 0.5rem;
    grid-template-columns: 1fr auto;
`;

const StyledNavLink = styled(NavLink)`
    background: var(--aside-list-item-bg);
    border: 0.08rem solid #d2d2d7;
    border-radius: 0.7rem;
    margin: 0.7rem 0;
    padding: 1rem 1.5rem;
    display: block;

    &:hover {
        border-color: var(--aside-list-border-color-hover);
        color: var(--form-text-color);
        text-decoration: none;
    }

    &.active {
        border-color: var(--aside-list-active-border-color);
        background: var(--aside-list-active-bg);
        border-width: 0.14rem;
    }
`;

const MainText = styled.div`
    color: var(--form-text-color);
    line-height: 125%;
    position: relative;
    overflow: hidden;
`;

const SecondaryText = styled.div`
    font-size: 0.8rem;
    color: var(--aside-list-item-color-secondary);
    line-height: 125%;
    position: relative;
    overflow: hidden;
`;

const SecondaryTextOneLine = styled(SecondaryText)`
    white-space: nowrap;
    overflow: unset;
`;

export const EllipsisContainer = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 1em;
`;

const StyledPaymentContainer = styled.div`
    margin: 0.4rem 0;
    color: var(--form-text-color) !important;
    a {
        color: var(--form-text-color);
    }
`;
