import { LoanOperationRepresentation } from 'src/generated-api-client';
import { bpmsResourcesManagerApi, loansApi } from 'src/services/apiServices';
import { BasicStore } from 'src/utils/mobx/BasicStore/BasicStore';
import { BasicStoreApi } from 'src/utils/mobx/BasicStore/BasicStore.types';
import { Pager } from 'src/utils/mobx/Pager';
import { RequestHelper } from 'src/utils/RequestHelper';

export class OperationsStoreClass extends BasicStore<LoanOperationRepresentation> {
    itemDetailsFormKey =
        'embedded:deployment:forms/bo/bo-17-loan-operation.json';

    api: BasicStoreApi<LoanOperationRepresentation> = {
        loadItemForm: (formKey: string, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                bpmsResourcesManagerApi.getForm({ formKey }, options),
            );
        },
        loadItem: (id: number, ...args) => {
            const options = RequestHelper.getOptionsFromArgs(args);

            return RequestHelper.unwrapFromAxiosPromise(
                loansApi.findOperationById({ id }, options),
            );
        },
    };

    pager = new Pager();
}

export const OperationsStore = new OperationsStoreClass();
