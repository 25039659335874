export const getValueByPath = (source: any, path: string | string[]) => {
    if (Array.isArray(path)) {
        return path.reduce((obj: any, key) => obj[key], source);
    }

    return source[path];
};

function isValuable(value: any): boolean {
    return value !== null && value !== undefined && value !== '';
}

export default {
    getValueByPath,
    isValuable,
};
