export enum UserRole {
    Admin = 'Admin',
    HeadOfSales = 'HeadOfSales',
    SalesManager = 'SalesManager',
    SalesTeamLeader = 'SalesTeamLeader',
    RelationshipManager = 'RelationshipManager',
    HeadOfCredit = 'HeadOfCredit',
    CreditTeamLeader = 'CreditTeamLeader',
    CreditUnderwriter = 'CreditUnderwriter',
    HeadOfRisk = 'HeadOfRisk',
    RiskOfficer = 'RiskOfficer',
    CustomerCare = 'CustomerCare',
    HeadOfOperation = 'HeadOfOperation',
    OperationOfficer = 'OperationOfficer',
    CustomerSuccess = 'CustomerSuccess',
    Compliance = 'Compliance',
    HeadOfCollection = 'HeadOfCollection',
    CollectionTeamLeader = 'CollectionTeamLeader',
    CollectionOfficer = 'CollectionOfficer',
    CollectionAdmin = 'CollectionAdmin',
    EnforcementOfficer = 'EnforcementOfficer',
    Auditing = 'Auditing',
    Finance = 'Finance',
    Support = 'Support',
    SalesSupport = 'SalesSupport',
    FinancialOperations = 'FinancialOperations',
    CAD = 'CAD',
    SeniorCAD = 'SeniorCAD',
    CustomerSuccessManager = 'CustomerSuccessManager',
}
