/* eslint-disable array-callback-return */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-lines */
/* eslint-disable no-param-reassign */
/* eslint-disable complexity */
/* eslint-disable no-underscore-dangle */
/* eslint-disable require-atomic-updates */
/* eslint-disable no-return-assign */
/* eslint-disable no-return-assign */

import BMF from 'browser-md5-file';
import Components from 'formiojs/components/Components';
import fileProcessor from 'formiojs/providers/processor/fileProcessor';
import FormioUtils from 'formiojs/utils';
import Validator from 'formiojs/validator/Validator';
import { FileActions } from 'src/formio/components/FileComponent/types';

const FileComponent = (Components as any).components.file;

class File extends (FileComponent as any) {
    init() {
        super.init();
    }

    uploaded: any = [];
    initFiles: any = [];

    attach(element: any) {
        this.loadRefs(element, {
            fileDrop: 'single',
            fileBrowse: 'single',
            galleryButton: 'single',
            cameraButton: 'single',
            takePictureButton: 'single',
            toggleCameraMode: 'single',
            videoPlayer: 'single',
            fileLink: 'multiple',
            removeLink: 'multiple',
            fileStatusRemove: 'multiple',
            fileImage: 'multiple',
            fileType: 'multiple',
            fileProcessingLoader: 'single',
            fileDownload: 'single',
        });

        this.refs?.removeLink?.forEach((removeLink: any) => {
            this.addEventListener(removeLink, 'click', (event: any) => {
                const index = event.target?.attributes?.tabindex?.value;
                event.preventDefault();
                event.stopPropagation();
                const fileInfo = this.dataValue[index];

                this.deleteFile(fileInfo);
                this.splice(index);
                this.redraw();
            });
        });

        this.refs.fileStatusRemove.forEach(
            (fileStatusRemove: any, index: number) => {
                this.addEventListener(
                    fileStatusRemove,
                    'click',
                    (event: any) => {
                        event.preventDefault();
                        event.stopPropagation();
                        if (this.abortUpload) {
                            this.abortUpload();
                        }
                        this.statuses.splice(index, 1);
                        if (this.component.validate.required) {
                            const hasRootErrors = this.root.errors?.length > 0;
                            this.emit(
                                'change',
                                {
                                    data: this.root.data,
                                    isValid: !hasRootErrors,
                                    changed: this,
                                },
                                { changed: this, changes: [this] },
                            );
                        }
                        this.redraw();
                    },
                );
            },
        );

        this.refs.fileStatusRemove.forEach(
            (fileStatusRemove: any, index: number) => {
                this.addEventListener(
                    fileStatusRemove,
                    'click',
                    (event: any) => {
                        event.preventDefault();
                        event.stopPropagation();
                        if (this.abortUpload) {
                            this.abortUpload();
                        }
                        this.statuses.splice(index, 1);
                        if (!this.statuses.length) {
                            this.refs.fileDrop.removeAttribute('hidden');
                        }
                        this.redraw();
                    },
                );
            },
        );

        this.addEventListener(this.refs.fileDownload, 'click', (event: any) => {
            event.stopPropagation();
        });

        this.on('change', (data: { isValid: boolean }) => {
            const fileStatusHasError =
                this.statuses?.filter((item: any) => item?.status === 'error')
                    ?.length > 0;
            if (
                data?.isValid &&
                fileStatusHasError &&
                this.component.validate.required
            ) {
                this.emit(
                    'change',
                    { data: this.data, isValid: false, changed: this },
                    { changed: this, changes: [this] },
                );
            }
        });

        return super.attach(element);
    }

    checkCustomValidityBeforeUpload(currentFilesData: Record<string, unknown>) {
        const newInstance = new FileComponent(this.schema);

        newInstance.dataValue = [
            ...this.getValue(),
            ...Object.values(currentFilesData),
        ];

        return (
            Validator.checkComponent(
                newInstance,
                this.data,
                this.row,
                true,
                false,
            )?.filter(
                (item: { context: { validator: string } }) =>
                    item?.context?.validator === 'custom',
            )?.length === 0
        );
    }

    checkComponentCustomValidity(currentFilesData: Record<string, unknown>) {
        const newInstance = new FileComponent(this.schema);

        newInstance.dataValue = [
            ...this.getValue(),
            ...Object.values(currentFilesData),
        ];

        const check = Validator.checkComponent(
            newInstance,
            this.data,
            this.row,
            true,
            false,
        )?.filter(
            (item: { context: { validator: string } }) =>
                item?.context?.validator === 'custom',
        );
        const validations = check;

        return this.setCustomValidity(validations, true);
    }

    setValidationErrorOnFileSize() {
        const newInstance = new FileComponent({
            ...this.schema,
            validate: {
                required: true,
            },
        });

        newInstance.dataValue = [];

        const check = Validator.checkComponent(
            newInstance,
            this.data,
            this.row,
            true,
            false,
        );

        const message = this.t(
            'File is too big; it must be at most {{ size }}',
            {
                size: this.component.fileMaxSize,
            },
        );

        const validations = [
            {
                ...check?.[0],
                message,
                context: {
                    validator: 'custom',
                    hasLabel: true,
                    setting: true,
                    key: this.component.key,
                    label: this.component.label,
                    value: [],
                    index: 0,
                },
            },
        ];

        return this.setCustomValidity(validations, true);
    }

    superUpload(files: any) {
        // Only allow one upload if not multiple.
        if (!this.component.multiple) {
            if (this.statuses.length) {
                this.statuses = [];
            }
            files = Array.prototype.slice.call(files, 0, 1);
        }

        if (this.component.storage && files && files.length) {
            this.fileDropHidden = true;

            // files is not really an array and does not have a forEach method, so fake it.
            /* eslint-disable max-statements */
            Array.prototype.forEach.call(files, async (file) => {
                const bmf = new BMF();
                const hash = await new Promise((resolve, reject) => {
                    bmf.md5(file, (err: any, md5: any) => {
                        if (err) {
                            return reject(err);
                        }

                        return resolve(md5);
                    });
                });
                const fileName = FormioUtils.uniqueName(
                    file.name,
                    this.component.fileNameTemplate,
                    this.evalContext(),
                );
                const escapedFileName = file.name
                    ? file.name.replaceAll('<', '&lt;').replaceAll('>', '&gt;')
                    : file.name;
                const fileUpload = {
                    originalName: escapedFileName,
                    name: fileName,
                    size: file.size,
                    status: 'info',
                    message: this.t('Processing file. Please wait...'),
                    hash,
                } as any;

                // Check if file with the same name is being uploaded
                const fileWithSameNameUploaded = this.dataValue.some(
                    (fileStatus: any) => fileStatus.originalName === file.name,
                );
                const fileWithSameNameUploadedWithError =
                    this.statuses.findIndex(
                        (fileStatus: any) =>
                            fileStatus.originalName === file.name &&
                            fileStatus.status === 'error',
                    );

                if (fileWithSameNameUploaded) {
                    fileUpload.status = 'error';
                    fileUpload.message = this.t(
                        'File with the same name is already uploaded',
                    );
                }

                if (fileWithSameNameUploadedWithError !== -1) {
                    this.statuses.splice(fileWithSameNameUploadedWithError, 1);
                    this.redraw();
                }

                // Check file pattern
                if (
                    this.component.filePattern &&
                    !this.validatePattern(file, this.component.filePattern)
                ) {
                    fileUpload.status = 'error';
                    fileUpload.message = this.t(
                        'File is the wrong type; it must be {{ pattern }}',
                        {
                            pattern: this.component.filePattern,
                        },
                    );
                }

                // Check file minimum size
                if (
                    this.component.fileMinSize &&
                    !this.validateMinSize(file, this.component.fileMinSize)
                ) {
                    fileUpload.status = 'error';
                    fileUpload.message = this.t(
                        'File is too small; it must be at least {{ size }}',
                        {
                            size: this.component.fileMinSize,
                        },
                    );
                }

                // Check file maximum size
                if (
                    this.component.fileMaxSize &&
                    !this.validateMaxSize(file, this.component.fileMaxSize)
                ) {
                    fileUpload.status = 'error';
                    fileUpload.message = this.t(
                        'File is too big; it must be at most {{ size }}',
                        {
                            size: this.component.fileMaxSize,
                        },
                    );
                }

                // Get a unique name for this file to keep file collisions from occurring.
                const dir = this.interpolate(this.component.dir || '');
                const { fileService } = this;
                if (!fileService) {
                    fileUpload.status = 'error';
                    fileUpload.message = this.t('File Service not provided.');
                }

                this.statuses.push(fileUpload);
                this.redraw();

                if (fileUpload.status !== 'error') {
                    if (this.component.privateDownload) {
                        file.private = true;
                    }
                    const { storage, options = {} } = this.component;
                    const url = this.interpolate(this.component.url, {
                        file: fileUpload,
                    });
                    let groupKey = null;
                    let groupPermissions = null;

                    // Iterate through form components to find group resource if one exists
                    this.root.everyComponent((element: any) => {
                        if (
                            element.component?.submissionAccess ||
                            element.component?.defaultPermission
                        ) {
                            groupPermissions = !element.component
                                .submissionAccess
                                ? [
                                      {
                                          type: element.component
                                              .defaultPermission,
                                          roles: [],
                                      },
                                  ]
                                : element.component.submissionAccess;

                            groupPermissions.forEach((permission: any) => {
                                groupKey = [
                                    'admin',
                                    'write',
                                    'create',
                                ].includes(permission.type)
                                    ? element.component.key
                                    : null;
                            });
                        }
                    });

                    const fileKey = this.component.fileKey || 'file';
                    const groupResourceId = groupKey
                        ? this.currentForm.submission.data[groupKey]._id
                        : null;
                    let processedFile = null;

                    if (this.root.options.fileProcessor) {
                        try {
                            if (this.refs.fileProcessingLoader) {
                                this.refs.fileProcessingLoader.style.display =
                                    'block';
                            }
                            const fileProcessorHandler = fileProcessor(
                                this.fileService,
                                this.root.options.fileProcessor,
                            );
                            processedFile = await fileProcessorHandler(
                                file,
                                this.component.properties,
                            );
                        } catch (err) {
                            fileUpload.status = 'error';
                            fileUpload.message = this.t(
                                'File processing has been failed.',
                            );
                            this.fileDropHidden = false;
                            this.redraw();

                            return;
                        } finally {
                            if (this.refs.fileProcessingLoader) {
                                this.refs.fileProcessingLoader.style.display =
                                    'none';
                            }
                        }
                    }

                    fileUpload.message = this.t('Starting upload.');
                    this.redraw();

                    const filePromise = fileService
                        .uploadFile(
                            storage,
                            processedFile || file,
                            fileName,
                            dir,
                            // Progress callback
                            (evt: any) => {
                                fileUpload.status = 'progress';
                                (fileUpload as any).progress = parseInt(
                                    ((100 * evt.loaded) / evt.total) as any,
                                );
                                delete fileUpload.message;
                                this.redraw();
                            },
                            url,
                            options,
                            fileKey,
                            groupPermissions,
                            groupResourceId,
                            // Upload start callback
                            () => {
                                this.emit('fileUploadingStart', filePromise);
                            },
                            // Abort upload callback
                            (abort: any) => (this.abortUpload = abort),
                        )
                        .then((fileInfo: any) => {
                            const index = this.statuses.indexOf(fileUpload);
                            if (index !== -1) {
                                this.statuses.splice(index, 1);
                            }
                            fileInfo.originalName = escapedFileName;
                            fileInfo.hash = fileUpload.hash;
                            fileInfo.actions = [FileActions.DELETE];
                            if (!this.hasValue()) {
                                this.dataValue = [];
                            }
                            this.dataValue.push(fileInfo);
                            this.fileDropHidden = false;
                            this.redraw();
                            this.triggerChange();
                            this.uploaded.push(fileInfo as any);
                            this.emit('fileUploadingEnd', filePromise);
                        })
                        .catch((error: any) => {
                            fileUpload.status = 'error';
                            fileUpload.message =
                                typeof error === 'string'
                                    ? error
                                    : error.toString();
                            delete fileUpload.progress;
                            this.fileDropHidden = false;
                            this.redraw();
                            this.emit('fileUploadingEnd', filePromise);
                        });
                } else if (this.component.validate.required) {
                    this.emit(
                        'change',
                        { data: this.data, isValid: false, changed: this },
                        { changed: this, changes: [this] },
                    );
                }
            });
        }
    }

    upload(files: Record<string, unknown>) {
        const isValid = this.checkCustomValidityBeforeUpload(files);

        if (!isValid) {
            this.checkComponentCustomValidity(files);
        } else {
            this.superUpload(files);
        }
    }
}

export default File;
