/* eslint-disable max-lines */
import { PlusOutlined } from '@ant-design/icons';
import { Form, Select, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from 'src/components/DateRangePicker/DateRangePicker';
import { SearchPanel } from 'src/components/SearchPanel/SearchPanel';
import {
    SearchPanelFilterForm,
    SearchPanelFilterFormProps,
} from 'src/components/SearchPanel/SearchPanelFilterForm';
import { EntityLinks } from 'src/core/router/EntityLinks';
import { Router } from 'src/core/router/Router';
import { getTransactionStatuses } from 'src/pages/TransactionsPage/TransactionStatusInfoMap';
import { TransactionsStore } from 'src/stores/TransactionsStore/TransactionsStore';
import { TransactionsTypes } from 'src/stores/TransactionsStore/TransactionsStore.types';
import { UserStore } from 'src/stores/UserStore/UserStore';
import styled from 'styled-components';

const store = TransactionsStore;

const BULK_IMPORT_TRANSACTION_PROCESS_KEY = 'ImportPaymentsProcess';

export const TransactionsHeaderPanel = observer(() => {
    const { t } = useTranslation();

    return (
        <StyledHeaderWrapper>
            <SearchPanel
                store={store}
                renderPanel={(props) => <FilterPanel {...props} />}
                placeholder={t(
                    'Page.Transactions.AsideHeader.Search.Placeholder',
                    {
                        defaultValue: 'Loan, ID or Borrower',
                    },
                )}
            />
            {UserStore.can.manage.transactions && (
                <Tooltip
                    title={t(
                        'Page.Transactions.AsideHeader.CreateNewItem.Tooltip',
                    )}
                >
                    <StyledCreateButton
                        onClick={() => {
                            const targetLink =
                                EntityLinks.transactions.process?.(
                                    BULK_IMPORT_TRANSACTION_PROCESS_KEY,
                                );
                            targetLink && Router.navigate(targetLink);
                        }}
                    >
                        <PlusOutlined />
                    </StyledCreateButton>
                </Tooltip>
            )}
        </StyledHeaderWrapper>
    );
});

const FilterPanel = observer((props: SearchPanelFilterFormProps) => {
    const statuses = getTransactionStatuses();

    const { t } = useTranslation();

    return (
        <SearchPanelFilterForm
            {...props}
            initialValues={{ createdAt: [] }}
            onFinish={(values: any) => {
                const { createdAt, processedAt, ...other } = values;
                store.pager.reset();
                const [filterCreatedAtFrom, filterCreatedAtTo] = createdAt || [
                    undefined,
                    undefined,
                ];
                filterCreatedAtFrom
                    ?.set('hour', 0)
                    .set('minute', 0)
                    .set('second', 0);
                filterCreatedAtTo
                    ?.set('hour', 23)
                    .set('minute', 59)
                    .set('second', 59);
                const [filterProcessedAtFrom, filterProcessedAtTo] =
                    processedAt || [undefined, undefined];
                filterProcessedAtFrom
                    ?.set('hour', 0)
                    .set('minute', 0)
                    .set('second', 0);
                filterProcessedAtTo
                    ?.set('hour', 23)
                    .set('minute', 59)
                    .set('second', 59);
                store.filterCriteria?.applyFilter({
                    ...other,
                    filterCreatedAtFrom: filterCreatedAtFrom?.toISOString(true),
                    filterCreatedAtTo: filterCreatedAtTo?.toISOString(),
                    filterProcessedAtFrom:
                        filterProcessedAtFrom?.toISOString(true),
                    filterProcessedAtTo: filterProcessedAtTo?.toISOString(),
                });
                store.loadList();
            }}
        >
            <StyledSelect
                label={t('Page.Transactions.Filter.Status', {
                    defaultValue: 'Status',
                })}
                name="filterStatuses"
            >
                <Select mode="multiple" allowClear>
                    {statuses.map((status) => (
                        <Select.Option key={status.id} value={status.id}>
                            {t(`data:status.transaction.${status.id}`)}
                        </Select.Option>
                    ))}
                </Select>
            </StyledSelect>

            <StyledSelect
                label={t('Page.Transactions.Filter.Type', {
                    defaultValue: 'Type',
                })}
                name="filterTypes"
            >
                <Select mode="multiple" allowClear>
                    <Select.Option value={TransactionsTypes.Repayment}>
                        {t('Page.Transactions.Filter.Type.Repayment', {
                            defaultValue: TransactionsTypes.Repayment,
                        })}
                    </Select.Option>
                    <Select.Option value={TransactionsTypes.Disbursement}>
                        {t('Page.Transactions.Filter.Type.Disbursement', {
                            defaultValue: TransactionsTypes.Disbursement,
                        })}
                    </Select.Option>
                    <Select.Option value={TransactionsTypes.FeePayment}>
                        {t('Page.Transactions.Filter.Type.FeePayment', {
                            defaultValue: TransactionsTypes.FeePayment,
                        })}
                    </Select.Option>
                    <Select.Option value={TransactionsTypes.Refund}>
                        {t('Page.Transactions.Filter.Type.Refund', {
                            defaultValue: TransactionsTypes.Refund,
                        })}
                    </Select.Option>
                </Select>
            </StyledSelect>
            <Form.Item
                label={t('Page.Transactions.Filter.Type.CreatedAt')}
                name="createdAt"
            >
                <DateRangePicker />
            </Form.Item>
            <Form.Item
                label={t('Page.Transactions.Filter.Type.ProcessedAt')}
                name="processedAt"
            >
                <DateRangePicker />
            </Form.Item>
        </SearchPanelFilterForm>
    );
});

const StyledSelect = styled(Form.Item)`
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        display: flex;
        align-items: center;
        min-height: 2.7rem;
        border-radius: 0.5rem;
    }
`;

const StyledHeaderWrapper = styled.header`
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto;
`;

const StyledCreateButton = styled.button`
    width: 3.6rem;
    min-width: 3rem;
    font-weight: 700;
    height: 2.4rem;
    margin-left: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: 2rem;
    border: none;
    background: var(--btn-bg);
    border-radius: 0.35rem;
    color: #ffffff;
    span: {
        font-size: 1rem;
    }
`;
