import Inputmask from 'inputmask';
import { AppEnv } from 'src/core/Environment';
import { getIsoCodeByPhone } from 'src/formio/utils/getIsoCodeByPhone';
import { getMaskByIsoPhone } from 'src/formio/utils/getMaskByIsoPhone';

export const getMaskedPhoneValue = (phoneNumber: any) => {
    if (typeof phoneNumber !== 'string' || !phoneNumber) {
        AppEnv.isDev &&
            console.warn(
                `App:Utils:: Phone number "${phoneNumber}" is not a string or empty. Return empty string instead of phone number`,
            );

        return '';
    }

    try {
        const phoneData = getIsoCodeByPhone(phoneNumber);

        const mask = getMaskByIsoPhone(
            phoneData?.countryLetterCode as string,
            phoneNumber,
        );

        const phoneMask = new Inputmask(mask);
        const cleanPattern = new RegExp(`^\\+?${phoneData?.countryDialCode}`);
        const phoneWithoutMask = phoneNumber.replace(cleanPattern, '');
        const formattedPhone = (phoneMask as any).format(phoneWithoutMask);

        if (!formattedPhone) {
            console.warn('App:Utils:: Error in formattedPhone');

            return phoneNumber;
        }

        return `+${phoneData?.countryDialCode || ''} ${formattedPhone || ''}`;
    } catch (error) {
        console.warn(
            'App:Utils:: Error parse phone number',
            phoneNumber,
            error,
        );

        return phoneNumber || '';
    }
};

const getFormattedValue = (ctx: any, value: any) => {
    return ctx.instance
        .getValueAsString(
            ctx.instance.formatValue(ctx.instance.parseValue(value)),
        )
        .replace(/"/g, '&quot;');
};

export const getDisabledValue = (ctx: any) => {
    const hasCalculatedValue = ctx?.component?.calculateValue;

    if (hasCalculatedValue) {
        return getFormattedValue(
            ctx,
            ctx.instance.doValueCalculation(
                ctx.instance.dataValue,
                ctx.data,
                ctx.row,
                {},
            ),
        );
    }

    if (ctx.component.type === 'phoneNumber') {
        return getMaskedPhoneValue(ctx.instance.getValue());
    }

    return getFormattedValue(ctx, ctx.instance.getValue());
};
